import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Analytics from 'react-router-ga';
import { Beforeunload } from 'react-beforeunload';

import { NomalLayout } from 'components';

import { ManagerLoginView, ManagerAdminView, ManagerAdminEditorView, ManagerMemberView, ManagerMemberEditorView,
         ManagerSentenceView, ManagerSentenceEditorView, ManagerWordView, ManagerWordEditorView,
         ManagerVideoView, ManagerVideoEditorView,
         ManagerEventView, ManagerSpotView } from 'modules/main/view';

 import { MobileLoginView, MobileHomeView, MobileFestivalIntroView,
          MobileMapEventView, MobileMapSpotView, MobileScheduleView, MobileEventView,
          MobileGoogleMapEventView, MobileGoogleMapSpotView,
          MobileWordView, MobileSentenceView, MobileMypageView } from 'modules/mobile/view';

//export const SOPOONG_URL = 'http://localhost';
//export const SOPOONG_URL = 'http://210.115.229.12:8055';
export const SOPOONG_URL = 'https://2024mine.com/anyeventmime-webapp-0.0.1-SNAPSHOT';
//export const SOPOONG_URL = 'http://13.209.25.100' // aws : ec2-13-124-186-97.ap-northeast-2.compute.amazonaws.com;
//export const SOPOONG_URL = 'http://cms.anyeventmime.kr'

export const ROOT_PATH = '/';

export const ANYEVENTMIME_URL = '/anyeventmime';
export const MAIN_PATH = ANYEVENTMIME_URL + '/main';

export const MANAGER_LOGIN_PATH = ANYEVENTMIME_URL + '/manager/login';
export const MANAGER_ADMIN_PATH = ANYEVENTMIME_URL + '/manager/admin';
export const MANAGER_ADMIN_EDITOR_PATH = ANYEVENTMIME_URL + '/manager/admineditor';
export const MANAGER_MEMBER_PATH = ANYEVENTMIME_URL + '/manager/member';
export const MANAGER_MEMBER_EDITOR_PATH = ANYEVENTMIME_URL + '/manager/membereditor';

export const MANAGER_EVENT_PATH = ANYEVENTMIME_URL + '/manager/event';
export const MANAGER_SPOT_PATH = ANYEVENTMIME_URL + '/manager/spot';

export const MANAGER_SENTENCE_PATH = ANYEVENTMIME_URL + '/manager/sentence';
export const MANAGER_SENTENCE_EDITOR_PATH = ANYEVENTMIME_URL + '/manager/sentenceeditor';
export const MANAGER_WORD_PATH = ANYEVENTMIME_URL + '/manager/word';
export const MANAGER_WORD_EDITOR_PATH = ANYEVENTMIME_URL + '/manager/wordeditor';
export const MANAGER_VIDEO_PATH = ANYEVENTMIME_URL + '/manager/video';
export const MANAGER_VIDEO_EDITOR_PATH = ANYEVENTMIME_URL + '/manager/videoeditor';

// mobile
export const MOBILE_LOGIN_PATH = ANYEVENTMIME_URL + '/mobile/login';
export const MOBILE_HOME_PATH = ANYEVENTMIME_URL + '/mobile/home';
export const MOBILE_MAP_EVENT_PATH = ANYEVENTMIME_URL + '/mobile/map/event';
export const MOBILE_MAP_SPOT_PATH = ANYEVENTMIME_URL + '/mobile/map/spot';
export const MOBILE_FESTIVAL_PATH = ANYEVENTMIME_URL + '/mobile/festival';
export const MOBILE_SCHEDULE_PATH = ANYEVENTMIME_URL + '/mobile/schedule';
export const MOBILE_EVENT_PATH = ANYEVENTMIME_URL + '/mobile/event';
export const MOBILE_WORD_PATH = ANYEVENTMIME_URL + '/mobile/word';
export const MOBILE_SENTENCE_PATH = ANYEVENTMIME_URL + '/mobile/sentence';
export const MOBILE_MYPAGE_PATH = ANYEVENTMIME_URL + '/mobile/mypage';


export const SHOP_ID = '1000000001';
export const SHOP_URL = '/shop';

export const SEARCH_PATH = ANYEVENTMIME_URL + '/search';

export const TERMS_OF_SERVICE_PATH = ANYEVENTMIME_URL + '/policy/termsofservice';
export const PRIVACY_PATH = ANYEVENTMIME_URL + '/policy/privacy';

export const LOGIN_PATH = ANYEVENTMIME_URL + '/login';
export const ACCOUNT_PATH = ANYEVENTMIME_URL + '/account';
export const MYPAGE_PATH = ANYEVENTMIME_URL + '/mypage';
export const PASSWORD_CHANGE_PATH = ANYEVENTMIME_URL + '/passwordchange';
export const PASSWORD_RESET_PATH = ANYEVENTMIME_URL + '/passwordreset';

export const HELP_PATH = ANYEVENTMIME_URL + '/help';


//export const JUSO_API_KEY =  'devU01TX0FVVEgyMDIwMDcyMDExMzExNDEwOTk2ODc=';
export const JUSO_API_KEY =  'U01TX0FVVEgyMDIwMDcyMDExMjgxNjEwOTk2ODY=';


export const Routes = () => {

  return (
    <Router >
      <Analytics id='UA-172718993-1' debug>
          <Switch>
            <NomalLayout>
              <Route exact path={ROOT_PATH} component={MobileLoginView}  />
              <Route exact path={ANYEVENTMIME_URL} component={ManagerLoginView}  />
              <Route exact path={MAIN_PATH} component={ManagerLoginView}  />

              <Route exact path={MANAGER_LOGIN_PATH} component={ManagerLoginView}  />
              <Route exact path={MANAGER_ADMIN_PATH} component={ManagerAdminView} />
              <Route exact path={`${MANAGER_ADMIN_EDITOR_PATH}/:userUid`} component={ManagerAdminEditorView} />
              <Route exact path={MANAGER_MEMBER_PATH} component={ManagerMemberView} />
              <Route exact path={`${MANAGER_MEMBER_EDITOR_PATH}/:userUid`} component={ManagerMemberEditorView} />

              <Route exact path={MANAGER_EVENT_PATH} component={ManagerEventView} />
              <Route exact path={MANAGER_SPOT_PATH} component={ManagerSpotView} />

              <Route exact path={MANAGER_SENTENCE_PATH} component={ManagerSentenceView} />
              <Route exact path={`${MANAGER_SENTENCE_EDITOR_PATH}/:sentenceId`} component={ManagerSentenceEditorView} />
              <Route exact path={MANAGER_WORD_PATH} component={ManagerWordView} />
              <Route exact path={`${MANAGER_WORD_EDITOR_PATH}/:wordId`} component={ManagerWordEditorView} />
              <Route exact path={MANAGER_VIDEO_PATH} component={ManagerVideoView} />
              <Route exact path={`${MANAGER_VIDEO_EDITOR_PATH}/:videoId`} component={ManagerVideoEditorView} />


              <Route exact path={MOBILE_LOGIN_PATH} component={MobileLoginView}  />
              <Route exact path={MOBILE_HOME_PATH} component={MobileHomeView}  />
              <Route exact path={MOBILE_FESTIVAL_PATH} component={MobileFestivalIntroView}  />
              <Route exact path={MOBILE_SCHEDULE_PATH} component={MobileScheduleView}  />
              <Route exact path={MOBILE_MAP_EVENT_PATH} component={MobileGoogleMapEventView}  />
              <Route exact path={`${MOBILE_MAP_SPOT_PATH}/:category`} component={MobileGoogleMapSpotView}  />
              <Route exact path={`${MOBILE_EVENT_PATH}/:eventId`} component={MobileEventView}  />
              <Route exact path={MOBILE_WORD_PATH} component={MobileWordView}  />
              <Route exact path={MOBILE_SENTENCE_PATH} component={MobileSentenceView}  />
              <Route exact path={MOBILE_MYPAGE_PATH} component={MobileMypageView}  />

            </NomalLayout>
          </Switch>
      </Analytics>
    </Router>
  );
};
