import React, { useState, FC, ReactNode, CSSProperties } from 'react';
import { Layout, Row } from 'antd';
import { MainHeader } from 'components/MainHeader';
import { MainFooter } from 'components/MainFooter';
import { MainSidebar } from 'components/MainSidebar';

type PropTypes = {
  children: ReactNode;
};

/**
 * @description 공통 레이아웃
 * @param {ReactNode} children
 */

export const NomalLayout: FC<PropTypes> = ({ children }) => {

  const { Header, Footer, Content } = Layout;
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const getCmsLayout = () => {
    return (
      <div id='MainLayout' style={{display: 'flex', justifyContent: 'center', backgroundSize: '100% 100%'}}>
        <Layout style={LayoutStyle}>
          <MainSidebar collapsed={collapsed} />
          <Layout style={LayoutStyle}>
            <Row id='MainHeader' style={HeaderStyle}>
              <MainHeader />
            </Row>
            <Layout style={LayoutStyle}>
              <Row style={ContentStyle}>
                <div>{children}</div>
              </Row>
            </Layout>
          </Layout>
          <Row id='MainFooter' style={FooterStyle}>
            <MainFooter />
          </Row>
        </Layout>
      </div>
    );

  }

  const getMobileLayout = () => {

    return (
      <div id='MobileMainLayout' style={{display: 'flex', justifyContent: 'center', backgroundSize: '100% 100%'}}>
        <Layout style={LayoutStyle}>
          <MainSidebar collapsed={collapsed} />
          <Layout style={LayoutStyle}>
            <Row id='MobileMainHeader' style={HeaderStyle}>
              <MainHeader />
            </Row>
            <Layout style={LayoutStyle}>
              <Row style={ContentStyle}>
                <div>{children}</div>
              </Row>
            </Layout>
          </Layout>
          <Row id='MobileMainFooter' style={FooterStyle}>
            <MainFooter />
          </Row>
        </Layout>
      </div>
    );
  }


  return (
    <div style={{display: 'flex', justifyContent: 'center', backgroundSize: '100% 100%'}}>
      <Layout id='MainLayout' style={LayoutStyle}>
        <MainSidebar collapsed={collapsed} />
        <Layout style={LayoutStyle}>
          <Row id='MainHeader' style={HeaderStyle}>
            <MainHeader />
          </Row>
          <Layout style={LayoutStyle}>
            <Row style={ContentStyle}>
              <div>{children}</div>
            </Row>
          </Layout>
        </Layout>
        <Row id='MainFooter' style={FooterStyle}>
          <MainFooter />
        </Row>
      </Layout>
    </div>
  );
};

const LayoutStyle = {
  overflow: 'hidden',
  maxWidth: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0)'
};

const HeaderStyle: CSSProperties = {
  width: '100%',
  padding: '0px',
};


const ContentStyle: CSSProperties = {
  padding: '0px',
  maxWidth: '100%', // NOTE: Full Wide XGA / 1366x768 => 16:9
  width: '100%',
  margin: '0 auto'
};

const FooterStyle: CSSProperties = {
  minHeight: '0px',
  padding: '0px',
  backgroundColor: 'rgba(0, 0, 0, 0)'
};
