import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';
import { MobileMenuDrawer, MobileFestivalDialog, MobileSpotDialog } from '.';

declare global {
  interface Window {
    any: any;
  }
}

export const MobileGoogleMapSpotView = (props) => {

  const history = useHistory();

  const [userUid, setUserUid] = useState<number>(0);

  const [spotList, setSpotList] = useState<any[]>([]);
  const [selectedSpot, setSelectedSpot] = useState<any>({});

  const [map, setMap] = useState<any>({});
  const [placeService, setPlaceService] = useState<any>({});
  const [infowindow, setInfowindow] = useState<any>({});
  const [markers, setMarkers] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  const [searchCategory, setSearchCategory] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<number>(-1);

  const [visibleMenuConfirm, setVisibleMenuConfirm] = useState<boolean>(false);
  const [visibleMenuFestivalConfirm, setVisibleMenuFestivalConfirm] = useState<boolean>(false);
  const [visibleMenuSpotConfirm, setVisibleMenuSpotConfirm] = useState<boolean>(false);

  const [visibleFestivalConfirm, setVisibleFestivalConfirm] = useState<boolean>(false);
  const [visibleSpotConfirm, setVisibleSpotConfirm] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');

  const [categoryPosition, setCategoryPosition] = useState<number>(0);

  const [prevButtonDisplay, setPrevButtonDisplay] = useState<string>('none');
  const [nextButtonDisplay, setNextButtonDisplay] = useState<string>('flext');


  const [categoryStateMap, setCategoryStateMap] = useState<Map<string, boolean>>(new Map());
  const [markerList, setMarkerList] = useState<any[]>([]);



  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();
    //$('#LoginPanel').css('background-image', 'url("images/anyeventmime/background/anyeventmime_login_background.png")')

    //$('#MainLayout').css('max-width', '430px');
    //$('#MainLayout').css('max-height', '960px');

    if (storageService.getItem('anyeventmime-user')) {
        var user = JSON.parse( storageService.getItem('anyeventmime-user') as string );
        if(user != undefined) {
          setUserUid(user.userUid);
        }
    }


    categoryStateMap.set('activity', false);
    categoryStateMap.set('sightseeing', false);
    categoryStateMap.set('shopping', false);
    categoryStateMap.set('food', false);
    categoryStateMap.set('accomodation', false);
    categoryStateMap.set('emergency', false);
    categoryStateMap.set('transit', false);

    switch(props.match.params.category) {
      case 'type00' : categoryStateMap.set('activity', true);
                      categoryStateMap.set('sightseeing', true);
                      categoryStateMap.set('shopping', true);
                      categoryStateMap.set('food', true);
                      categoryStateMap.set('accomodation', true);
                      categoryStateMap.set('emergency', true);
                      categoryStateMap.set('transit', true);
                      break;
      case 'type02' : categoryStateMap.set('activity', true);  break;
      case 'type03' : categoryStateMap.set('sightseeing', true);  break;
      case 'type04' : categoryStateMap.set('shopping', true);  break;
      case 'type05' : categoryStateMap.set('food', true);  break;
      case 'type06' : categoryStateMap.set('accomodation', true);  break;
      case 'type07' : categoryStateMap.set('emergency', true);  break;
      case 'type08' : categoryStateMap.set('transit', true);  break;
    }

    getGoogleMap();

  }, [props.match.params.category]);


  const getSpotListData = (googleMap) => {

    var selectedCategory = '';


    if(categoryStateMap.get('activity')) {
      selectedCategory += 'type02,';
      $('#span-activity').css('background', '#2A78FF');
      $('#span-activity').css('color', '#FFFFFF');
    }
    else {
      $('#span-activity').css('background', '#FFFFFF');
      $('#span-activity').css('color', '#002581');
    }

    if(categoryStateMap.get('sightseeing')) {
      selectedCategory += 'type03,';
      $('#span-sightseeing').css('background', '#0DAB47');
      $('#span-sightseeing').css('color', '#FFFFFF');
    }
    else {
      $('#span-sightseeing').css('background', '#FFFFFF');
      $('#span-sightseeing').css('color', '#002581');
    }


    if(categoryStateMap.get('shopping')) {
      selectedCategory += 'type04,';
      $('#span-shopping').css('background', '#AE08C1');
      $('#span-shopping').css('color', '#FFFFFF');
    }
    else {
      $('#span-shopping').css('background', '#FFFFFF');
      $('#span-shopping').css('color', '#002581');
    }

    if(categoryStateMap.get('food')) {
      selectedCategory += 'type05,';
      $('#span-food').css('background', '#FF8800');
      $('#span-food').css('color', '#FFFFFF');
    }
    else {
      $('#span-food').css('background', '#FFFFFF');
      $('#span-food').css('color', '#002581');
    }

    if(categoryStateMap.get('accomodation')) {
      selectedCategory += 'type06,';
      $('#span-accomodation').css('background', '#0DBFC5');
      $('#span-accomodation').css('color', '#FFFFFF');
    }
    else {
      $('#span-accomodation').css('background', '#FFFFFF');
      $('#span-accomodation').css('color', '#002581');
    }

    if(categoryStateMap.get('emergency')) {
      selectedCategory += 'type07,';
      $('#span-emergency').css('background', '#FC3B69');
      $('#span-emergency').css('color', '#FFFFFF');
    }
    else {
      $('#span-emergency').css('background', '#FFFFFF');
      $('#span-emergency').css('color', '#002581');
    }

    if(categoryStateMap.get('transit')) {
      selectedCategory += 'type08,';
      $('#span-transit').css('background', '#6CAB0D');
      $('#span-transit').css('color', '#FFFFFF');
    }
    else {
      $('#span-transit').css('background', '#FFFFFF');
      $('#span-transit').css('color', '#002581');
    }

    setSpotList([]);

    if(selectedCategory == '') {
      while(markerList.length > 0) {
       var marker = markerList.pop();
       marker.setMap(null)
      }
      return;
    }

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/spotsByUserAndCondition.do?' ;
    searchUrl += 'page=0&size=1000';
    searchUrl += '&userUid=' + userUid;
    searchUrl += '&category=' + selectedCategory;
    searchUrl += '&keyword=' + searchKeyword;
    searchUrl += '&status=' + searchStatus;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setSpotList(Object.assign(data));

                    while(markerList.length > 0) {
                      var marker = markerList.pop();
                      marker.setMap(null)
                    }

                    if(data != null) {
                      data.forEach((spot) => createMarker(googleMap, spot));
                    }

                  })
     .catch(err => console.log(err));

  };


  const getGoogleMap = () => {
    // 지도 API
    var mapContainer = document.getElementById('map'); // 지도를 표시할 div

    if(mapContainer == null)
      return;

    var mapOption = {};
    var googleMap = {};

    if ("geolocation" in navigator) {

      navigator.geolocation.getCurrentPosition((position) => {
        //doSomething(position.coords.latitude, position.coords.longitude);

        // 내위치에 마커를 생성합니다
        var myPosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        mapOption = {
          center: myPosition,
          zoom: 14, // 지도의 확대 레벨
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        };
        // 지도를 생성합니다

        googleMap = new window.google.maps.Map(mapContainer, mapOption);
        setMap(googleMap);

        var myMarker = new window.google.maps.Marker({
          position: myPosition,
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        console.log('내위치 : ' + myPosition);
        myMarker.setMap(googleMap);

        getSpotListData(googleMap);
      });

    }
    else {

      mapOption = {
        center: new window.google.maps.LatLng(37.867786, 127.720815),
        zoom: 14, // 지도의 확대 레벨
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      };
      // 지도를 생성합니다

      googleMap = new window.google.maps.Map(mapContainer, mapOption);
      setMap(googleMap);

      getSpotListData(googleMap);
    }
  }


  const getInfoMarkerSrc = (category) => {

    var markerSrc = '';

    switch(category) {
      case 'type02' : markerSrc = 'images/anyeventmime/background/map_activity_marker.png'; break;
      case 'type03' : markerSrc = 'images/anyeventmime/background/map_sightseeing_marker.png'; break;
      case 'type04' : markerSrc = 'images/anyeventmime/background/map_shopping_marker.png'; break;
      case 'type05' : markerSrc = 'images/anyeventmime/background/map_food_marker.png'; break;
      case 'type06' : markerSrc = 'images/anyeventmime/background/map_accomodation_marker.png'; break;
      case 'type07' : markerSrc = 'images/anyeventmime/background/map_emergency_marker.png'; break;
      case 'type08' : markerSrc = 'images/anyeventmime/background/map_transit_marker.png'; break;
      default : markerSrc = 'images/anyeventmime/background/map_food_marker.png'; break;
    }

    return markerSrc;

  }

  const getInfoHeader = (category) => {

    var header = '<div>'
    switch(category) {
      case 'type02' : header += '<span><div style="padding:5px; background-color: #2A78FF; color: #FFFFFF; font-size: 12px; font-weight: bold;">activity</div></span>'; break;
      case 'type03' : header += '<span><div style="padding:5px; background-color: #0DAB47; color: #FFFFFF; font-size: 12px; font-weight: bold;">sightseeing</div></span>'; break;
      case 'type04' : header += '<span><div style="padding:5px; background-color: #AE08C1; color: #FFFFFF; font-size: 12px; font-weight: bold;">shopping</div></span>'; break;
      case 'type05' : header += '<span><div style="padding:5px; background-color: #FFCC01; color: #FFFFFF; font-size: 12px; font-weight: bold;">food</div></span>'; break;
      case 'type06' : header += '<span><div style="padding:5px; background-color: #0DBFC5; color: #FFFFFF; font-size: 12px; font-weight: bold;">accomodation</div></span>'; break;
      case 'type07' : header += '<span><div style="padding:5px; background-color: #FC3B69; color: #FFFFFF; font-size: 12px; font-weight: bold;">emergency</div></span>'; break;
      case 'type08' : header += '<span><div style="padding:5px; background-color: #6CAB0D; color: #FFFFFF; font-size: 12px; font-weight: bold;">transit</div></span>'; break;
      default : header += '<span><div style="padding:5px; background-color: #FFCC01; color: #FFFFFF; font-size: 12px; font-weight: bold;">food</div></span>'; break;
    }

    //header +='<span><div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="width: 24px; height: 24px; background: rgb(184, 184, 184); border-radius: 15px;"><i aria-label="icon: book" class="anticon anticon-book" style="color: rgb(255, 255, 255);"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="book" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zM668 345.9L621.5 312 572 347.4V124h96v221.9z"></path></svg></i></div></span>';

    header += '</div>'

    return header;
  }

  const getCategoryName = (category) => {

    var categoryName = ''
    switch(category) {
      case 'type02' : categoryName = 'Activity'; break;
      case 'type03' : categoryName = 'Sightseeing'; break;
      case 'type04' : categoryName = 'Shopping'; break;
      case 'type05' : categoryName = 'Food'; break;
      case 'type06' : categoryName = 'Accomodation'; break;
      case 'type07' : categoryName = 'Emergency'; break;
      case 'type08' : categoryName = 'Transit'; break;
    }

    return categoryName;
  }


  const createMarker = (googleMap, spot) => {

    //var imageSrc = 'images/anyeventmime/background/map_food_marker.png'; // 마커이미지의 주소입니다
    var imageSrc = getInfoMarkerSrc(spot.category); // 마커이미지의 주소입니다
    var markerPosition = new window.google.maps.LatLng(spot.latitude, spot.longitude); // 마커가 표시될 위치입니다

    // 마커를 생성합니다
    var marker = new window.google.maps.Marker({
      position: markerPosition,
      icon: {
        url: imageSrc,
        scaledSize: new window.google.maps.Size(26, 32)
      } // 마커이미지 설정
    });


    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(googleMap);

    markerList.push(marker);


    // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우를 생성합니다
    var iwContent = '<div style="background: #ffffff; width: 220px; border: 1px solid rgb(118, 129, 168); margin-top: -1px; margin-left: -1px; font-size: 12px;">';
    //iwContent += '<div style="padding:5px; background-color: #FFCC01; color: #002581; font-size: 12px; font-weight: bold;">공연일정</div>'; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    iwContent += getInfoHeader(spot.category); // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    iwContent +='<div style="margin-top: 10px; padding:5px;"><span> 유형 </span><span> : </span><span> ' + getCategoryName(spot.category) + ' </span></div>';

    iwContent +='<div style="padding:5px; "><hr /></div>';
    iwContent +='<div style="padding:5px;"><span> 이름 </span><span> : </span><span> ' + spot.name + ' </span></div>';
    iwContent +='<div style="padding:5px;"><hr /></div>';
    iwContent +='<div style="padding:5px;"><span> 주소 </span><span> : </span><span> ' + spot.address + ' </span></div>';
    iwContent +='<div style="padding:5px;"><hr /></div>';
    iwContent +='<div style="padding:5px;"><span> 전화번호 </span><span> : </span><span> ' + spot.contact + ' </span></div>';

    iwContent +='<div style="padding:5px;"><hr /></div>';

    if(spot.linkUrl != null && spot.linkUrl != '') {
      iwContent +='<div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="padding:5px; margin-bottom: 5px">';
      iwContent +='<div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="width: 100px; height: 30px; margin-left: 10px; border-radius: 15px; color: #002581; border: 1px solid #002581; font-size: 12px; font-weight: bold; background: #FFFFFF;">';
      iwContent += '자세히보기</div></div>';
    }

    var  iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다

    // 인포윈도우를 생성합니다
    var infowindow = new window.google.maps.InfoWindow({
        content : iwContent,
        removable : iwRemoveable
    });

    // 마커에 클릭이벤트를 등록합니다
    window.google.maps.event.addListener(marker, 'click', function() {
          // 마커 위에 인포윈도우를 표시합니다
          //infowindow.open(daumMap, marker);
          setSelectedSpot(spot);
          setVisibleSpotConfirm(true);
    });
  }


  const createSpotBookmark = (spot) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/bookmark.do' ;

    var params = {
                  bookmarkType: 1,
                  userUid: userUid,
                  spotId: spot.spotId
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      console.log(data);

                      var tempSpot = Object.assign({}, selectedSpot);
                      tempSpot.bookmarkId = data.bookmarkId;
                      setSelectedSpot(tempSpot);

                    })
      .catch(err => console.log(err));

  };

  const deleteSpotBookmark = (spot) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/bookmarkDelete.do' ;

    var params = {
                  bookmarkId: spot.bookmarkId
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      console.log(data);

                      var tempSpot = Object.assign({}, selectedSpot);
                      tempSpot.bookmarkId = 0;
                      setSelectedSpot(tempSpot);

                    })
      .catch(err => console.log(err));

  };


  const calculatePosition = (delta) => {

    var position = categoryPosition + delta;

    setCategoryPosition(position);

    if(position < 0)
      setPrevButtonDisplay('flex');
    else
      setPrevButtonDisplay('none');


    if(position > -570)
      setNextButtonDisplay('flex');
    else
      setNextButtonDisplay('none');
  }

  const checkCategory = (category) => {

    categoryStateMap.set(category, !categoryStateMap.get(category));


    getSpotListData(map);

  }

  const { SubMenu } = Menu;

  const getMap = () => {

    return(
      <Row>
        <div id='map' className='daum_map' style={{position: 'relative', overflow: 'hidden', width: '100vw', height: '100vh', margin: '0', padding: '0', color: '#000000'}} />
        <div style={{position: 'absolute', top: 18, left: 26, zIndex: 1}}>
          <img src='images/anyeventmime/background/icon_mobile_menu.png'  style={{height:44}} onClick={() => setVisibleMenuConfirm(true)}/>
        </div>
        <div style={{position: 'absolute', top: 18, right: 18, zIndex: 1}}>
          <img src='images/anyeventmime/background/button_minifestival.png'  style={{height:32}} onClick={() => setVisibleFestivalConfirm(true)}/>
        </div>


      </Row>
    );
  }



  return (
    <>

      <div style={{backgroundColor:'#FFCC01'}}>
        <Row type='flex' justify='center' align='top' style={{width: '100%', height: '100%'}}>

          <Col span={24}>
            {getMap()}
          </Col>

          <Col span={24} style={{position: 'fixed', height: 72, left: 0, bottom: 0, backgroundColor: 'rgba(0, 37, 129, 0.2)', borderRadius: '25px 25px 0px 0px', zIndex: 999}}>
            <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%', paddingLeft: 10, paddingRight: 10}} >
              <Col span={2}>
                <Row type='flex' justify='start' align='middle' style={{width: '100%'}}>
                  <Icon type="left" style={{display: prevButtonDisplay, fontSize: 20}}  onClick={() => calculatePosition(30)}/>
                </Row>
              </Col>
              <Col span={20} style={{overflowX: 'scroll' }}>
                <Row style={{display:'flex', marginLeft: categoryPosition}}>
                  <span id='span-activity' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#2A78FF'}} onClick={() => {checkCategory('activity')}}> activity </span>
                  <span id='span-sightseeing' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#0DAB47'}} onClick={() => {checkCategory('sightseeing')}}> sightseeing </span>
                  <span id='span-shopping' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#AE08C1'}} onClick={() => {checkCategory('shopping')}}> shopping </span>
                  <span id='span-food' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#FFCC01'}} onClick={() => {checkCategory('food')}}> food </span>
                  <span id='span-accomodation' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#0DBFC5'}} onClick={() => {checkCategory('accomodation')}}> accomodation </span>
                  <span id='span-emergency' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#FC3B69'}} onClick={() => {checkCategory('emergency')}}> emergency </span>
                  <span id='span-transit' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#6CAB0D'}} onClick={() => {checkCategory('transit')}}> transit </span>
                </Row>
              </Col>
              <Col span={2}>
                <Row type='flex' justify='end' align='middle' style={{width: '100%'}}>
                  <Icon type="right" style={{display: nextButtonDisplay, fontSize: 20}}  onClick={() => calculatePosition(-30)}/>
                </Row>
              </Col>
            </Row>

          </Col>

        </Row>

      </div>

      <MobileMenuDrawer
        visibleMenuConfirm = {visibleMenuConfirm}
        onVisibleMenuConfirm={(value)=>setVisibleMenuConfirm(false)}
      />

      <MobileFestivalDialog
        visibleFestivalConfirm = {visibleFestivalConfirm}
        onVisibleFestivalConfirm={(value)=>setVisibleFestivalConfirm(false)}
      />

      <MobileSpotDialog
        userUid = {userUid}
        spot = {selectedSpot}
        visibleSpotConfirm = {visibleSpotConfirm}
        onVisibleSpotConfirm={(value)=>setVisibleSpotConfirm(false)}
        onCreateBookmark={(value) => createSpotBookmark(value)}
        onDeleteBookmark={(value) => deleteSpotBookmark(value)}
      />



    </>
  );

};
