import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_WORD_EDITOR_PATH,
        storageService
      } from 'common';

import { MobileMenuDrawer, MobileFestivalDialog, MobileWordDetailDialog } from '.';

export const MobileWordView = (props) => {

  const history = useHistory();

  const [userUid, setUserUid] = useState<number>(0);

  const [wordList, setWordList] = useState<any[]>([]);
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [goCurrentPage, setGoCurrentPage] = useState<number>(1);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchCategory, setSearchCategory] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<number>(1);

  const [selectedWordId, setSelectedWordId] = useState<number>(0);
  const [visibleWordConfirm, setVisibleWordConfirm] = useState<boolean>(false);

  const [visibleMenuConfirm, setVisibleMenuConfirm] = useState<boolean>(false);
  const [visibleFestivalConfirm, setVisibleFestivalConfirm] = useState<boolean>(false);

  const [categoryPosition, setCategoryPosition] = useState<number>(0);

  const [prevButtonDisplay, setPrevButtonDisplay] = useState<string>('none');
  const [nextButtonDisplay, setNextButtonDisplay] = useState<string>('flex');

  const [categoryStateMap, setCategoryStateMap] = useState<Map<string, boolean>>(new Map());


  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();

    if (storageService.getItem('anyeventmime-user')) {
        var user = JSON.parse( storageService.getItem('anyeventmime-user') as string );
        if(user != undefined) {
          setUserUid(user.userUid);
        }
    }

    var selectedCategory = '';

    categoryStateMap.set('activity', true);
    categoryStateMap.set('sightseeing', true);
    categoryStateMap.set('shopping', true);
    categoryStateMap.set('food', true);
    categoryStateMap.set('accomodation', true);
    categoryStateMap.set('emergency', true);
    categoryStateMap.set('transit', true);

    if(categoryStateMap.get('activity')) {
      selectedCategory += 'type02,';
      $('#span-activity').css('background', '#FFCC01');
    }
    else {
      $('#span-activity').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('sightseeing')) {
      selectedCategory += 'type03,';
      $('#span-sightseeing').css('background', '#FFCC01');
    }
    else {
      $('#span-sightseeing').css('background', '#FFFFFF');
    }


    if(categoryStateMap.get('shopping')) {
      selectedCategory += 'type04,';
      $('#span-shopping').css('background', '#FFCC01');
    }
    else {
      $('#span-shopping').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('food')) {
      selectedCategory += 'type05,';
      $('#span-food').css('background', '#FFCC01');
    }
    else {
      $('#span-food').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('accomodation')) {
      selectedCategory += 'type06,';
      $('#span-accomodation').css('background', '#FFCC01');
    }
    else {
      $('#span-accomodation').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('emergency')) {
      selectedCategory += 'type07,';
      $('#span-emergency').css('background', '#FFCC01');
    }
    else {
      $('#span-emergency').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('transit')) {
      selectedCategory += 'type08,';
      $('#span-transit').css('background', '#FFCC01');
    }
    else {
      $('#span-transit').css('background', '#FFFFFF');
    }

    getWordCount(searchCategory, searchKeyword, searchStatus);

    setSearchCategory(selectedCategory)

  }, []);



  const getWordListData = (page, pageSize, category, keyword, status) => {

    console.log('page : ' + page + ' , pageSize : ' + pageSize);

    setCurrentPage(page);
    setGoCurrentPage(page);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/wordsByCondition.do?' ;
    searchUrl += 'page=' + (page - 1) + '&size=' + size;
    searchUrl += '&category=' + category;
    searchUrl += '&keyword=' + keyword;
    searchUrl += '&status=1';


    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setWordList(Object.assign(data));
                    setCurrentPage(currentPage + 1);
                  })
     .catch(err => console.log(err));

  };

  const getWordDataListContinue = (page, size) => {

    console.log('page : ' + page + ' , pageSize : ' + size);
    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/wordsByCondition.do?';
    searchUrl += 'page=' + (page- 1) + '&size=' + size;
    searchUrl += '&category=' + searchCategory;
    searchUrl += '&keyword=' + searchKeyword;
    searchUrl += '&status=1';

    fetch(
      searchUrl
    ).then(res => res.json())
     .then(data => {
                      console.log(data)
                      setWordList(wordList.concat(Object.assign(data)));
                      setCurrentPage(currentPage + 1);
                   })
     .catch(function(t) {
        return console.log(t)
    })
  }


  const getWordCount = (category, keyword, status) => {

    setCurrentPage(1);
    setWordList([]);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/wordCountByCondition.do?' ;
    searchUrl += 'category=' + category;
    searchUrl += '&keyword=' + keyword;
    searchUrl += '&status=' + status;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setTotalCount(data);

                      if(data > 0)
                        getWordListData(1, size, category, keyword, status);

                     })
     .catch(err => console.log(err));

  };


  const createWordBookmark = (wordId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/bookmark.do' ;

    var params = {
                  bookmarkType: 2,
                  userUid: userUid,
                  wordId: wordId
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      console.log(data);
                    })
      .catch(err => console.log(err));

  };


  const calculatePosition = (delta) => {

    var position = categoryPosition + delta;

    setCategoryPosition(position);

    if(position < 0)
      setPrevButtonDisplay('flex');
    else
      setPrevButtonDisplay('none');


    if(position > -570)
      setNextButtonDisplay('flex');
    else
      setNextButtonDisplay('none');
  }


  const checkCategory = (category) => {

    categoryStateMap.set(category, !categoryStateMap.get(category));

    var selectedCategory = '';

    if(categoryStateMap.get('activity')) {
      selectedCategory += 'type02,';
      $('#span-activity').css('background', '#FFCC01');
    }
    else {
      $('#span-activity').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('sightseeing')) {
      selectedCategory += 'type03,';
      $('#span-sightseeing').css('background', '#FFCC01');
    }
    else {
      $('#span-sightseeing').css('background', '#FFFFFF');
    }


    if(categoryStateMap.get('shopping')) {
      selectedCategory += 'type04,';
      $('#span-shopping').css('background', '#FFCC01');
    }
    else {
      $('#span-shopping').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('food')) {
      selectedCategory += 'type05,';
      $('#span-food').css('background', '#FFCC01');
    }
    else {
      $('#span-food').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('accomodation')) {
      selectedCategory += 'type06,';
      $('#span-accomodation').css('background', '#FFCC01');
    }
    else {
      $('#span-accomodation').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('emergency')) {
      selectedCategory += 'type07,';
      $('#span-emergency').css('background', '#FFCC01');
    }
    else {
      $('#span-emergency').css('background', '#FFFFFF');
    }

    if(categoryStateMap.get('transit')) {
      selectedCategory += 'type08,';
      $('#span-transit').css('background', '#FFCC01');
    }
    else {
      $('#span-transit').css('background', '#FFFFFF');
    }

    getWordCount(selectedCategory, searchKeyword, searchStatus);

    setSearchCategory(selectedCategory);

  }


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }



  return (
    <>
      <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 60,  paddingLeft: 10, paddingRight: 10, backgroundColor: '#FFCC01', borderBottom: 'solid 0.5px rgba(112, 112, 112, 0.2)'}}>
        <img src='images/anyeventmime/background/icon_mobile_menu.png'  style={{height:44}} onClick={() => setVisibleMenuConfirm(true)}/>
        <img src='images/anyeventmime/background/button_minifestival_content.png'  style={{height:32}} onClick={() => setVisibleFestivalConfirm(true)}/>
      </Row>

      <Row className='word-content' style={{height: '85vh', overflowY: 'scroll', backgroundColor: '#FFCC01'}}>

        <Row type='flex' justify='center' style={{marginTop: 10}}>
          <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 40, margin: '10px 30px', paddingLeft: 10, paddingRight: 20, backgroundColor: '#FFFFFF', borderRadius: 20}}>
            <Input placeholder='Search' style={{width: '80%', height: '30px', color: '#002581'}}
                   onChange={e => setSearchKeyword(e.target.value)}
                   onKeyDown={e => {if(e.key == 'Enter') getWordCount(searchCategory, searchKeyword, searchStatus);}}/>
            <Icon type="search" style={{color: '#002581'}} onClick={() =>{getWordCount(searchCategory, searchKeyword, searchStatus)}}/>
          </Row>
        </Row>

        <Row type='flex' justify='center' style={{marginTop: 0}}>
          {wordList ? (
            wordList.map( word => (
              <Row key={word.wordId} type='flex' justify='start' align='middle' style={{width: '100%', margin: '5px 30px', padding: 10, backgroundColor: '#FFFFFF', borderRadius: 10}}>

                <Col span={22} onClick={() => {setSelectedWordId(word.wordId); setVisibleWordConfirm(true);}} >

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    {word.word}
                  </Row>
                  <Row type='flex' justify='start' style={{width: '100%'}}>
                    {word.description}
                  </Row>
                  <Row type='flex' justify='start' style={{width: '100%'}}>
                    {word.koreanExample}
                  </Row>
                </Col>
                <Col span={2}>
                  <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%'}}>
                    <Row type='flex' justify='center' align='middle' style={{width: 24, height: 24, background: '#B8B8B8', borderRadius: 15}} onClick={() => createWordBookmark(word.wordId)}>
                      <Icon type="book" theme="filled" style={{color: '#ffffff'}}/>
                    </Row>
                  </Row>
                </Col>
              </Row>
            ))
          ) : (
            <Row />
          )}
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='center' align='middle'
                 style={{margin: '20px 30px 40px 30px', paddingLeft: 30, paddingRight: 30, height: 40, border: 'solid 0.5px #002581'}}
                 onClick={() => {if(wordList.length < totalCount) getWordDataListContinue(currentPage, size); }}>
                <span style={{color: '#002581', fontWeight: 'bold'}}> + 더보기 </span>
            </Row>
          </Row>
        </Row>
      </Row>

      <Row style={{position:'fixed', width: '100%', backgroundColor: '#FFCC01', bottom: 0}}>
        <Col span={24} style={{height: 72, backgroundColor: '#002581', borderRadius: '25px 25px 0px 0px'}}>
          <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%', paddingLeft: 10, paddingRight: 10}} >
            <Col span={2}>
              <Row type='flex' justify='start' align='middle' style={{width: '100%'}}>
                <Icon type="left" style={{display: prevButtonDisplay, fontSize: 20, color: '#ffffff'}}  onClick={() => calculatePosition(30)}/>
              </Row>
            </Col>
            <Col span={20} style={{overflowX: 'scroll'}}>
              <Row style={{display:'flex', marginLeft: categoryPosition, fontWeight: 600}}>
                <span id='span-activity' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('activity')}}> activity </span>
                <span id='span-sightseeing' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('sightseeing')}}> sightseeing </span>
                <span id='span-shopping' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('shopping')}}> shopping </span>
                <span id='span-food' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('food')}}> food </span>
                <span id='span-accomodation' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('accomodation')}}> accomodation </span>
                <span id='span-emergency' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('emergency')}}> emergency </span>
                <span id='span-transit' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01'}} onClick={() => {checkCategory('transit')}}> transit </span>
            </Row>
            </Col>
            <Col span={2}>
              <Row type='flex' justify='end' align='middle' style={{width: '100%'}}>
                <Icon type="right" style={{display: nextButtonDisplay, fontSize: 20, color: '#ffffff'}}  onClick={() => calculatePosition(-30)}/>
              </Row>
            </Col>
          </Row>

        </Col>
      </Row>


      <MobileWordDetailDialog
          wordId={selectedWordId}
          visibleWordConfirm={visibleWordConfirm}
          onVisibleWordConfirm={(value)=>{setVisibleWordConfirm(value);}}
        />

      <MobileMenuDrawer
        visibleMenuConfirm = {visibleMenuConfirm}
        onVisibleMenuConfirm={(value)=>setVisibleMenuConfirm(false)}
      />

      <MobileFestivalDialog
        visibleFestivalConfirm = {visibleFestivalConfirm}
        onVisibleFestivalConfirm={(value)=>setVisibleFestivalConfirm(false)}
      />

    </>
  );

};
