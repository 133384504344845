import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';
import { MobileMenuDrawer, MobileFestivalDialog, MobileEventDialog } from '.';

declare global {
  interface Window {
    google: any;
  }
}


export const MobileGoogleMapEventView = (props) => {

  const history = useHistory();

  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [eventList, setEventList] = useState<any[]>([]);

  const [map, setMap] = useState<any>({});
  const [placeService, setPlaceService] = useState<any>({});
  const [infowindow, setInfowindow] = useState<any>({});
  const [markers, setMarkers] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  const [visibleMenuConfirm, setVisibleMenuConfirm] = useState<boolean>(false);
  const [visibleMenuFestivalConfirm, setVisibleMenuFestivalConfirm] = useState<boolean>(false);
  const [visibleMenuSpotConfirm, setVisibleMenuSpotConfirm] = useState<boolean>(false);

  const [visibleFestivalConfirm, setVisibleFestivalConfirm] = useState<boolean>(false);
  const [visibleEventConfirm, setVisibleEventConfirm] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>({});

  const [title, setTitle] = useState<string>('');

  const [categoryPosition, setCategoryPosition] = useState<number>(0);

  const [prevButtonDisplay, setPrevButtonDisplay] = useState<string>('none');
  const [nextButtonDisplay, setNextButtonDisplay] = useState<string>('flex');

  const [eventStateMap, setEventStateMap] = useState<Map<string, boolean>>(new Map());
  const [markerList, setMarkerList] = useState<any[]>([]);
  const [circleList, setCircleList] = useState<any[]>([]);



  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();
    //$('#LoginPanel').css('background-image', 'url("images/anyeventmime/background/anyeventmime_login_background.png")')

    //$('#MainLayout').css('max-width', '430px');
    //$('#MainLayout').css('max-height', '960px');

    eventStateMap.set('event1', true);
    eventStateMap.set('event2', true);
    eventStateMap.set('event3', true);
    eventStateMap.set('event4', true);
    eventStateMap.set('event5', true);
    eventStateMap.set('event6', true);
    eventStateMap.set('event7', true);

    //getDaumMap();
    getGoogleMap();

  }, []);


  const getEventListData = (googleMap) => {

    var selectedEvent = '';

    if(eventStateMap.get('event1')) {
      selectedEvent += '1,';
      $('#span-event1').css('background', '#FFCC01');
    }
    else {
      $('#span-event1').css('background', '#FFFFFF');
    }

    if(eventStateMap.get('event2')) {
      selectedEvent += '2,';
      $('#span-event2').css('background', '#FFCC01');
    }
    else {
      $('#span-event2').css('background', '#FFFFFF');
    }


    if(eventStateMap.get('event3')) {
      selectedEvent += '3,';
      $('#span-event3').css('background', '#FFCC01');
    }
    else {
      $('#span-event3').css('background', '#FFFFFF');
    }

    if(eventStateMap.get('event4')) {
      selectedEvent += '4,';
      $('#span-event4').css('background', '#FFCC01');
    }
    else {
      $('#span-event4').css('background', '#FFFFFF');
    }

    if(eventStateMap.get('event5')) {
      selectedEvent += '5,';
      $('#span-event5').css('background', '#FFCC01');
    }
    else {
      $('#span-event5').css('background', '#FFFFFF');
    }

    if(eventStateMap.get('event6')) {
      selectedEvent += '6,';
      $('#span-event6').css('background', '#FFCC01');
    }
    else {
      $('#span-event6').css('background', '#FFFFFF');
    }

    if(eventStateMap.get('event7')) {
      selectedEvent += '7,';
      $('#span-event7').css('background', '#FFCC01');
    }
    else {
      $('#span-event7').css('background', '#FFFFFF');
    }

    setEventList([]);

    if(selectedEvent == '') {
      while(markerList.length > 0) {
       var marker = markerList.pop();
       marker.setMap(null)
      }

      while(circleList.length > 0) {
       var circle = circleList.pop();
       circle.setMap(null)
      }
      return;
    }


    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/eventsByIds.do?' ;
    searchUrl += 'page=0&size=1000';
    searchUrl += '&idlist=' + selectedEvent;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {

                    while(markerList.length > 0) {
                     var marker = markerList.pop();
                     marker.setMap(null)
                    }

                    while(circleList.length > 0) {
                     var circle = circleList.pop();
                     circle.setMap(null)
                    }

                    console.log(data);
                    setEventList(Object.assign(data));

                    if(data != null) {
                      data.forEach((event) => createMarker(googleMap, event));
                    }

                  })
     .catch(err => console.log(err));

  };



  const getGoogleMap = () => {
    // 지도 API
    var mapContainer = document.getElementById('map'); // 지도를 표시할 div

    if(mapContainer == null)
      return;

    var mapOption = {};
    var googleMap = {};

    if ("geolocation" in navigator) {

      navigator.geolocation.getCurrentPosition((position) => {
        //doSomething(position.coords.latitude, position.coords.longitude);

        // 내위치에 마커를 생성합니다
        var myPosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        mapOption = {
          center: myPosition,
          zoom: 14, // 지도의 확대 레벨
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        };
        // 지도를 생성합니다

        googleMap = new window.google.maps.Map(mapContainer, mapOption);
        setMap(googleMap);

        var myMarker = new window.google.maps.Marker({
          position: myPosition,
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        console.log('내위치 : ' + myPosition);
        myMarker.setMap(googleMap);

        getEventListData(googleMap);
      });

    }
    else {

      mapOption = {
        center: new window.google.maps.LatLng(37.867786, 127.720815),
        zoom: 14, // 지도의 확대 레벨
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      };
      // 지도를 생성합니다

      googleMap = new window.google.maps.Map(mapContainer, mapOption);
      setMap(googleMap);

      getEventListData(googleMap);
    }
  }


  const createMarker = (googleMap, event) => {

    var imageSrc = 'images/anyeventmime/background/map_event_marker.png'; // 마커이미지의 주소입니다
    var imageSize = new window.google.maps.Size(70, 88); // 마커이미지의 크기입니다
    var imageOption = {offset: new window.google.maps.Point(35, 88)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

    var markerImage = new window.google.maps.MarkerImage(imageSrc, imageSize, imageOption);

    var imageElement = document.createElement('img');
    imageElement.src = 'images/anyeventmime/background/map_event_marker.png';
    imageElement.style.width = '70px';
    imageElement.style.height = 'auto';

    var markerPosition = new window.google.maps.LatLng(event.latitude, event.longitude); // 마커가 표시될 위치입니다

    // 마커를 생성합니다
    var marker = new window.google.maps.Marker({
      position: markerPosition,
      icon: {
        url: imageSrc,
        scaledSize: new window.google.maps.Size(60, 77)
      }// 마커이미지 설정
    });


    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(googleMap);
    markerList.push(marker);


    // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우를 생성합니다
    var iwContent = '<div style="background: #ffffff; border: 1px solid rgb(118, 129, 168)"><div style="padding:5px; background-color: #FFCC01; color: #002581; font-size: 12px; font-weight: bold;">공연일정</div>'; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    iwContent +='<div style="padding:5px;">' + event.description + '</div>';

    iwContent +='<div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="padding:5px; background:#002581; border-top: 1px solid rgb(118, 129, 168)">';
    iwContent +='<div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="width: 100px; height: 30px; margin-left: 10px; border-radius: 15px 15px 0px; color: #002581; font-size: 12px; font-weight: bold; background: #FFFFFF;">';
    iwContent += '자세히보기</div></div>';


    var  iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다

    // 인포윈도우를 생성합니다
    var infowindow = new window.google.maps.InfoWindow({
        content : iwContent,
        removable : iwRemoveable
    });

    // 마커에 클릭이벤트를 등록합니다
    window.google.maps.event.addListener(marker, 'click', function() {
          // 마커 위에 인포윈도우를 표시합니다
          //infowindow.open(daumMap, marker);

          setSelectedEvent(event);
          setVisibleEventConfirm(true);
    });


    var circle = new window.google.maps.Circle({
        center : new window.google.maps.LatLng(event.latitude, event.longitude),  // 원의 중심좌표 입니다
        radius: event.radius, // 미터 단위의 원의 반지름입니다
        strokeWeight: 0, // 선의 두께입니다
        strokeColor: '#75B8FA', // 선의 색깔입니다
        strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'dashed', // 선의 스타일 입니다
        fillColor: '#002581', // 채우기 색깔입니다
        fillOpacity: 0.4  // 채우기 불투명도 입니다
    });

    // 지도에 원을 표시합니다
    circle.setMap(googleMap);
    circleList.push(circle);

  }


  const getMap = () => {

    return(
      <Row>
        <div id='map' className='daum_map' style={{position: 'relative', overflow: 'hidden', width: '100vw', height: '100vh', margin: '0', padding: '0', color: '#000000'}} />
        <div style={{position: 'absolute', top: 18, left: 26, zIndex: 1}}>
          <img src='images/anyeventmime/background/icon_mobile_menu.png'  style={{height:44}} onClick={() => setVisibleMenuConfirm(true)}/>
        </div>
        <div style={{position: 'absolute', top: 18, right: 18, zIndex: 1}}>
          <img src='images/anyeventmime/background/button_minifestival.png'  style={{height:32}} onClick={() => setVisibleFestivalConfirm(true)}/>
        </div>


      </Row>
    );
  }

  const calculatePosition = (delta) => {

    var position = categoryPosition + delta;

    setCategoryPosition(position);

    if(position < 0)
      setPrevButtonDisplay('flex');
    else
      setPrevButtonDisplay('none');


    if(position > -1410)
      setNextButtonDisplay('flex');
    else
      setNextButtonDisplay('none');
  }


  const checkEvent = (event) => {

    eventStateMap.set(event, !eventStateMap.get(event));

    getEventListData(map);

    //getWordCount(selectedCategory, searchKeyword, searchStatus);

    //setSearchCategory(selectedCategory);

  }


  const { SubMenu } = Menu;

  return (
    <>

      <div style={{backgroundColor:'#FFCC01'}}>
        <Row type='flex' justify='center' align='top' style={{width: '100%', height: '100%'}}>

          <Col span={24}>
            {getMap()}
          </Col>

          <Col span={24} style={{position: 'fixed', height: 72, left: 0, bottom: 0, backgroundColor: 'rgba(0, 37, 129, 0.2)', borderRadius: '25px 25px 0px 0px', zIndex: 999}}>
            <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%', paddingLeft: 10, paddingRight: 10}} >
              <Col span={2}>
                <Row type='flex' justify='start' align='middle' style={{width: '100%'}}>
                  <Icon type="left" style={{display: prevButtonDisplay, fontSize: 20}}  onClick={() => calculatePosition(30)}/>
                </Row>
              </Col>
              <Col span={20} style={{overflowX: 'scroll' }}>
                <Row style={{display:'flex', marginLeft: categoryPosition}}>
                  <span id='span-event1' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event1')}}> City of Water&#59; Ah&#33; Surajang </span>
                  <span id='span-event2' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event2')}}> Theater Performace 1 - Kaleidoscope &#39;Kaleidoscope&#39; </span>
                  <span id='span-event3' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event3')}}> Theater Performance 2 - Marine Boy - &#39;The Voyage&#39; </span>
                  <span id='span-event4' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event4')}}> City of Spring </span>
                  <span id='span-event5' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event5')}}> Festival Theater MIME Outdoor Performance </span>
                  <span id='span-event6' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event6')}}> City of Fire&#59; Dokkaebi Nanjang </span>
                  <span id='span-event7' style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#002581', background: '#FFCC01', whiteSpace: 'nowrap'}} onClick={() => {checkEvent('event7')}}> Daytime Dokkaebi Nanjang </span>
                </Row>
              </Col>
              <Col span={2}>
                <Row type='flex' justify='end' align='middle' style={{width: '100%'}}>
                  <Icon type="right" style={{display: nextButtonDisplay, fontSize: 20}}  onClick={() => calculatePosition(-30)}/>
                </Row>
              </Col>
            </Row>

          </Col>

        </Row>

      </div>


      <MobileMenuDrawer
        visibleMenuConfirm = {visibleMenuConfirm}
        onVisibleMenuConfirm={(value)=>setVisibleMenuConfirm(false)}
      />

      <MobileFestivalDialog
        visibleFestivalConfirm = {visibleFestivalConfirm}
        onVisibleFestivalConfirm={(value)=>setVisibleFestivalConfirm(false)}
      />

      <MobileEventDialog
        event = {selectedEvent}
        visibleEventConfirm = {visibleEventConfirm}
        onVisibleEventConfirm={(value)=>setVisibleEventConfirm(false)}
      />

    </>
  );

};
