import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MOBILE_SENTENCE_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const MobileSentenceDetailDialog = (props) => {

  const { sentence, voices, visibleSentenceConfirm, onVisibleSentenceConfirm, onCreateBookmark, onDeleteBookmark } = props;

  const history = useHistory();


  useEffect(() => {


  }, [visibleSentenceConfirm]);


  const speech = (txt) => {
    if(!window.speechSynthesis) {
      alert("음성 재생을 지원하지 않는 브라우저입니다. 크롬, 파이어폭스 등의 최신 브라우저를 이용하세요");
      return;
    }

    var utterThis = new SpeechSynthesisUtterance(txt);

    utterThis.onend = function (event) {
      console.log('end');
    };
    utterThis.onerror = function(event) {
      console.log('error', event);
    };

    var lang = 'en-US';
    var lang2 = 'en_US';
    var voiceFound = false;

    var voicelist = '';

    voices.forEach(voice => {
      console.log(voice.lang + '\n');
      voicelist += voice.lang + ',';

      if(voice.lang == lang || voice.lang == lang2) {
        utterThis.voice = voice;
        voiceFound = true;
      }
    });

    if(!voiceFound) {
      alert('voice not found : voicelist = ' + voicelist);
      return;
    }

    utterThis.lang = lang;
    utterThis.pitch = 1;
    utterThis.rate = 1;  //속도
    window.speechSynthesis.speak(utterThis);

  }

  const getCategoryName = (category) => {

    var categoryName = ''
    switch(category) {
      case 'type02' : categoryName = 'Activity'; break;
      case 'type03' : categoryName = 'Sightseeing'; break;
      case 'type04' : categoryName = 'Shopping'; break;
      case 'type05' : categoryName = 'Food'; break;
      case 'type06' : categoryName = 'Accomodation'; break;
      case 'type07' : categoryName = 'Emergency'; break;
      case 'type08' : categoryName = 'Transit'; break;
    }

    return categoryName;
  }

  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }


  return (
    <Modal
        className='popup-content'
         visible={visibleSentenceConfirm}
         closable={false}
         title=""
         width={'100vw'}
         style={{ top: 0, height: '100vh' }}
         footer={null}
         >
         <Row className='sentence-content' style={{height: '100vh', overflowY: 'scroll', backgroundColor: '#FFCC01'}}>

           <Row type='flex' justify='center' style={{marginTop: 50}}>
             <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 40, margin: '10px 30px', paddingLeft: 20, paddingRight: 20, backgroundColor: '#FFFFFF', borderRadius: 10}}>
               <Col>
                <span > <Icon type="check"  style={{color: '#FDB813', fontSize: 18, fontWeight: 800}}/> </span>
                <span style={{marginLeft: 10, color: '#002480', fontWeight: 600}}> {getCategoryName(sentence.category)} </span>
               </Col>
               <Col>
                 {
                   (sentence.bookmarkId > 0) ? (
                     <Row type='flex' justify='center' align='middle' style={{width: 24, height: 24, background: '#002581', borderRadius: 15}} onClick={() => onDeleteBookmark(sentence)}>
                       <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
                     </Row>
                   ) : (
                     <Row type='flex' justify='center' align='middle' style={{width: 24, height: 24, background: '#B8B8B8', borderRadius: 15}} onClick={() => onCreateBookmark(sentence)}>
                       <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
                     </Row>
                   )
                 }
               </Col>
             </Row>
           </Row>

           <Row type='flex' justify='center' style={{marginTop: 0}}>

             <Row type='flex' justify='start' align='middle' style={{width: '100%', margin: '5px 30px', padding: 20, backgroundColor: '#FFFFFF', borderRadius: 10}} >

               <Col span={24} >
                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    영어
                  </Row>
                  <Row type='flex' justify='start'>
                    {sentence.englishSentence}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='space-between' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    <Col>
                      한국어
                    </Col>
                    <Col>
                        <Row type='flex' justify='center' align='middle'
                              style={{width: 20, height: 20, background: '#00247F', borderRadius: 15}}
                              onClick={() => speech(sentence.koreanSentence)}>
                          <Icon type="sound" theme="filled" style={{fontSize: 12, color: '#ffffff'}}/>
                        </Row>
                    </Col>
                  </Row>
                  <Row type='flex' justify='start'>
                    {sentence.description}
                  </Row>
                  <Row type='flex' justify='start'>
                    {sentence.koreanSentence}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    설명
                  </Row>
                  <Row type='flex' justify='start'>

                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    키워드
                  </Row>
                  <Row type='flex' justify='start'>
                    {sentence.keyword}
                  </Row>
               </Col>
             </Row>

             <Row style={{width: '100%'}}>
               <Row type='flex' justify='center' align='middle'
                    style={{margin: '20px 30px 40px 30px', paddingLeft: 30, paddingRight: 30, height: 40, border: 'solid 0.5px #002581'}}
                    onClick={() => onVisibleSentenceConfirm(false)}>
                   <span style={{color: '#002581', fontWeight: 'bold'}}> 목록 </span>
               </Row>
             </Row>
           </Row>
         </Row>


    </Modal>
  );

};
