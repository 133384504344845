import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL } from 'common';


export const MobileSpotDialog = (props) => {

  const { userUid, spot, visibleSpotConfirm, onVisibleSpotConfirm, onCreateBookmark, onDeleteBookmark } = props;

  const [title, setTitle] = useState<string>('');
  const [backgroundColor, setBackgroundColor] = useState<string>('#2A78FF');
  const [categoryName, setCategoryName] = useState<string>('체험');


  useEffect(() => {

    setCategoryInfo(spot.category);

  }, [spot]);



  const setCategoryInfo = (category) => {

    switch(category) {
      case 'type02' : setTitle('activity'); setCategoryName('Activity'); setBackgroundColor('#2A78FF'); break;
      case 'type03' : setTitle('sightseeing'); setCategoryName('Sightseeing'); setBackgroundColor('#0DAB47'); break;
      case 'type04' : setTitle('shopping'); setCategoryName('Shopping'); setBackgroundColor('#AE08C1'); break;
      case 'type05' : setTitle('food'); setCategoryName('Food'); setBackgroundColor('#FFCC01'); break;
      case 'type06' : setTitle('accomodation'); setCategoryName('Accomodation'); setBackgroundColor('#0DBFC5'); break;
      case 'type07' : setTitle('emergency'); setCategoryName('Emergency'); setBackgroundColor('#FC3B69'); break;
      case 'type08' : setTitle('transit'); setCategoryName('Transit'); setBackgroundColor('#6CAB0D'); break;
    }

  }

  return (
    <Modal
         className='festival-modal'
         visible={visibleSpotConfirm}
         closable={false}
         title=""
         width='65vw'
         style={{ top: 0 }}
         centered
         footer={null}
         >
      <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 30,  paddingLeft: 10, paddingRight: 10, backgroundColor: backgroundColor}}>
        <Col span={18}>
          <span style={{color: '#ffffff', fontWeight: 600}}>{title}</span>
        </Col>
        <Col span={2}>
        {
          (spot.bookmarkId > 0) ? (
            <Row type='flex' justify='center' align='middle' style={{width: 20, height: 20, background: '#002581', borderRadius: 15}} onClick={() => onDeleteBookmark(spot)}>
              <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
            </Row>
          ) : (
            <Row type='flex' justify='center' align='middle' style={{width: 20, height: 20, background: '#B8B8B8', borderRadius: 15}} onClick={() => onCreateBookmark(spot)}>
              <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
            </Row>
          )
        }
        </Col>
        <Col>
          <img src='images/anyeventmime/background/button_minifestival_close.png'  style={{height:20}} onClick={() => onVisibleSpotConfirm(false)}/>
        </Col>
      </Row>
      <Row type='flex' justify='center' style={{width: '100%'}}>
        <Col span={22} style={{marginBottom: 10}}>
          <Row type='flex' justify='start' style={{width: '100%', marginTop: 5, padding: '10px' }}>
            <span style={{fontSize: 12, color: '#000000'}}>유형 : {categoryName}</span>
          </Row>
          <hr/>
          <Row type='flex' justify='start' style={{width: '100%', padding: '10px'}}>
            <span style={{fontSize: 12, color: '#000000'}}>이름 : {spot.name}</span>
          </Row>
          <hr/>
          <Row type='flex' justify='start' style={{width: '100%', padding: '10px'}}>
            <span style={{fontSize: 12, color: '#000000'}}>주소 : {spot.address}</span>
          </Row>
          <hr/>
          <Row type='flex' justify='start' style={{width: '100%', padding: '10px'}}>
            <span style={{fontSize: 12, color: '#000000'}}>전화번호 : {spot.contact}</span>
          </Row>
          <hr/>
          {
            (spot.linkUrl != undefined && spot.linkUrl != '') ? (
              <Row type='flex' justify='center' align='middle' style={{width: '100%', padding: '10px'}} >
                <Row type='flex' justify='center' align='middle'
                      style={{width: 100, height: 30, fontSize: 12, fontWeight: 600, color: '#002581', borderRadius: 15, border: '1px solid #002581'}}
                      onClick={() => {window.open(spot.linkUrl)}}> 자세히보기 </Row>
              </Row>
            ) : (<Row />)
          }

        </Col>
      </Row>



    </Modal>


  );

};
