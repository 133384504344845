import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_WORD_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const ManagerWordEditorDialog = (props) => {

  const { wordId, visibleWordConfirm, onWordConfirm } = props;

  const history = useHistory();

  const [word, setWord] = useState<string>('');
  const [define, setDefine] = useState<string>('');
  const [speech, setSpeech] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [englishExample, setEnglishExample] = useState<string>('');
  const [koreanExample, setKoreanExample] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [status, setStatus] = useState<number>(0);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState<any[]>([]);
  const [indeterminateCategory, setIndeterminateCategory] = useState<boolean>(false);
  const [checkAllCategory, setCheckAllCategory] = useState<boolean>(false);

  const [keywordList, setKeywordList] = useState<any[]>([]);
  const [tempKeyword, setTempKeyword] = useState<string>('');

  const [activeButtonStyle, setActiveButtonStyle] = useState<any>();
  const [inActiveButtonStyle, setInactiveButtonStyle] = useState<any>();

  const selectedStyle = {fontSize: '17px', height: '58px', width: '140px', borderColor: '#7446f9', backgroundColor: '#ffffff', color: '#7445f9'};
  const deselectedStyle ={fontSize: '17px', height: '58px', width: '140px', borderColor: '#dddddd', backgroundColor: '#f7f6fb', color: '#dddddd'};


  useEffect(() => {

    if(visibleWordConfirm) {
      setWord('');
      setDefine('');
      setSpeech('');
      setCategory('');
      setKeyword('');
      setEnglishExample('');
      setKoreanExample('');
      setImageUrl('');
      setStatus(0);

      setActiveButtonStyle(deselectedStyle);
      setInactiveButtonStyle(selectedStyle);

      getCategoryData();

      if(wordId > 0) {
        getWordData(wordId);
      }
    }

  }, [visibleWordConfirm]);


  const getCategoryData = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/categorys.do?';
    searchUrl += 'page=0&size=100';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null)
                      setCategoryList(data);

                  })
     .catch(err => console.log(err));

  };


  const getWordData = (wordId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/word.do?wordId=' + wordId;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null) {
                      setWord(data.word);
                      setDefine(data.define);
                      setSpeech(data.speech);

                      setCategory(data.category);
                      if(data.category != null)
                        setSelectedCategoryList(data.category.split(','));

                      setKeyword(data.keyword);
                      if(data.keyword != null)
                        setKeywordList(data.keyword.split(','));

                      setEnglishExample(data.englishExample);
                      setKoreanExample(data.koreanExample);

                      setImageUrl(data.imageUrl);
                      if(imageUrl != null){
                        $('#wordImage').show();
                        $('#imageResult').hide();
                      }
                      else {
                        $('#wordImage').hide();
                        $('#imageResult').show();
                      }

                      setStatus(data.status);
                      if(data.status == 1) {
                        setActiveButtonStyle(selectedStyle);
                        setInactiveButtonStyle(deselectedStyle);
                      }
                      else {
                        setActiveButtonStyle(deselectedStyle);
                        setInactiveButtonStyle(selectedStyle);
                      }
                    }

                  })
     .catch(err => console.log(err));

  };


  const createWord= () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/word.do';

    var categoryData = '';
    selectedCategoryList.forEach(category => { categoryData += category + ',' });
    if(categoryData.length > 0)
      categoryData = categoryData.substring(0, categoryData.length - 1);

    var keywordData = '';
    keywordList.forEach(keyword => { keywordData += keyword + ',' });
    if(keywordData.length > 0)
      keywordData = keywordData.substring(0, keywordData.length - 1);

    var params = {
                  word: word,
                  define: define,
                  speech: speech,
                  category: categoryData,
                  keyword: keywordData,
                  englishExample: englishExample,
                  koreanExample: koreanExample,
                  imageUrl: imageUrl,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.wordId == null){
                        InfoModal("info", "알림", "단어 등록이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "단어 등록이 성공하였습니다.");
                        onWordConfirm(true);
                      }

                    })
      .catch(err => console.log(err));

  }

  const updateWord = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/wordUpdate.do';

    var categoryData = '';
    selectedCategoryList.forEach(category => { categoryData += category + ',' });
    if(categoryData.length > 0)
      categoryData = categoryData.substring(0, categoryData.length - 1);

      var keywordData = '';
      keywordList.forEach(keyword => { keywordData += keyword + ',' });
      if(keywordData.length > 0)
        keywordData = keywordData.substring(0, keywordData.length - 1);

    var params = {
                  wordId: wordId,
                  word: word,
                  define: define,
                  speech: speech,
                  category: categoryData,
                  keyword: keywordData,
                  englishExample: englishExample,
                  koreanExample: koreanExample,
                  imageUrl: imageUrl,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.wordId == null){
                        InfoModal("info", "알림", "문장 수정이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "문장 수정이 성공하였습니다.");
                        onWordConfirm(true);
                      }

                    })
      .catch(err => console.log(err));

  }

  const uploadWordImage  = () => {
    var uploadUrl = SOPOONG_URL + '/fileupload/insertCoverImage.do';
    var formData = new FormData();
    formData.append("imageFile", $("#uploadWordImageFile")[0].files[0]);
    fetch(uploadUrl, {
        method: "POST",
        headers: {},
        body: formData
    })
    .then(function (response) { return response.json(); })
    .then(function (data) {
      console.log(data);
      setImageUrl(data.url);
      $("#wordImage").show();
      $("#imageResult").hide();
    })
    .catch(function (err) { return console.log(err); });
  }


  const onCheckAllChangeCategory = (e) => {

    if(e.target.checked)
      setSelectedCategoryList(categoryList.map((item) => item.category));
    else
      setSelectedCategoryList([]);

    setIndeterminateCategory(false);
    setCheckAllCategory(e.target.checked);

  };

  const checkCategoryList = (checkedList) => {

    if(checkedList.length == 0) {
      setIndeterminateCategory(false);
      setCheckAllCategory(false);
    }
    else if(checkedList.length < 12) {
      setIndeterminateCategory(true);
      setCheckAllCategory(false);
    }
    else {
      setIndeterminateCategory(false);
      setCheckAllCategory(true);
    }
    setSelectedCategoryList(checkedList);
  }

  const removeKeyword = (word) => {
    let words = keywordList.filter((keyword) => keyword != word);
    setKeywordList(words);
  }

  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const { Option } = Select;
  const { Search } = Input;


  var editorConfiguration = {
      ckfinder: {
          // Upload the images to the server using the CKFinder QuickUpload command.
          uploadUrl: SOPOONG_URL + '/fileupload/insertAnyEventMimeImage.do'
      },
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'alignment', 'fontBackgroundColor', 'fontColor', 'fontSize', '|', 'imageUpload'],
      image: {
          // Configure the available styles.
          styles: [
              'alignLeft', 'alignCenter', 'alignRight'
          ],
          // Configure the available image resize options.
          resizeOptions: [
              {
                  name: 'imageResize:original',
                  label: 'Original',
                  value: null
              },
              {
                  name: 'imageResize:50',
                  label: '50%',
                  value: '50'
              },
              {
                  name: 'imageResize:75',
                  label: '75%',
                  value: '75'
              }
          ],
          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
              'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
              '|',
              'imageResize',
              '|',
              'imageTextAlternative'
          ]
      }
  };


  return (
    <Modal
        className='popup-content'
         visible={visibleWordConfirm}
         closable={false}
         title=""
         width={'80%'}
         style={{ top: 20 }}
         centered
         footer={null}
         >
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Col style={{width: '100%', backgroundColor: '#ffffff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '0px 80px'}}>
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='start' align='top' style={{marginTop: '65px'}}>
              <Col span={4}> 대분류 </Col>
              <Col span={3}>
                <Checkbox checked={checkAllCategory}
                          indeterminate={indeterminateCategory}
                          onChange={onCheckAllChangeCategory}>전체</Checkbox>
              </Col>
              <Col span={17}>
                <Checkbox.Group style={{width: '100%'}}
                                value={categoryList}
                                onChange={values => checkCategoryList(values)} >
                  <Row type='flex' justify='start'>

                    {categoryList ? (
                      categoryList.map(category => (
                        <Col span={4} key={category.categoryId} style={{marginBottom: 20}}>
                          <Checkbox value={category.category}>{category.name}</Checkbox>
                        </Col>
                      ))
                    ) : (
                      <Empty />
                    )}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>키워드 </Col>
              <Col span={20} style={{backgroundColor: '#f7f6fb', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '24px'}}>
                <Row>
                  <Input style={{height: '50px', width: '420px'}}
                         value={tempKeyword}
                         onChange={e => setTempKeyword(e.target.value)}
                         onKeyDown={e => {
                                          if(e.key == 'Enter'){
                                            setKeywordList([...keywordList, tempKeyword]);
                                            setTempKeyword('');
                                          }
                                        }}/>
                  <span style={{marginLeft:'10px'}}>  *Enter로 태그를 추가할 수 있습니다. </span>
                </Row>
                <Row style={{marginTop: '20px'}} />
                <Row>
                  {keywordList ? (
                    keywordList.map((word, index) => {
                      return (
                        <Col key={index} span={2} style={{backgroundColor: '#f3efff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '5px 10px', marginRight: '10px'}}>
                          <span>{word}</span>
                          <span style={{color: '#774af9', marginLeft: '10px', cursor: 'pointer'}}
                                onClick={() => removeKeyword(word)}> X </span>
                        </Col>);
                      })) :

                    <Empty />
                  }
                </Row>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> 활성 여부 </Col>
              <Col span={20}>
                <Button style={activeButtonStyle}
                        onClick={e => {setStatus(1); setActiveButtonStyle(selectedStyle); setInactiveButtonStyle(deselectedStyle);}} > 활성화 </Button>
                <Button style={inActiveButtonStyle}
                        onClick={e => {setStatus(0); setActiveButtonStyle(deselectedStyle); setInactiveButtonStyle(selectedStyle);}} > 비활성화 </Button>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>단어</Col>
              <Col span={7}>
                <Input placeholder='단어'
                       style={{height: '58px'}}
                       value={word}
                       onChange={e => setWord(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>품사</Col>
              <Col span={7}>
                <Input placeholder='품사'
                       style={{height: '58px'}}
                       value={speech}
                       onChange={e => setSpeech(e.target.value)} />
              </Col>
              <Col span={2} offset={2}>정의</Col>
              <Col span={7}>
                <Input placeholder='정의'
                       style={{height: '58px'}}
                       value={define}
                       onChange={e => setDefine(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}><div>예문1</div><div>(영어)</div></Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={englishExample}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEnglishExample(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}><div>예문2</div><div>(한국어)</div></Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={koreanExample}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setKoreanExample(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px', marginBottom: '50px'}}>
              <Col span={4}>이미지</Col>
              <Col span={20}>
                <Row>
                  <Button style={{fontSize: '18px', height: '58px', width: '380px', backgroundColor: '#774af9', color: '#ffffff'}}
                          onClick={() => $('#uploadWordImageFile').click()}> Click to Upload </Button>
                  <span id='imageResult' style={{marginLeft: '10px'}}> *등록된 파일 없음 </span>
                  <Input type='file'
                         id='uploadWordImageFile'
                         style={{display: 'none'}}
                         onChange={uploadWordImage} />
                  <img id='wordImage' src={imageUrl} style={{display: 'none', marginLeft: '10px', height: '40px', width: '40px'}} />
                </Row>
                <Row>
                  <Icon type='exclamation-circle' style={{color: '#774af9', fontSize: '14px'}} />
                  <span style={{fontSize: '14px', color: '#b1b1b1'}}> .jpg, .jpeg, .bmp, .gif, .png </span>
                </Row>
              </Col>
            </Row>
            <Row type='flex' justify='start'>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row type='flex' justify='end' style={{margin: '40px 0 88px 0'}}>
              <Col>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#928ca2', color: '#ffffff'}}
                        onClick={() => onWordConfirm(false)}> Cancel </Button>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                        onClick={() => { wordId == 0 ? createWord() : updateWord() }}> Save </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

    </Modal>
  );

};
