import React from 'react';
import { render } from 'react-dom';
import 'antd/dist/antd.css';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { GoogleOAuthProvider } from '@react-oauth/google';
import {Wrapper} from "@googlemaps/react-wrapper";


import { Routes } from 'common';
import 'anyeventmime.css';


const App = () => (
  <Routes />
);

render(<GoogleOAuthProvider clientId="153342400811-352is8pbubjmqiqr8dqc0rh184hv96i5.apps.googleusercontent.com">
        <Wrapper apiKey="AIzaSyCqjZ1RvnaBMfHH9-bHMgm0PP8BrmQvot0" language='en' >
          <App />
        </Wrapper>
      </GoogleOAuthProvider>, document.getElementById('root'));
