import React, {CSSProperties} from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Divider } from 'antd';
import { TERMS_OF_SERVICE_PATH ,
        PRIVACY_PATH
      } from 'common';

/**
 * @description NomalLayout에서 사용되는 메인 푸터 컴포넌트
 */
export const MainFooter = () => {

  return (
    <>
    </>
  );
};
