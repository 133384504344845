import React, { Component, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';


export const MobileCanvasDialog = (props) => {

  const { visibleCanvasConfirm, onVisibleCanvasConfirm } = props;

  const canvasRef = useRef(null);

  const [title, setTitle] = useState<string>('');
  const [festivalContext, setFestivalContext] = useState<string>('');

  const [canvasContainer, setCanvasContainer] = useState<any>();

  const imgFestival = document.getElementById('img-festival');
  const imgMypage = document.getElementById('img-mypage');
  const imgSentence = document.getElementById('img-sentence');
  const imgSpot = document.getElementById('img-spot');
  const imgWord = document.getElementById('img-word');

  //const [imageList, setImageList] = useState<any[]>([]);

  var imageList: any[] = [];

  let animateId;
  let animateCount = 0;

  var sides = 1;
  var radius = 1000;
  var rotate = 0;

  var centerx = 200;
  var centery = 500;
  let ctx;

  const PI2 = Math.PI * 2;

  const COLORS = [
    "#4b45ab",
    "#554fb8",
    "#605ac7",
    "#2a91a8",
    "#2e9ab2",
    "#32a5bf",
    "#81b144",
    "#85b944",
    "#8fc549",
    "#e0af27",
    "#eeba2a",
    "#fec72e",
    "#bf342d",
    "#ca3931",
    "#d7423a",
  ];



  useEffect(() => {

    console.log('MobileCanvasDialog start..')



    if(visibleCanvasConfirm == true) {

      const canvas:any = canvasRef.current;

      var images: any[] = [];

      imageList.push(imgFestival);
      imageList.push(imgMypage);
      imageList.push(imgSentence);
      imageList.push(imgSpot);
      //imageList.push(imgWord);

      //setImageList(images);

      if(canvas != null) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        centerx = canvas.width/2;
        centery = canvas.height/2 + 900 ;


        setCanvasContainer(canvas);

        ctx = canvas.getContext('2d');

        canvas.addEventListener('click', (e) => {    animate();    });

        animate();
      }

    }


    return () => {
      if(animateId)
        window.cancelAnimationFrame(animateId);
    };



  }, [visibleCanvasConfirm]);




  const animate = () => {

    if(!visibleCanvasConfirm)
      return;

    animateCount ++;

    //var ctx = canvasContainer.getContext('2d');
    var moveX = 0.1;


    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)

    ctx.save();

    const angle = PI2 / imageList.length;
    const angle2 = PI2 / 4;

    ctx.translate(centerx, centery);

    //rotate += moveX * 0.008;
    //rotate += moveX * 0.008;

    rotate += moveX * Math.PI/180
    ctx.rotate(rotate);

    for (let i = 0; i < imageList.length; i++) {
      const x = radius * Math.cos(angle * i);
      const y = radius * Math.sin(angle * i);

      //i == 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);

      ctx.save();
      //ctx.translate(imageList[i].width/2, imageList[i].height/2);
      //ctx.translate(0, 300);
      ctx.translate(x, y);
      ctx.rotate((((360 / imageList.length) * i + 90) * Math.PI) / 180);
      //ctx.rotate((((360 / imageList.length) * i + 45) * Math.PI) / 180);
      //ctx.rotate((((360 / imageList.length) * i + 45) * Math.PI) / 180);

      //ctx.drawImage(imageList[i], -imageList[i].width/2, -imageList[i].height/2, imageList[i].width * 1.5, imageList[i].height * 1.5);
      ctx.drawImage(imageList[i], -250, -250, 500, 500);

/*
      i == 0 ? ctx.moveTo(x, y) : ctx.lineTo(x, y);

      ctx.save();
      ctx.fillStyle = COLORS[i];
      ctx.translate(x, y);
      ctx.rotate((((360 / sides) * i + 45) * Math.PI) / 180);
      ctx.beginPath();
      for (let j = 0; j < 4; j++) {
        const x2 = 160 * Math.cos(angle2 * j);
        const y2 = 160 * Math.sin(angle2 * j);
        j == 0 ? ctx.moveTo(x2, y2) : ctx.lineTo(x2, y2);
      }
      ctx.fill();
      ctx.closePath();
*/
      ctx.restore();
    }
    ctx.restore();

    //console.log('rotate : ' + rotate);

    if(animateCount % (900) == 0)
      return;

    animateId = window.requestAnimationFrame(animate);
    //setTimeout(() => window.requestAnimationFrame(animate), 100);
  }



  return (
    <Modal
         className='festival-modal'
         visible={visibleCanvasConfirm}
         closable={false}
         title=""
         width='100vw'
         style={{ top: 0 }}
         footer={null}
         >

         <Row type='flex' justify='end' align='middle' style={{width: '100%', height: 30,  paddingRight: 10, backgroundColor: '#FFCC01'}}>
           <img src='images/anyeventmime/background/button_minifestival_close.png'  style={{height:20}} onClick={() => onVisibleCanvasConfirm(false)}/>
         </Row>
        <Row type='flex' justify='end' align='middle' style={{width: '100%', height: '90vh',  paddingRight: 10}}>

          <canvas ref={canvasRef} />

        </Row>
        <img src='images/anyeventmime/background/icon_menu_festival_off.png' id='img-festival' style={{width: '20%'}} onClick={() => animate()} />
        <img src='images/anyeventmime/background/icon_menu_mypage_off.png' id='img-mypage' style={{width: '20%'}} onClick={() => animate()} />
        <img src='images/anyeventmime/background/icon_menu_sentence_off.png' id='img-sentence' style={{width: '20%'}} onClick={() => animate()}/>
        <img src='images/anyeventmime/background/icon_menu_spot_off.png' id='img-spot' style={{width: '20%'}} onClick={() => animate()} />
        <img src='images/anyeventmime/background/icon_menu_word_off.png' id='img-word' style={{width: '20%'}}/>
    </Modal>


  );

};
