import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';
import { MobileMenuDrawer, MobileFestivalDialog } from '.';

declare global {
  interface Window {
    daum: any;
  }
}

export const MobileHomeView = (props) => {

  const history = useHistory();

  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [eventList, setEventList] = useState<any[]>([]);
  const [spotList, setSpotList] = useState<any[]>([]);


  const [map, setMap] = useState<any>({});
  const [placeService, setPlaceService] = useState<any>({});
  const [infowindow, setInfowindow] = useState<any>({});
  const [markers, setMarkers] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  const [visibleMenuConfirm, setVisibleMenuConfirm] = useState<boolean>(false);
  const [visibleMenuFestivalConfirm, setVisibleMenuFestivalConfirm] = useState<boolean>(false);
  const [visibleMenuSpotConfirm, setVisibleMenuSpotConfirm] = useState<boolean>(false);

  const [visibleFestivalConfirm, setVisibleFestivalConfirm] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');

  const [categoryPosition, setCategoryPosition] = useState<number>(0);

  const [prevButtonDisplay, setPrevButtonDisplay] = useState<string>('none');
  const [nextButtonDisplay, setNextButtonDisplay] = useState<string>('flext');



  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();
    //$('#LoginPanel').css('background-image', 'url("images/anyeventmime/background/anyeventmime_login_background.png")')

    //$('#MainLayout').css('max-width', '430px');
    //$('#MainLayout').css('max-height', '960px');

    getDaumMap();

  }, []);


  const getEventListData = (daumMap) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/events.do?' ;
    searchUrl += 'page=0&size=1000';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setEventList(Object.assign(data));

                    if(data != null) {
                      data.forEach((event) => createMarker(daumMap, event.latitude, event.longitude, event.radius, event.description));
                    }

                  })
     .catch(err => console.log(err));

  };

  const getSpotListData = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/spots.do?' ;
    searchUrl += 'page=0&size=1000';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setSpotList(Object.assign(data));
                  })
     .catch(err => console.log(err));

  };


  const getDaumMap = () => {
    // 지도 API
    var mapContainer = document.getElementById('map'); // 지도를 표시할 div
    var mapOption = {
      center: new window.daum.maps.LatLng(37.867786, 127.720815),
      level: 4 // 지도의 확대 레벨
    };
    // 지도를 생성합니다

    //console.log('mapContainer : ' + mapContainer);

    if(mapContainer == null)
      return;

    var daumMap = new window.daum.maps.Map(mapContainer, mapOption);
    daumMap.setMapTypeId(window.daum.maps.MapTypeId.ROADMAP);
    // 마커가 표시될 위치입니다
    var markerPosition = new window.daum.maps.LatLng(37.86710996062191, 127.71824150588344);
    // 마커를 생성합니다
    var marker = new window.daum.maps.Marker();
    // 클릭한 위치에 대한 주소를 표시할 인포윈도우입니다
    var infowindow = new window.daum.maps.InfoWindow({ zindex: 1 });
    setMap(daumMap);


    getEventListData(daumMap);
  }


  const createMarker = (daumMap, latitude, longitude, radius, description) => {

    var imageSrc = 'images/anyeventmime/background/map_event_marker.png'; // 마커이미지의 주소입니다
    var imageSize = new window.daum.maps.Size(70, 88); // 마커이미지의 크기입니다
    var imageOption = {offset: new window.daum.maps.Point(35, 88)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

    var markerImage = new window.daum.maps.MarkerImage(imageSrc, imageSize, imageOption);
    var markerPosition = new window.daum.maps.LatLng(latitude, longitude); // 마커가 표시될 위치입니다

    // 마커를 생성합니다
    var marker = new window.daum.maps.Marker({
      position: markerPosition,
      image: markerImage // 마커이미지 설정
    });


    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(daumMap);


    // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우를 생성합니다
    var iwContent = '<div style="background: #ffffff; border: 1px solid rgb(118, 129, 168)"><div style="padding:5px; background-color: #FFCC01; color: #002581; font-size: 12px; font-weight: bold;">공연일정</div>'; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    iwContent +='<div style="padding:5px;">' + description + '</div>';

    iwContent +='<div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="padding:5px; background:#002581; border-top: 1px solid rgb(118, 129, 168)">';
    iwContent +='<div class="ant-row-flex ant-row-flex-center ant-row-flex-middle" style="width: 100px; height: 30px; margin-left: 10px; border-radius: 15px 15px 0px; color: #002581; font-size: 12px; font-weight: bold; background: #FFFFFF;">';
    iwContent += '자세히보기</div></div>';


    var  iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다

    // 인포윈도우를 생성합니다
    var infowindow = new window.daum.maps.InfoWindow({
        content : iwContent,
        removable : iwRemoveable
    });

    // 마커에 클릭이벤트를 등록합니다
    window.daum.maps.event.addListener(marker, 'click', function() {
          // 마커 위에 인포윈도우를 표시합니다
          infowindow.open(daumMap, marker);
    });


    var circle = new window.daum.maps.Circle({
        center : new window.daum.maps.LatLng(latitude, longitude),  // 원의 중심좌표 입니다
        radius: radius, // 미터 단위의 원의 반지름입니다
        strokeWeight: 0, // 선의 두께입니다
        strokeColor: '#75B8FA', // 선의 색깔입니다
        strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle: 'dashed', // 선의 스타일 입니다
        fillColor: '#002581', // 채우기 색깔입니다
        fillOpacity: 0.4  // 채우기 불투명도 입니다
    });

    // 지도에 원을 표시합니다
    circle.setMap(daumMap);

  }


  const setMapType = (maptype) => {
    var roadmapControl = document.getElementById('btnRoadmap');
    var skyviewControl = document.getElementById('btnSkyview');
    if (maptype === 'roadmap') {
      map.setMapTypeId(window.daum.maps.MapTypeId.ROADMAP);
      if (roadmapControl != null)
          roadmapControl.className = 'selected_btn';
      if (skyviewControl != null)
          skyviewControl.className = 'btn';
    }
    else {
      map.setMapTypeId(window.daum.maps.MapTypeId.HYBRID);
      if (skyviewControl != null)
          skyviewControl.className = 'selected_btn';
      if (roadmapControl != null)
          roadmapControl.className = 'btn';
    }
  }

  // 지도 확대, 축소 컨트롤에서 확대 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomIn = () => {
    map.setLevel(map.getLevel() - 1);
  }

  // 지도 확대, 축소 컨트롤에서 축소 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomOut = () => {
    map.setLevel(map.getLevel() + 1);
  }



  const getMap = () => {

    return(
      <Row>
        <div id='map' className='daum_map' style={{position: 'relative', overflow: 'hidden', width: '100vw', height: '100vh', margin: '0', padding: '0', color: '#000000'}} />
        <div style={{position: 'absolute', top: 18, left: 26, zIndex: 1}}>
          <img src='images/anyeventmime/background/icon_mobile_menu.png'  style={{height:44}} onClick={() => setVisibleMenuConfirm(true)}/>
        </div>
        <div style={{position: 'absolute', top: 18, right: 18, zIndex: 1}}>
          <img src='images/anyeventmime/background/button_minifestival.png'  style={{height:32}} onClick={() => setVisibleFestivalConfirm(true)}/>
        </div>


      </Row>
    );
  }

  const calculatePosition = (delta) => {

    var position = categoryPosition + delta;

    setCategoryPosition(position);

    if(position < 0)
      setPrevButtonDisplay('flex');
    else
      setPrevButtonDisplay('none');


    if(position > -570)
      setNextButtonDisplay('flex');
    else
      setNextButtonDisplay('none');
  }

  const { SubMenu } = Menu;

  return (
    <>

      <div style={{backgroundColor:'#FFCC01'}}>
        <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%'}}>

          <Col span={24}>
            {getMap()}
          </Col>

          <Col span={24} style={{height: 72, marginTop: -72, backgroundColor: 'rgba(0, 37, 129, 0.2)', borderRadius: '25px 25px 0px 0px', zIndex: 999}}>
            <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%', paddingLeft: 10, paddingRight: 10}} >
              <Col span={2}>
                <Row type='flex' justify='start' align='middle' style={{width: '100%'}}>
                  <Icon type="left" style={{display: prevButtonDisplay, fontSize: 20}}  onClick={() => calculatePosition(30)}/>
                </Row>
              </Col>
              <Col span={20} style={{overflowX: 'hidden' }}>
                <Row style={{display:'flex', marginLeft: categoryPosition}}>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#FFCC01'}} onClick={() => {}}> food </span>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#FC3B69'}} onClick={() => {}}> emergency </span>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#AE08C1'}} onClick={() => {}}> shopping </span>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#2A78FF'}} onClick={() => {}}> activity </span>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#0DBFC5'}} onClick={() => {}}> accomodatioin </span>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#0DAB47'}} onClick={() => {}}> sightseeing </span>
                  <span style={{height: 28, marginRight: 5,  padding: '2px 10px 2px 10px', borderRadius: 14, color: '#ffffff', background: '#6CAB0D'}} onClick={() => {}}> transit </span>
                </Row>
              </Col>
              <Col span={2}>
                <Row type='flex' justify='end' align='middle' style={{width: '100%'}}>
                  <Icon type="right" style={{display: nextButtonDisplay, fontSize: 20}}  onClick={() => calculatePosition(-30)}/>
                </Row>
              </Col>
            </Row>

          </Col>

        </Row>

      </div>

      <MobileMenuDrawer
        visibleMenuConfirm = {visibleMenuConfirm}
        onVisibleMenuConfirm={(value)=>setVisibleMenuConfirm(false)}
      />

      <MobileFestivalDialog
        visibleFestivalConfirm = {visibleFestivalConfirm}
        onVisibleFestivalConfirm={(value)=>setVisibleFestivalConfirm(false)}
      />

    </>
  );

};
