import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';
import { MobileMenuDrawer, MobileFestivalDialog } from '.';

declare global {
  interface Window {
    daum: any;
  }
}

export const MobileScheduleView = (props) => {

  const history = useHistory();

  const [visibleMenuConfirm, setVisibleMenuConfirm] = useState<boolean>(false);
  const [visibleFestivalConfirm, setVisibleFestivalConfirm] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');
  const [festivalContext, setFestivalContext] = useState<string>('');


  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();


    var context = '<div class="container"><figure class="image image_resized" style="width:60%;"><img src="images/anyeventmime/background/mobile_festival_schedule.png"></figure><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>City of Water; Ah! Surajang</strong></span></p><p>May 26th (Sunday) 13:00 ~ 16:00</p><p>05.26 (SUN) PM 1:00 ~ 4:00 &nbsp;</p><p>: Chuncheon Jungang-ro</p><p>&nbsp;</p>';
    context += '<hr class="rounded"><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>Theater Performace 1 - Kaleidoscope ’Kaleidoscope’</strong></span></p><p>05.26 (SUN) PM 5:00 ~ 6:00, 05.27 (MON) PM 7:30 ~ 8:30 &nbsp;</p><p>: Festival theater MIME</p><p>&nbsp;</p>';
    context += '<hr class="rounded"><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>Theater Performance 2 - Marine Boy - ’The Voyage’</strong></span></p><p>05.29 (WED), 05.30 (THU) PM 7:30 ~ 8:20</p><p>: Festival theater MIME</p><p>&nbsp;</p>';
    context += '<hr class="rounded"><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>City of Spring</strong></span></p><p>05.28 (TUE) PM 7:00 ~ 10:00 &nbsp;</p><p>: Commonz Field Chuncheon</p><p>&nbsp;</p>';
    context += '<hr class="rounded"><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>Festival Theater MIME Outdoor Performance</strong></span></p><p>05.27 (MON), 05.29 (WED) 8:30 ~ 10:00 PM</p><p>Festival theater MIME</p><p>&nbsp;</p>';
    context += '<hr class="rounded"><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>City of Fire; Dokkaebi Nanjang</strong></span></p><p>05.31 (FRI) 7:00 PM~ 06.01 (SAT) 2:00 AM</p><p>06.01 (SAT) 2:00 PM ~ 06.02 (SUN) 2:00 AM</p><p>: LEGOLAND Korea Resort parking lot</p><p>&nbsp;</p>';
    context += '<hr class="rounded"><p>&nbsp;</p><p><span style="color:hsl(210, 75%, 60%);"><strong>Daytime Dokkaebi Nanjang</strong></span></p><p>06.02 (SUN) 2:00 ~ 6:00 PM</p><p>: LEGOLAND Korea Resort parking lot</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><div>';


    setFestivalContext(context);

  }, []);


  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }


  return (
    <>
      <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 60,  paddingLeft: 10, paddingRight: 10, backgroundColor: '#FFCC01'}}>
        <img src='images/anyeventmime/background/icon_mobile_menu.png'  style={{height:44}} onClick={() => setVisibleMenuConfirm(true)}/>
        <img src='images/anyeventmime/background/button_minifestival_content.png'  style={{height:32}} onClick={() => setVisibleFestivalConfirm(true)}/>
      </Row>

      <Row style={{fontSize: 12, padding: '20px 20px'}}>
        <span><Icon type="home" /></span> <span style={{marginLeft: 10}}> Mime Festival </span> <span> &gt; </span> <span style={{color: '#002581'}}> Schedule</span>
      </Row>

      <Row type='flex' justify='center' style={{width: '100%', marginTop: 0, padding: '10px 40px 40px 40px', backgroundColor: '#ffffff'}}>
        <div style={{display: 'inline-block'}}>
          {getHtml(festivalContext)}
        </div>
      </Row>

      <Row type='flex' justify='space-between' align='middle' style={{position: 'fixed', width: '100%', height: 40,  bottom: 0, backgroundColor: '#FFCC01'}}>
      </Row>

      <MobileMenuDrawer
        visibleMenuConfirm = {visibleMenuConfirm}
        onVisibleMenuConfirm={(value)=>setVisibleMenuConfirm(false)}
      />

      <MobileFestivalDialog
        visibleFestivalConfirm = {visibleFestivalConfirm}
        onVisibleFestivalConfirm={(value)=>setVisibleFestivalConfirm(false)}
      />



    </>
  );

};
