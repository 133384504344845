import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_WORD_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const MobileWordDetailDialog = (props) => {

  const { userUid, wordId, visibleWordConfirm, onVisibleWordConfirm } = props;

  const history = useHistory();

  const [word, setWord] = useState<string>('');
  const [define, setDefine] = useState<string>('');
  const [speech, setSpeech] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [englishExample, setEnglishExample] = useState<string>('');
  const [koreanExample, setKoreanExample] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [status, setStatus] = useState<number>(0);


  useEffect(() => {

    if(visibleWordConfirm) {
      setWord('');
      setDefine('');
      setSpeech('');
      setCategory('');
      setKeyword('');
      setEnglishExample('');
      setKoreanExample('');
      setImageUrl('');
      setStatus(0);

      if(wordId > 0) {
        getWordData(wordId);
      }
    }

  }, [visibleWordConfirm]);



  const getWordData = (wordId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/word.do?wordId=' + wordId;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null) {
                      setWord(data.word);
                      setDefine(data.define);
                      setSpeech(data.speech);
                      setCategory(data.category);
                      setKeyword(data.keyword);
                      setEnglishExample(data.englishExample);
                      setKoreanExample(data.koreanExample);
                      setImageUrl(data.imageUrl);
                      setStatus(data.status);

                    }

                  })
     .catch(err => console.log(err));

  };


  const getCategoryName = (category) => {

    var categoryName = ''
    switch(category) {
      case 'type02' : categoryName = 'Activity'; break;
      case 'type03' : categoryName = 'Sightseeing'; break;
      case 'type04' : categoryName = 'Shopping'; break;
      case 'type05' : categoryName = 'Food'; break;
      case 'type06' : categoryName = 'Accomodation'; break;
      case 'type07' : categoryName = 'Emergency'; break;
      case 'type08' : categoryName = 'Transit'; break;

    }

    return categoryName;
  }


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  return (
    <Modal
        className='popup-content'
         visible={visibleWordConfirm}
         closable={false}
         title=""
         width={'100vw'}
         style={{ top: 0, height: '100vh' }}
         footer={null}
         >
         <Row className='word-content' style={{height: '100vh', overflowY: 'scroll', backgroundColor: '#FFCC01'}}>

           <Row type='flex' justify='center' style={{marginTop: 50}}>
             <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 40, margin: '10px 30px', paddingLeft: 20, paddingRight: 20, backgroundColor: '#FFFFFF', borderRadius: 10}}>
               <Col>
                <span > <Icon type="check"  style={{color: '#FDB813', fontSize: 18, fontWeight: 800}}/> </span>
                <span style={{marginLeft: 10, color: '#002480', fontWeight: 600}}> {getCategoryName(category)} </span>
               </Col>
               <Col>
                   <Row type='flex' justify='center' align='middle' style={{width: 24, height: 24, background: '#B8B8B8', borderRadius: 15}}>
                     <Icon type="book" theme="filled" style={{color: '#ffffff'}}/>
                   </Row>
               </Col>
             </Row>
           </Row>

           <Row type='flex' justify='center' style={{marginTop: 0}}>

             <Row type='flex' justify='start' align='middle' style={{width: '100%', margin: '5px 30px', padding: 20, backgroundColor: '#FFFFFF', borderRadius: 10}} >

               <Col span={24} >
                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    단어
                  </Row>
                  <Row type='flex' justify='start'>
                    {word}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    뜻
                  </Row>
                  <Row type='flex' justify='start'>
                    <span>{define} </span>
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    영어예문
                  </Row>
                  <Row type='flex' justify='start'>
                    {englishExample}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    한국어예문
                  </Row>
                  <Row type='flex' justify='start'>
                    {koreanExample}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    키워드
                  </Row>
                  <Row type='flex' justify='start'>
                    {keyword}
                  </Row>
               </Col>
             </Row>

             <Row style={{width: '100%'}}>
               <Row type='flex' justify='center' align='middle'
                    style={{margin: '20px 30px 40px 30px', paddingLeft: 30, paddingRight: 30, height: 40, border: 'solid 0.5px #002581'}}
                    onClick={() => onVisibleWordConfirm(false)}>
                   <span style={{color: '#002581', fontWeight: 'bold'}}> + 목록 </span>
               </Row>
             </Row>
           </Row>
         </Row>

    </Modal>
  );

};
