import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_SENTENCE_EDITOR_PATH,
        storageService
      } from 'common';

import { ManagerSentenceEditorDialog } from '.';

export const ManagerSentenceView = (props) => {

  const history = useHistory();

  const [sentenceList, setSentenceList] = useState<any[]>([]);
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [goCurrentPage, setGoCurrentPage] = useState<number>(1);


  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchCategory, setSearchCategory] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<number>(-1);

  const [selectedSentenceId, setSelectedSentenceId] = useState<number>(0);
  const [visibleSentenceConfirm, setVisibleSentenceConfirm] = useState<boolean>(false);


  useEffect(() => {

    $('#headerTitle').text('문장');

    getSentenceCount(searchCategory, searchKeyword, searchStatus);

  }, []);



  const getSentenceListData = (page, pageSize, category, keyword, status) => {

    console.log('page : ' + page + ' , pageSize : ' + pageSize);

    setCurrentPage(page);
    setGoCurrentPage(page);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentencesByCondition.do?' ;
    searchUrl += 'page=' + (page - 1) + '&size=' + size;
    searchUrl += '&category=' + category;
    searchUrl += '&keyword=' + keyword;
    searchUrl += '&status=' + status;


    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setSentenceList(Object.assign(data));
                  })
     .catch(err => console.log(err));

  };


  const getSentenceCount = (category, keyword, status) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentenceCountByCondition.do?' ;
    searchUrl += 'category=' + category;
    searchUrl += '&keyword=' + keyword;
    searchUrl += '&status=' + status;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setTotalCount(data);

                      if(data > 0)
                        getSentenceListData(1, size, category, keyword, status);

                     })
     .catch(err => console.log(err));

  };

  const updateSentenceList = (status) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentenceUpdateStatusByIds.do?' ;
    searchUrl += 'status=' + status;

    var ids = '';
    selectedIds.forEach(id => ids += id + ',');

    if(ids.length == 0)
      return;

    searchUrl += '&ids=' + ids.substring(0, ids.length - 1);

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setSelectedIds([]);
                      getSentenceCount(searchCategory, searchKeyword, searchStatus);
                     })
     .catch(err => console.log(err));
  };

  const deleteSentenceList = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentenceDeleteByIds.do?' ;

    var ids = '';
    selectedIds.forEach(id => ids += id + ',');

    if(ids.length == 0)
      return;

    searchUrl += 'ids=' + ids.substring(0, ids.length - 1);

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setSelectedIds([]);
                      getSentenceCount(searchCategory, searchKeyword, searchStatus);
                     })
     .catch(err => console.log(err));
  };


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }


  const columns = [

      {
        title: 'idx',
        dataIndex: 'sentenceId',
        align: 'left' as 'left',
        width: '10%',
        render: function (text, record) {
          /*
          return (<span style={{fontSize: '16px', color: '#7446f9', cursor: 'pointer' }}
                        onClick={() => history.push(MANAGER_SENTENCE_EDITOR_PATH + '/' + record.sentenceId)}> {text} </span>);
          */
          return (<span style={{fontSize: '16px', color: '#7446f9', cursor: 'pointer' }}
                        onClick={() => {setSelectedSentenceId(record.sentenceId); setVisibleSentenceConfirm(true);}}> {text} </span>);
        }
      },
      {
        title: 'Sentence',
        dataIndex: 'englishSentence',
        align: 'left' as 'left',
        width: '20%',
        render: function (text, record) {
          return (<div style={{fontSize: '16px'}}> {getHtml(text)} </div>);
        }
      },
      {
        title: 'Korean',
        dataIndex: 'koreanSentence',
        align: 'left' as 'left',
        width: '20%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getHtml(text)} </div>);
        }
      },

      {
        title: 'Description',
        dataIndex: 'description',
        align: 'left' as 'left',
        width: '20%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getDate(text)} </div>);
        }
      },

      {
        title: 'Created At',
        dataIndex: 'createDate',
        align: 'left' as 'left',
        width: '20%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getDate(text)} </div>);
        }
      },

      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left' as 'left',
        width: '10%',
        render: function (text) {
          return text ? (<span style={{color: '#50c900', fontSize: '16px' }}> 활성화 </span>) :  (<span style={{color: '#f9b446', fontSize: '16px' }}> 비활성화 </span>);
        }
      }
  ];

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedIds(selectedRowKeys);
    }

  }

  const { Option } = Select;
  const { Search } = Input;


  return (
    <>
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Row type='flex' justify='space-between' align='middle' style={{width: '100%'}} >

          <Col>
            <Button id='statusAllButton' style={{fontSize: '15px', width: '60px', height: '45px', backgroundColor: '#bda7fb', color: '#ffffff', fontWeight: 600}}
                    onClick={() => {setSearchStatus(-1);
                                    getSentenceCount(searchCategory, searchKeyword, -1);
                                    $('#statusAllButton').css('backgroundColor', '#bda7fb');
                                    $('#statusAllButton').css('color', '#ffffff');
                                    $('#statusActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusActiveButton').css('color', '#000000');
                                    $('#statusInActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusInActiveButton').css('color', '#000000');
                                  }}> ALL </Button>
            <Button id='statusActiveButton' style={{fontSize: '15px', width: '90px', height: '45px', fontWeight: 600}}
                    onClick={() => {setSearchStatus(1);
                                    getSentenceCount(searchCategory, searchKeyword, 1);
                                    $('#statusAllButton').css('backgroundColor', '#ffffff');
                                    $('#statusAllButton').css('color', '#000000');
                                    $('#statusActiveButton').css('backgroundColor', '#bda7fb');
                                    $('#statusActiveButton').css('color', '#ffffff');
                                    $('#statusInActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusInActiveButton').css('color', '#000000');
                                  }}> Active </Button>
            <Button id='statusInActiveButton' style={{fontSize: '15px', width: '90px', height: '45px', fontWeight: 600}}
                    onClick={() => {setSearchStatus(0);
                                    getSentenceCount(searchCategory, searchKeyword, 0);
                                    $('#statusAllButton').css('backgroundColor', '#ffffff');
                                    $('#statusAllButton').css('color', '#000000');
                                    $('#statusActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusActiveButton').css('color', '#000000');
                                    $('#statusInActiveButton').css('backgroundColor', '#bda7fb');
                                    $('#statusInActiveButton').css('color', '#ffffff');
                                  }}> InActive </Button>
            <Select value={searchCategory}
                    style={{width: '260px', height: '45px', margin: '0 10px'}}
                    onChange={value => {setSearchCategory(value); getSentenceCount(value, searchKeyword, searchStatus);}}>
                <Option value=''>전체</Option>
                <Option value='type02'>체험</Option>
                <Option value='type03'>자연</Option>
                <Option value='type04'>전통시장</Option>
                <Option value='type05'>식당</Option>
                <Option value='type06'>숙박</Option>
                <Option value='type07'>비상</Option>
                <Option value='type08'>운송</Option>
            </Select>
            <Input placeholder='Search Text' style={{width: '315px', height: '45px'}}
                   onChange={e => setSearchKeyword(e.target.value)}
                   onKeyDown={e => {if(e.key == 'Enter') getSentenceCount(searchCategory, searchKeyword, searchStatus);}}/>
          </Col>
          <Col>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', borderColor: '#f32357', backgroundColor: '#ffffff', color: '#f32357'}}
                    onClick={() => updateSentenceList(1)}> To Active </Button>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', borderColor: '#f32357', backgroundColor: '#ffffff', color: '#f32357', marginLeft: '10px'}}
                    onClick={() => updateSentenceList(0)}> To Inactive </Button>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', borderColor: '#f32357', backgroundColor: '#ffffff', color: '#f32357', marginLeft: '10px'}}
                    onClick={() => deleteSentenceList()}> Delete </Button>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                    onClick={() => {setSelectedSentenceId(0); setVisibleSentenceConfirm(true);}}> Add </Button>
          </Col>
        </Row>

        <Row type='flex' justify='center' style={{width: '100%', marginTop: '10px', border: 'solid 0.5px rgba(112, 112, 112, 0.2)'}}>
          <Col style={{width: '100%'}}>
            <Table
              rowKey='sentenceId'
              rowSelection={rowSelection}
              style={{ backgroundColor: 'rgba(255, 255, 255, 1)', color: '#FFFFFF' }}
              columns={columns}
              dataSource={sentenceList}
              pagination = {false}
            />
          </Col>
          <Col style={{width: '100%', height: '53px', backgroundColor: '#efeef0', padding: '16px'}}>
            total {totalCount}
          </Col>
        </Row>

        <Row type='flex' justify='center'  align='middle' style={{width: '1272px'}}>
          <Pagination current={currentPage} total={totalCount} onChange={(page, size) => getSentenceListData(page, size, searchCategory, searchKeyword, searchStatus)} style={{margin: '20px 0 20px 0'}}/>
          <Col>
            <span style={{margin: '0 10px'}}> 페이지 이동</span>
            <Input style={{width: '60px', height: '30px'}}
                   value={goCurrentPage}
                   onChange={e => setGoCurrentPage(Number(e.target.value))}
                   onKeyDown={e => {if(e.key == 'Enter') {setCurrentPage(goCurrentPage); getSentenceListData(goCurrentPage, size, searchCategory, searchKeyword, searchStatus);}}}/>
          </Col>
        </Row>

      </Row>

      <ManagerSentenceEditorDialog
          sentenceId={selectedSentenceId}
          visibleSentenceConfirm={visibleSentenceConfirm}
          onSentenceConfirm={(value)=>{ if(value)
                                          getSentenceListData(currentPage, size, searchCategory, searchKeyword, searchStatus);
                                        setVisibleSentenceConfirm(false);}}
        />

    </>
  );

};
