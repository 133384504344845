import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_SENTENCE_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const ManagerSentenceEditorDialog = (props) => {

  const { sentenceId, visibleSentenceConfirm, onSentenceConfirm } = props;

  const history = useHistory();

  const [sentence, setSentence] = useState<string>('');
  const [define, setDefine] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [englishSentence, setEnglishSentence] = useState<string>('');
  const [koreanSentence, setKoreanSentence] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [status, setStatus] = useState<number>(0);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState<any[]>([]);
  const [indeterminateCategory, setIndeterminateCategory] = useState<boolean>(false);
  const [checkAllCategory, setCheckAllCategory] = useState<boolean>(false);

  const [keywordList, setKeywordList] = useState<any[]>([]);
  const [tempKeyword, setTempKeyword] = useState<string>('');

  const [activeButtonStyle, setActiveButtonStyle] = useState<any>();
  const [inActiveButtonStyle, setInactiveButtonStyle] = useState<any>();

  const selectedStyle = {fontSize: '17px', height: '58px', width: '140px', borderColor: '#7446f9', backgroundColor: '#ffffff', color: '#7445f9'};
  const deselectedStyle ={fontSize: '17px', height: '58px', width: '140px', borderColor: '#dddddd', backgroundColor: '#f7f6fb', color: '#dddddd'};

  const fileReader = new FileReader();


  useEffect(() => {

    if(visibleSentenceConfirm){
      setSentence('');
      setDefine('');
      setCategory('');
      setKeyword('');
      setEnglishSentence('');
      setKoreanSentence('');
      setDescription('');
      setStatus(0);

      setActiveButtonStyle(deselectedStyle);
      setInactiveButtonStyle(selectedStyle);

      getCategoryData();

      if(sentenceId > 0) {
        getSentenceData(sentenceId);
      }
    }
  }, [visibleSentenceConfirm]);


  const getCategoryData = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/categorys.do?';
    searchUrl += 'page=0&size=100';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null)
                      setCategoryList(data);

                  })
     .catch(err => console.log(err));

  };


  const getSentenceData = (sentenceId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentence.do?sentenceId=' + sentenceId;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null) {
                      setSentence(data.sentence);
                      setDefine(data.define);

                      setCategory(data.category);
                      if(data.category != null)
                        setSelectedCategoryList(data.category.split(','));

                      setKeyword(data.keyword);
                      if(data.keyword != null)
                        setKeywordList(data.keyword.split(','));

                      setEnglishSentence(data.englishSentence);
                      setKoreanSentence(data.koreanSentence);
                      setDescription(data.description);

                      setStatus(data.status);
                      if(data.status == 1) {
                        setActiveButtonStyle(selectedStyle);
                        setInactiveButtonStyle(deselectedStyle);
                      }
                      else {
                        setActiveButtonStyle(deselectedStyle);
                        setInactiveButtonStyle(selectedStyle);
                      }
                    }

                  })
     .catch(err => console.log(err));

  };


  const createSentence = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentence.do';

    var categoryData = '';
    selectedCategoryList.forEach(category => { categoryData += category + ',' });
    if(categoryData.length > 0)
      categoryData = categoryData.substring(0, categoryData.length - 1);

    var keywordData = '';
    keywordList.forEach(keyword => { keywordData += keyword + ',' });
    if(keywordData.length > 0)
      keywordData = keywordData.substring(0, keywordData.length - 1);

    var params = {
            sentence: sentence,
            define: define,
            category: categoryData,
            keyword: keywordData,
            englishSentence: englishSentence,
            koreanSentence: koreanSentence,
            description: description,
            status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.sentenceId == null){
                        InfoModal("info", "알림", "문장 등록이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "문장 등록이 성공하였습니다.");

                        onSentenceConfirm(true);
                      }

                    })
      .catch(err => console.log(err));

  }

  const updateSentence = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentenceUpdate.do';

    var categoryData = '';
    selectedCategoryList.forEach(category => { categoryData += category + ',' });
    if(categoryData.length > 0)
      categoryData = categoryData.substring(0, categoryData.length - 1);

      var keywordData = '';
      keywordList.forEach(keyword => { keywordData += keyword + ',' });
      if(keywordData.length > 0)
        keywordData = keywordData.substring(0, keywordData.length - 1);

    var params = {
                  sentenceId: sentenceId,
                  sentence: sentence,
                  define: define,
                  category: categoryData,
                  keyword: keywordData,
                  englishSentence: englishSentence,
                  koreanSentence: koreanSentence,
                  description: description,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.sentenceId == null){
                        InfoModal("info", "알림", "문장 수정이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "문장 수정이 성공하였습니다.");

                        onSentenceConfirm(true);
                      }

                    })
      .catch(err => console.log(err));

  }

  const createSentenceBulk = (category, englishSentence, koreanSentence, description) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentence.do';

    var params = {
            sentence: '',
            define: '',
            category: category,
            keyword: '',
            englishSentence: englishSentence,
            koreanSentence: koreanSentence,
            description: description,
            status: 1
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.sentenceId == null){
                        console.log("문장 등록이 실패하였습니다.");
                      }
                      else {
                        console.log("문장 등록이 성공하였습니다.");
                      }

                    })
      .catch(err => console.log(err));

  }

  const onCheckAllChangeCategory = (e) => {

    if(e.target.checked)
      setSelectedCategoryList(categoryList.map((item) => item.category));
    else
      setSelectedCategoryList([]);

    setIndeterminateCategory(false);
    setCheckAllCategory(e.target.checked);

  };

  const checkCategoryList = (checkedList) => {

    if(checkedList.length == 0) {
      setIndeterminateCategory(false);
      setCheckAllCategory(false);
    }
    else if(checkedList.length < 12) {
      setIndeterminateCategory(true);
      setCheckAllCategory(false);
    }
    else {
      setIndeterminateCategory(false);
      setCheckAllCategory(true);
    }
    setSelectedCategoryList(checkedList);
  }


  const uploadCsv = () => {

    let csvFile = $("#uploadCsvFile")[0].files[0];

    if (csvFile) {
       fileReader.onload = function (event:any) {
         const text = event.target.result;
        //  console.log(event);
        //  console.log(event.target.result);
         csvFileToArray(text);
       };

       fileReader.readAsText(csvFile);
     }

/*
    let json = csvToJson.getJsonFromCsv(csvFile);

    for(let i=0; i<json.length;i++){
      console.log(json[i]);
    }
*/
  }

  const csvFileToArray = string => {
     var array = string.toString().split("\r\n")
    //  console.log(array); here we are getting the first rows which is our header rows to convert it into keys we are logging it here
      var data:any[] = []
      // console.log(data);
      for(const r of array){
        // console.log(r);
          let row = r.toString().split("|")
          data.push(row)
      }
      console.log(data)
      var heading = data[1]
      // console.log(heading); to get the column headers which will act as key
      var ans_array:any[] = []
      // console.log(ans_array);
      for(var i=2;i<data.length;i++){
          var row = data[i]
          var obj:any = {};

          if(row.length != heading.length)
            continue;

          for(var j=0;j<heading.length;j++){
              if(!row[j]){
                  row[j]="NA";
              }
              // console.log(row[j].toString())
              //obj[heading[j].replaceAll(" ","_")] = row[j].toString().replaceAll(" ","_");

              //obj.category='type01';
              //obj.englishSentence=row[0];
              //obj.koreanSentence=row[2];
              //obj.description=row[1];


          }
          createSentenceBulk('type08', row[0], row[2], row[1])
          ans_array.push(obj)
      }
      console.log({ans_array})
   };


  const removeKeyword = (word) => {
    let words = keywordList.filter((keyword) => keyword != word);
    setKeywordList(words);
  }

  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const getCsvSaveButton = () => {

    if(sentenceId == -1)
      return (<Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
            onClick={() => {$('#uploadCsvFile').click() }}> CSV Save </Button>);
    else
      return (<></>)
  }

  const { Option } = Select;
  const { Search } = Input;


  var editorConfiguration = {
      ckfinder: {
          // Upload the images to the server using the CKFinder QuickUpload command.
          uploadUrl: SOPOONG_URL + '/fileupload/insertAnyEventMimeImage.do'
      },
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'alignment', 'fontBackgroundColor', 'fontColor', 'fontSize', '|', 'imageUpload'],
      image: {
          // Configure the available styles.
          styles: [
              'alignLeft', 'alignCenter', 'alignRight'
          ],
          // Configure the available image resize options.
          resizeOptions: [
              {
                  name: 'imageResize:original',
                  label: 'Original',
                  value: null
              },
              {
                  name: 'imageResize:50',
                  label: '50%',
                  value: '50'
              },
              {
                  name: 'imageResize:75',
                  label: '75%',
                  value: '75'
              }
          ],
          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
              'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
              '|',
              'imageResize',
              '|',
              'imageTextAlternative'
          ]
      }
  };


  return (
    <Modal
        className='popup-content'
         visible={visibleSentenceConfirm}
         closable={false}
         title=""
         width={'80%'}
         style={{ top: 20 }}
         centered
         footer={null}
         >
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '0'}}>
        <Col style={{width: '100%', backgroundColor: '#ffffff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '0px 80px'}}>
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='start' align='top' style={{marginTop: '65px'}}>
              <Col span={4}> 대분류 </Col>
              <Col span={3}>
                <Checkbox checked={checkAllCategory}
                          indeterminate={indeterminateCategory}
                          onChange={onCheckAllChangeCategory}>전체</Checkbox>
              </Col>
              <Col span={17}>
                <Checkbox.Group style={{width: '100%'}}
                                value={selectedCategoryList}
                                onChange={values => checkCategoryList(values)} >
                  <Row type='flex' justify='start'>

                    {categoryList ? (
                      categoryList.map(category => (
                        <Col span={4} key={category.categoryId} style={{marginBottom: 20}}>
                          <Checkbox value={category.category}>{category.name}</Checkbox>
                        </Col>
                      ))
                    ) : (
                      <Empty />
                    )}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>키워드 </Col>
              <Col span={20} style={{backgroundColor: '#f7f6fb', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '24px'}}>
                <Row>
                  <Input style={{height: '50px', width: '420px'}}
                         value={tempKeyword}
                         onChange={e => setTempKeyword(e.target.value)}
                         onKeyDown={e => {
                                          if(e.key == 'Enter'){
                                            setKeywordList([...keywordList, tempKeyword]);
                                            setTempKeyword('');
                                          }
                                        }}/>
                  <span style={{marginLeft:'10px'}}>  *Enter로 태그를 추가할 수 있습니다. </span>
                </Row>
                <Row style={{marginTop: '20px'}} />
                <Row>
                  {keywordList ? (
                    keywordList.map((word, index) => {
                      return (
                        <Col key={index} span={2} style={{backgroundColor: '#f3efff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '5px 10px', marginRight: '10px'}}>
                          <span>{word}</span>
                          <span style={{color: '#774af9', marginLeft: '10px', cursor: 'pointer'}}
                                onClick={() => removeKeyword(word)}> X </span>
                        </Col>);
                      })) :

                    <Empty />
                  }
                </Row>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> 활성 여부 </Col>
              <Col span={20}>
                <Button style={activeButtonStyle}
                        onClick={e => {setStatus(1); setActiveButtonStyle(selectedStyle); setInactiveButtonStyle(deselectedStyle);}} > 활성화 </Button>
                <Button style={inActiveButtonStyle}
                        onClick={e => {setStatus(0); setActiveButtonStyle(deselectedStyle); setInactiveButtonStyle(selectedStyle);}} > 비활성화 </Button>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>영문 문장</Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={englishSentence}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEnglishSentence(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>한글발음 문장</Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={koreanSentence}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setKoreanSentence(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px', marginBottom: '50px'}}>
              <Col span={4}>설명</Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setDescription(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start'>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row type='flex' justify='end' style={{margin: '40px 0 60px 0'}}>
              <Col>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#928ca2', color: '#ffffff'}}
                        onClick={() => onSentenceConfirm(false)}> Cancel </Button>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                        onClick={() => { sentenceId == 0 ? createSentence() : updateSentence() }}> Save </Button>
                {getCsvSaveButton()}

              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <Input type='file' id='uploadCsvFile' accept='.csv'
              style={{display: 'none'}}
              onChange={() => {uploadCsv()}}
              />

    </Modal>
  );

};
