import React, { Component, useEffect, useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_WORD_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

declare global {
  interface Window {
    daum: any;
  }
}

export const ManagerEventEditorDialog = (props) => {

  const { eventId, visibleEventConfirm, onEventConfirm } = props;

  const history = useHistory();

  const [name, setName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [eventDate, setEventDate] = useState<string>('');
  const [eventTime, setEventTime] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [radius, setRadius] = useState<number>(0);
  const [status, setStatus] = useState<number>(0);

  const [map, setMap] = useState<any>({});
  const [placeService, setPlaceService] = useState<any>({});
  const [infowindow, setInfowindow] = useState<any>({});
  const [markers, setMarkers] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>('');


  const [activeButtonStyle, setActiveButtonStyle] = useState<any>();
  const [inActiveButtonStyle, setInactiveButtonStyle] = useState<any>();

  const selectedStyle = {fontSize: '17px', height: '58px', width: '140px', borderColor: '#7446f9', backgroundColor: '#ffffff', color: '#7445f9'};
  const deselectedStyle ={fontSize: '17px', height: '58px', width: '140px', borderColor: '#dddddd', backgroundColor: '#f7f6fb', color: '#dddddd'};

  const mapRef = useRef(null);


  useEffect(() => {

    if(visibleEventConfirm) {
      setName('');
      setTitle('');
      setEventDate('');
      setEventTime('');
      setAddress('');
      setContact('');
      setDescription('');
      setLatitude(0);
      setLongitude(0);
      setRadius(0);
      setStatus(0);

      setActiveButtonStyle(deselectedStyle);
      setInactiveButtonStyle(selectedStyle);

      if(eventId > 0) {
        getEventData(eventId);
      }
      else {
        if (window.daum != undefined)
          setTimeout(() => getDaumMap(), 100);
      }

    }

  }, [visibleEventConfirm]);


  const getEventData = (eventId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/event.do?eventId=' + eventId;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null) {
                      setName(data.name);
                      setTitle(data.title);
                      setEventDate(data.eventDate);
                      setEventTime(data.eventTime);
                      setAddress(data.address);

                      setContact(data.contact);
                      setDescription(data.description);

                      setLatitude(Number(data.latitude));
                      setLongitude(Number(data.longitude));
                      setRadius(data.radius);

                      setStatus(data.status);
                      if(data.status == 1) {
                        setActiveButtonStyle(selectedStyle);
                        setInactiveButtonStyle(deselectedStyle);
                      }
                      else {
                        setActiveButtonStyle(deselectedStyle);
                        setInactiveButtonStyle(selectedStyle);
                      }


                      if(data.latitude != null && data.longitude != null)
                        updateDaumMap(data.title, Number(data.latitude), Number(data.longitude));
                      else
                        updateDaumMap(data.title, 37.867786, 127.720815);
                    }

                  })
     .catch(err => console.log(err));

  };


  const createEvent= () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/event.do';

    var params = {
                  name: name,
                  title: title,
                  eventDate: eventDate,
                  eventTime: eventTime,
                  contact: contact,
                  address: address,
                  latitude: latitude.toString(),
                  longitude: longitude.toString(),
                  radius: radius,
                  description: description,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.eventId == null){
                        InfoModal("info", "알림", "이벤트 등록이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "이벤트 등록이 성공하였습니다.");
                        onEventConfirm(true);
                      }

                    })
      .catch(err => console.log(err));

  }

  const updateEvent = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/eventUpdate.do';

    console.log('latitude : ' + latitude + ' , longitude : ' + longitude);

    var params = {
                  eventId: eventId,
                  name: name,
                  title: title,
                  eventDate: eventDate,
                  eventTime: eventTime,
                  contact: contact,
                  address: address,
                  latitude: latitude.toString(),
                  longitude: longitude.toString(),
                  radius: radius,
                  description: description,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.eventId == null){
                        InfoModal("info", "알림", "이벤트 수정이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "이벤트 수정이 성공하였습니다.");
                        onEventConfirm(true);
                      }

                    })
      .catch(err => console.log(err));

  }



  const getDaumMap = () => {
    // 지도 API
    var mapContainer = document.getElementById('map'); // 지도를 표시할 div
    var mapOption = {
      center: new window.daum.maps.LatLng(37.867786, 127.720815),
      level: 4 // 지도의 확대 레벨
    };
    // 지도를 생성합니다

    //console.log('mapContainer : ' + mapContainer);

    if(mapContainer == null)
      return;

    var daumMap = new window.daum.maps.Map(mapContainer, mapOption);
    daumMap.setMapTypeId(window.daum.maps.MapTypeId.ROADMAP);
    // 마커가 표시될 위치입니다
    var markerPosition = new window.daum.maps.LatLng(37.86710996062191, 127.71824150588344);
    // 마커를 생성합니다
    var marker = new window.daum.maps.Marker();
    // 클릭한 위치에 대한 주소를 표시할 인포윈도우입니다
    var infowindow = new window.daum.maps.InfoWindow({ zindex: 1 });
    setMap(daumMap);

    setPlaceService(new window.daum.maps.services.Places());
    setInfowindow(new window.daum.maps.InfoWindow({ zindex: 1 }))
    // 지도에 클릭 이벤트를 등록합니다
    // 지도를 클릭하면 마지막 파라미터로 넘어온 함수를 호출합니다
    // 주소-좌표 변환 객체를 생성합니다

    window.daum.maps.event.addListener(daumMap, 'click', function (mouseEvent) {
      // 클릭한 위도, 경도 정보를 가져옵니다
      var latlng = mouseEvent.latLng;

      console.log('latitude : ' + latlng.getLat() + ' , longitude : ' + latlng.getLng());
      setLatitude(latlng.getLat());
      setLongitude(latlng.getLng());

      var content = '<div style="padding: 5px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap; color: #0000ff; font-weight: 800">';
      content += '<div>&nbsp;&nbsp;' + title + '</div>';
      content += '</div>';

      // 마커를 클릭한 위치에 표시합니다
      marker.setPosition(mouseEvent.latLng);
      marker.setMap(daumMap);

      infowindow.setContent(content);
      infowindow.open(daumMap, marker);

    });
  }

  const updateDaumMap = (placeName, latitude, longitude) => {

    // 지도 API
    var mapContainer = document.getElementById('map'); // 지도를 표시할 div
    var mapOption = {
      center: new window.daum.maps.LatLng(latitude, longitude),
      level: 2 // 지도의 확대 레벨
    };

    // 지도를 생성합니다
    var daumMap = new window.daum.maps.Map(mapContainer, mapOption);
    daumMap.setMapTypeId(window.daum.maps.MapTypeId.ROADMAP);


    // 마커가 표시될 위치입니다
    var markerPosition = new window.daum.maps.LatLng(latitude, longitude);
    // 마커를 생성합니다
    var marker = new window.daum.maps.Marker();
    // 클릭한 위치에 대한 주소를 표시할 인포윈도우입니다
    //var infowindow2 = new window.daum.maps.InfoWindow({ zindex: 1 });
    marker.setPosition(markerPosition);
    marker.setMap(daumMap);
    setMap(daumMap);

    var infowindow = new window.daum.maps.InfoWindow({ zindex: 1 });

    // 지도에 클릭 이벤트를 등록합니다
    // 지도를 클릭하면 마지막 파라미터로 넘어온 함수를 호출합니다
    // 주소-좌표 변환 객체를 생성합니다
    window.daum.maps.event.addListener(daumMap, 'click', function (mouseEvent) {
      // 클릭한 위도, 경도 정보를 가져옵니다
      var latlng = mouseEvent.latLng;
      setLatitude(latlng.getLat());
      setLongitude(latlng.getLng());

      var content = '<div class="info-title" style="padding: 5px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap; color: #0000FF">' + placeName + '</div>';

      // 마커를 클릭한 위치에 표시합니다
      marker.setPosition(mouseEvent.latLng);
      marker.setMap(daumMap);

      infowindow.setContent(content);
      infowindow.open(daumMap, marker);

    });

    var content = '<div class="info-title" style="padding: 5px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap; color: #0000FF">' + placeName + '</div>';

    infowindow.setContent(content);
    infowindow.open(daumMap, marker);

    var infoTitle = document.querySelectorAll('.info-title');
    infoTitle.forEach(function(e:any) {
        var w = e.offsetWidth + 10;
        var ml = w/2;
        e.parentElement.style.top = "0px";
        e.parentElement.style.left = "50%";
        e.parentElement.style.marginLeft = -ml+"px";
        e.parentElement.style.width = w+"px";
        //e.parentElement.previousSibling.style.display = "none";
        //e.parentElement.parentElement.style.border = "0px";
        //e.parentElement.parentElement.style.background = "unset";
    });

  }


  const setMapType = (maptype) => {
    var roadmapControl = document.getElementById('btnRoadmap');
    var skyviewControl = document.getElementById('btnSkyview');
    if (maptype === 'roadmap') {
      map.setMapTypeId(window.daum.maps.MapTypeId.ROADMAP);
      if (roadmapControl != null)
          roadmapControl.className = 'selected_btn';
      if (skyviewControl != null)
          skyviewControl.className = 'btn';
    }
    else {
      map.setMapTypeId(window.daum.maps.MapTypeId.HYBRID);
      if (skyviewControl != null)
          skyviewControl.className = 'selected_btn';
      if (roadmapControl != null)
          roadmapControl.className = 'btn';
    }
  }

  // 지도 확대, 축소 컨트롤에서 확대 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomIn = () => {
    map.setLevel(map.getLevel() - 1);
  }

  // 지도 확대, 축소 컨트롤에서 축소 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomOut = () => {
    map.setLevel(map.getLevel() + 1);
  }

  const searchPlaces = () =>  {

    if (!keyword.replace(/^\s+|\s+$/g, '')) {
        alert('키워드를 입력해주세요!');
        return false;
    }

    // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
    placeService.keywordSearch( keyword, placesSearchCB);
  }

    // 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
  const placesSearchCB = (data, status, pagination) => {
    if (status === window.daum.maps.services.Status.OK) {

      // 정상적으로 검색이 완료됐으면
      // 검색 목록과 마커를 표출합니다
      displayPlaces(data);

      // 페이지 번호를 표출합니다
      displayPagination(pagination);

    } else if (status === window.daum.maps.services.Status.ZERO_RESULT) {

      alert('검색 결과가 존재하지 않습니다.');
      return;

    } else if (status === window.daum.maps.services.Status.ERROR) {

      alert('검색 결과 중 오류가 발생했습니다.');
      return;

    }
  }

  // 검색 결과 목록과 마커를 표출하는 함수입니다
  const displayPlaces = (places) => {

    var listEl = document.getElementById('placesList'),
    menuEl = document.getElementById('menu_wrap'),
    fragment = document.createDocumentFragment(),
    bounds = new window.daum.maps.LatLngBounds(),
    listStr = '';

    // 검색 결과 목록에 추가된 항목들을 제거합니다
    removeAllChildNods(listEl);

    // 지도에 표시되고 있는 마커를 제거합니다
    removeMarker();

    for ( var i=0; i<places.length; i++ ) {

      // 마커를 생성하고 지도에 표시합니다
      var placePosition = new window.daum.maps.LatLng(places[i].y, places[i].x),
      marker = addMarker(placePosition, i),
      itemEl = getListItem(i, places[i]); // 검색 결과 항목 Element를 생성합니다

      // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
      // LatLngBounds 객체에 좌표를 추가합니다
      bounds.extend(placePosition);

      // 마커와 검색결과 항목에 mouseover 했을때
      // 해당 장소에 인포윈도우에 장소명을 표시합니다
      // mouseout 했을 때는 인포윈도우를 닫습니다
      (function(marker, title) {
        window.daum.maps.event.addListener(marker, 'mouseover', function() {
            displayInfowindow(marker, title);
        });

        window.daum.maps.event.addListener(marker, 'mouseout', function() {
            infowindow.close();
        });

        itemEl.onmouseover =  function () {
            displayInfowindow(marker, title);
        };

        itemEl.onmouseout =  function () {
            infowindow.close();
        };
      })(marker, places[i].place_name);

      fragment.appendChild(itemEl);
    }

    // 검색결과 항목들을 검색결과 목록 Element에 추가합니다
    if(listEl != null)
      listEl.appendChild(fragment);

    if(menuEl != null)
      menuEl.scrollTop = 0;

    // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
    map.setBounds(bounds);
  }

  // 검색결과 항목을 Element로 반환하는 함수입니다
  const getListItem = (index, places) => {

    var el = document.createElement('li');
    var itemStr = '<span class="markerbg marker_' + (index+1) + '"></span>' +
          '<div class="info">' +
          '   <h5>' + places.place_name + '</h5>';

    if (places.road_address_name) {
    itemStr += '    <span>' + places.road_address_name + '</span>' +
              '   <span class="jibun gray">' +  places.address_name  + '</span>';
    } else {
    itemStr += '    <span>' +  places.address_name  + '</span>';
    }

    itemStr += '  <span class="tel">' + places.phone  + '</span>' +
          '</div>';

    el.innerHTML = itemStr;
    el.className = 'item';

    return el;
  }


  // 마커를 생성하고 지도 위에 마커를 표시하는 함수입니다
  const addMarker = (position, idx) => {
    var imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png'; // 마커 이미지 url, 스프라이트 이미지를 씁니다
    var imageSize = new window.daum.maps.Size(36, 37);  // 마커 이미지의 크기
    var imgOptions =  {
        spriteSize : new window.daum.maps.Size(36, 691), // 스프라이트 이미지의 크기
        spriteOrigin : new window.daum.maps.Point(0, (idx*46)+10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
        offset: new window.daum.maps.Point(13, 37) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
    };
    var markerImage = new window.daum.maps.MarkerImage(imageSrc, imageSize, imgOptions),
        marker = new window.daum.maps.Marker({
        position: position, // 마커의 위치
        image: markerImage
    });

    marker.setMap(map); // 지도 위에 마커를 표출합니다
    markers.push(marker);  // 배열에 생성된 마커를 추가합니다

    return marker;
  }

  // 지도 위에 표시되고 있는 마커를 모두 제거합니다
  const removeMarker = () => {
    for ( var i = 0; i < markers.length; i++ ) {
      markers[i].setMap(null);
    }

    setMarkers([]);
    //markers = [];
  }


  // 검색결과 목록 하단에 페이지번호를 표시는 함수입니다
  const displayPagination = (pagination) => {
    var paginationEl = document.getElementById('pagination'),
        fragment = document.createDocumentFragment(),
        i;

    // 기존에 추가된 페이지번호를 삭제합니다

    if(paginationEl != null) {
      while (paginationEl.hasChildNodes()) {

        if(paginationEl.lastChild != null)
          paginationEl.removeChild (paginationEl.lastChild);
      }
    }

    for (i=1; i<=pagination.last; i++) {
      var el = document.createElement('a');
      el.href = "#";
      el.innerHTML = i;

      if (i===pagination.current) {
        el.className = 'on';
      } else {
        el.onclick = (function(i) {
          return function() {
            pagination.gotoPage(i);
          }
        })(i);
      }

      fragment.appendChild(el);
    }

    if(paginationEl != null)
      paginationEl.appendChild(fragment);
  }


  // 검색결과 목록 또는 마커를 클릭했을 때 호출되는 함수입니다
  // 인포윈도우에 장소명을 표시합니다
  const displayInfowindow = (marker, title) => {
    var content = '<div style="padding:5px;z-index:1;">' + title + '</div>';

    infowindow.setContent(content);
    infowindow.open(map, marker);
  }

  // 검색결과 목록의 자식 Element를 제거하는 함수입니다
  const removeAllChildNods = (el) => {
    while (el.hasChildNodes()) {
      el.removeChild (el.lastChild);
    }
  }


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }


  const getMap = () => {

    return(
      <Row>
        <div id='map' className='daum_map' style={{position: 'relative', overflow: 'hidden', width: '100%', height: '400px', margin: '0', padding: '0', color: '#000000'}} />
        <div className='custom_typecontrol radius_border'>
          <span id='btnRoadmap' className='btn' onClick={()=>setMapType('roadmap')}> 지도 </span>
          <span id='btnSkyview' className='selected_btn' onClick={()=>setMapType('skyview')}> 스카이뷰 </span>
        </div>
        <div className='custom_zoomcontrol radius_border'>
          <span className='btn' onClick={()=>zoomIn()}>
            <img src='https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png' alt='확대' />
          </span>
          <span className='btn' onClick={()=>zoomOut()}>
            <img src='https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png' alt='축소' />
          </span>
        </div>
        <p id='result' />
        <div id="menu_wrap" className="bg_white">
          <div className="option">
            <Row type='flex' align='middle'>
              <Col span={16}>
                <Input placeholder='키워드를 입력하세요' style={{height: '32px'}}  onChange={e=>setKeyword(e.target.value)} />
              </Col>
              <Col span={8}>
                <Button onClick={()=>{searchPlaces()}} > 검색 </Button>
              </Col>
            </Row>
          </div>

          <ul id="placesList"></ul>
          <div id="pagination"></div>
        </div>

      </Row>
    );
  }

  const { Option } = Select;
  const { Search } = Input;


  var editorConfiguration = {
      ckfinder: {
          // Upload the images to the server using the CKFinder QuickUpload command.
          uploadUrl: SOPOONG_URL + '/fileupload/insertAnyEventMimeImage.do'
      },
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'alignment', 'fontBackgroundColor', 'fontColor', 'fontSize', '|', 'imageUpload'],
      image: {
          // Configure the available styles.
          styles: [
              'alignLeft', 'alignCenter', 'alignRight'
          ],
          // Configure the available image resize options.
          resizeOptions: [
              {
                  name: 'imageResize:original',
                  label: 'Original',
                  value: null
              },
              {
                  name: 'imageResize:50',
                  label: '50%',
                  value: '50'
              },
              {
                  name: 'imageResize:75',
                  label: '75%',
                  value: '75'
              }
          ],
          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
              'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
              '|',
              'imageResize',
              '|',
              'imageTextAlternative'
          ]
      }
  };


  return (
    <Modal
        className='popup-content'
         visible={visibleEventConfirm}
         closable={false}
         title=""
         width={'80%'}
         style={{ top: 20 }}
         centered
         footer={null}
         >
      <Row className='event-content' type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Col style={{width: '100%', backgroundColor: '#ffffff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '0px 80px'}}>
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> 활성 여부 </Col>
              <Col span={20}>
                <Button style={activeButtonStyle}
                        onClick={e => {setStatus(1); setActiveButtonStyle(selectedStyle); setInactiveButtonStyle(deselectedStyle);}} > 활성화 </Button>
                <Button style={inActiveButtonStyle}
                        onClick={e => {setStatus(0); setActiveButtonStyle(deselectedStyle); setInactiveButtonStyle(selectedStyle);}} > 비활성화 </Button>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>이름</Col>
              <Col span={20}>
                <Input placeholder='이름'
                       style={{height: '58px'}}
                       value={name}
                       onChange={e => setName(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>제목</Col>
              <Col span={20}>
                <Input placeholder='제목'
                       style={{height: '58px'}}
                       value={title}
                       onChange={e => setTitle(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>행사일정</Col>
              <Col span={10}>
                <Input placeholder='행사일정'
                       style={{height: '58px'}}
                       value={eventDate}
                       onChange={e => setEventDate(e.target.value)} />
              </Col>
              <Col span={2} offset={3}>연락처</Col>
              <Col span={5}>
                <Input placeholder='연락처'
                       style={{height: '58px'}}
                       value={contact}
                       onChange={e => setContact(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>주소</Col>
              <Col span={10}>
                <Input placeholder='주소'
                       style={{height: '58px'}}
                       value={address}
                       onChange={e => setAddress(e.target.value)} />
              </Col>
              <Col span={2} offset={3}>반경</Col>
              <Col span={5}>
                <Input placeholder='반경'
                       style={{height: '58px'}}
                       value={radius}
                       onChange={e => setRadius(Number(e.target.value))} />
              </Col>
            </Row>
            <Row style={{marginTop: '10px'}}>
              <Col span={20} offset={4}>
                {getMap()}
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}><div>소개</div></Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '5px'}}>
              <Col span={24}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setDescription(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='end' style={{margin: '40px 0 88px 0'}}>
              <Col>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#928ca2', color: '#ffffff'}}
                        onClick={() => onEventConfirm(false)}> Cancel </Button>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                        onClick={() => { eventId == 0 ? createEvent() : updateEvent() }}> Save </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

    </Modal>
  );

};
