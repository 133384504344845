import React, { useState, useEffect, CSSProperties } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Typography, Button, Divider, Icon, Menu, Dropdown } from 'antd';
import $ from 'jquery';

import { MANAGER_LOGIN_PATH,
      storageService } from 'common';

/**
 * @description NomalLayout에서 사용하는 메인 헤더 컴포넌트
 */
export const MainHeader = (props) => {

  const [userId, setUserId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  const history = useHistory();

  useEffect(() => {

    console.log('currentTime : ' + (new Date()).getTime() );

    if (storageService.getItem('anyeventmime-user')) {

        var user = JSON.parse( storageService.getItem('anyeventmime-user') as string );

        if(user != undefined) {

          var currentTime = (new Date()).getTime();

          /*
          if(currentTime - user.loginTime > 3600000) {
            storageService.removeItem('anyeventmime-user');
            return;
          }
          */

          setUserId(user.userId);
          setUserName(user.userName);

          $('#user').show();
          $('#logout').show();
          $('#mypage').show();
          $('#setting').show();
          $('#login').hide();
          $('#account').hide();
        }

    }
    else {
      $('#login').show();
      $('#logout').hide();
    }

  }, [props]);


  const logout = () => {
    storageService.removeItem('anyeventmime-user');

    $('#user').hide();
    $('#logout').hide();
    $('#mypage').hide();
    $('#setting').hide();

    $('#login').show();
    $('#account').show();

    history.push(MANAGER_LOGIN_PATH);

  }

  const { Header, Footer, Sider, Content } = Layout;

  const { Title, Text } = Typography;

  return (
    <>
      <div className='header-container'>

        <Row type='flex' justify='space-between' style={{width: '100%', padding: '24px 20px 24px 40px', borderBottom: 'solid 1px rgba(112, 112, 112, 0.2)'}} >
          <Col style={{fontSize: '26px'}}>
            <div id='headerTitle'></div>
          </Col>

          <Col>
            <span>
              <img src='images/anyeventmime/background/icon-login-user.png' style={{width: '24px', height: '24px'}} />
            </span>
            <span style={{marginLeft: '5px', marginRight: '30px', fontSize: '17px'}}>
              {userId}
            </span>
            <span>
              <img src='images/anyeventmime/background/icon-logout.png'
                   style={{width: '24px', height: '24px', cursor: 'pointer'}}
                   onClick={() => logout()}/>
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
};
