import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';


export const MobileFestivalDialog = (props) => {

  const { visibleFestivalConfirm, onVisibleFestivalConfirm } = props;

  const [title, setTitle] = useState<string>('');
  const [festivalContext, setFestivalContext] = useState<string>('');



  useEffect(() => {

    var context = '춘천마임축제는 해마다 5월 마지막 주 봄과 물의도시 춘천에서 ';
    context += '열리는 축제로 물과 불, 몸과 움직임을 통해 도시와 예술, 시민을';
    context += '잇는 공연 예술 축제입니다.<br /><br />';

    context += '프랑스 미모스 마임축제, 영국의 런던 마임축제와 더불어 세계 3대 ';
    context += '마임축제로 손꼽히며 국제적 위상을 높여가고 있습니다.<br /><br />';

    context += '도시와 도시민이 가진 정서와 마음을 말 없는 움직임과 물, 불, 꽃, ';
    context += '빛, 영상 등 다양한 장르와의 융복합을 통해 ‘춘천 + 마임 + 축제’라는';
    context += '새로운 장르를 만들어가고 있습니다.<br /><br />';

    context += '물과 불의 원초적 에너지를 기반으로 도시와 시대가 가진 욕망을 ';
    context += '예술적 몸짓으로풀어헤치는 ‘난장’의 축제입니다.';

    setFestivalContext(context);

  }, []);


  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }

  return (
    <Modal
         className='festival-modal'
         visible={visibleFestivalConfirm}
         closable={false}
         title=""
         width='100vw'
         style={{ top: 0 }}
         footer={null}
         >
     <Row type='flex' justify='end' align='middle' style={{width: '100%', height: 30,  paddingRight: 10, backgroundColor: '#FFCC01'}}>
       <img src='images/anyeventmime/background/button_minifestival_close.png'  style={{height:20}} onClick={() => onVisibleFestivalConfirm(false)}/>
     </Row>
      <Row type='flex' justify='center' style={{width: '100%', marginTop: 20, backgroundColor: '#ffffff', padding: '0'}}>
        <img src='images/anyeventmime/background/minifestival_content_img_1.png'  style={{height:37}}/>
      </Row>
      <Row type='flex' justify='center' style={{width: '100%', marginTop: 20, backgroundColor: '#ffffff', padding: '0'}}>
        <img src='images/anyeventmime/background/minifestival_content_img_2.png'  style={{height:150}}/>
      </Row>
      <Row type='flex' justify='center' style={{width: '100%', marginTop: 20, padding: '20px 40px 40px 40px', backgroundColor: '#ffffff'}}>
        <div style={{display: 'inline-block'}}>
          {getHtml(festivalContext)}
        </div>
      </Row>
    </Modal>


  );

};
