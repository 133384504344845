import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';


export const ManagerLoginView = (props) => {

  const history = useHistory();

  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  //const [voices, setVoices] = useState<any[]>([]);

  var voices : any[] = [];


  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();
    $('#LoginPanel').css('background-image', 'url("images/anyeventmime/background/manager_background.jpg")')

/*
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }



    setVoiceList();
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = setVoiceList;
    }

    document.addEventListener("click", function(e) { speech('');});

*/

  }, []);

  const setVoiceList = () => {
    //setVoices(window.speechSynthesis.getVoices());
    voices = window.speechSynthesis.getVoices();
    //console.log('voices : ' + voices);
  }

  const speech = (txt) => {
    if(!window.speechSynthesis) {
      alert("음성 재생을 지원하지 않는 브라우저입니다. 크롬, 파이어폭스 등의 최신 브라우저를 이용하세요");
      return;
    }

    var utterThis = new SpeechSynthesisUtterance('Hello, How are you?');

    utterThis.onend = function (event) {
      console.log('end');
    };
    utterThis.onerror = function(event) {
      console.log('error', event);
    };

    var lang = 'en-US';
    var voiceFound = false;


    voices.forEach(voice => {
      console.log(voice.lang + '\n');

      if(voice.lang == lang) {
        utterThis.voice = voice;
        voiceFound = true;
      }
    });

    if(!voiceFound) {
      alert('voice not found');
      return;
    }

    utterThis.lang = lang;
    utterThis.pitch = 1;
    utterThis.rate = 1;  //속도
    window.speechSynthesis.speak(utterThis);

  }



  const success = (position)  => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    var textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
    alert(textContent);
  }

  const error = () => {
    alert("Unable to retrieve your location");
  }





  const checkLogin = () => {

    if(userId == 'engtech' && password == 'engtech')
      history.push(MANAGER_ADMIN_PATH);

    let loginParam = {userId: userId, password: password}
    let url = SOPOONG_URL + ANYEVENTMIME_URL + '/login.do';

    fetch( url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(loginParam)
    } )
      .then(response => response.json())
      .then(data => {
                      console.log(data);
                      if(data.userId == null) {
                        console.log('로그인 실패');
                        InfoModal('warning', '알림', '아이디와 비밀번호가 일치하지 않습니다."')
                        storageService.removeItem('anyeventmime-user');
                      }
                      else {

                        data.loginTime = (new Date()).getTime();
                        storageService.setItem('anyeventmime-user', JSON.stringify(data));

                        $('#MainHeader').show();
                        $('#Sidebar').show();

                        history.push(MANAGER_ADMIN_PATH);
                      }

                    })
      .catch(err => console.log(err));
  }

  return (
    <>

      <div id='LoginPanel' className='login-container'>
        <Row type='flex' justify='center' align='middle' style={{height: '100vh'}}>
          <Col style={{width: '558px', height: '520px', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '0px', boxShadow: '0px 0px 20px 0 rgba(0, 0, 0, 0.3)'}}>
            <Row  type='flex' justify='center' align='middle' style={{marginTop: '65px'}}>
              <Col>
                <img src='images/anyeventmime/background/anyeventmime_logo_icon.png' style={{width: 220, height: 95}} />
              </Col>
            </Row>
            <Row  type='flex' justify='center' style={{marginTop: '45px'}}>
              <Col>
                <Input
                  placeholder='ID'
                  value={userId}
                  onChange={e => setUserId(e.target.value)}
                  suffix={<Icon type='user' style={{fontSize: '20px', color: '#7446f9', marginRight: '10px'}} />}
                  style={{width: '420px', height: '72px', marginLeft: '0px', padding: '0px', border: '0px', fontSize: '17px'}}
                />
              </Col>
            </Row>
            <Row  type='flex' justify='center' style={{marginTop: '20px'}}>
              <Col>
                <Input
                  placeholder='Password'
                  type='password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  suffix={<Icon type='unlock' style={{fontSize: '20px', color: '#7446f9', marginRight: '10px', transform: 'scaleX(-1)'}} />}
                  style={{width: '420px', height: '72px', marginLeft: '0px', padding: '0px', border: '0px', fontSize: '17px'}}
                />
              </Col>
            </Row>
            <Row  type='flex' justify='center' style={{marginTop: '20px'}}>
              <Col>
                <Button
                  style={{width: '420px', height: '72px', color: '#ffffff', backgroundColor: '#7446f9', border: '0px', fontSize: '17px'}}
                  onClick={() => checkLogin()}> LOGIN </Button>
              </Col>
            </Row>

          </Col>
        </Row>
      </div>

    </>
  );

};
