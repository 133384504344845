import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_EDITOR_PATH,
        storageService
      } from 'common';

import { ManagerAdminEditorDialog } from '.';

export const ManagerAdminView = (props) => {

  const history = useHistory();

  const [adminList, setAdminList] = useState<any[]>([]);
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [goCurrentPage, setGoCurrentPage] = useState<number>(1);

  const [selectedUids, setSelectedUids] = useState<number[]>([]);
  const [searchCategory, setSearchCategory] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [searchStatus, setSearchStatus] = useState<number>(-1);

  const [selectedUserUid, setSelectedUserUid] = useState<number>(0);
  const [visibleAdminConfirm, setVisibleAdminConfirm] = useState<boolean>(false);


  useEffect(() => {

    $('#headerTitle').text('관리자');

    getAdminCount(searchStatus);

  }, []);



  const getAdminListData = (status, page, pageSize) => {

    console.log('page : ' + page + ' , pageSize : ' + pageSize);

    setCurrentPage(page);
    setGoCurrentPage(page);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/adminsByStatus.do?' ;
    searchUrl += 'page=' + (page - 1) + '&size=' + size;
    searchUrl += '&status=' + status;


    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setAdminList(Object.assign(data));
                  })
     .catch(err => console.log(err));

  };


  const getAdminCount = (status) => {

    setTotalCount(0);
    setAdminList([]);


    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/adminCountByStatus.do' ;
    searchUrl += '?status=' + status;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setTotalCount(data);

                      if(data > 0)
                        getAdminListData(status, 1, size);

                     })
     .catch(err => console.log(err));

  };

  const updateAdminList = (status) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/adminUpdateStatusByIds.do?' ;
    searchUrl += 'status=' + status;

    var ids = '';
    selectedUids.forEach(id => ids += id + ',');

    if(ids.length == 0)
      return;

    searchUrl += '&ids=' + ids.substring(0, ids.length - 1);

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setSelectedUids([]);
                      getAdminCount(searchStatus);
                     })
     .catch(err => console.log(err));
  };

  const deleteAdminList = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/adminDeleteByIds.do?' ;

    var ids = '';
    selectedUids.forEach(id => ids += id + ',');

    if(ids.length == 0)
      return;

    searchUrl += 'ids=' + ids.substring(0, ids.length - 1);


    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setSelectedUids([]);
                      getAdminCount(searchStatus);
                     })
     .catch(err => console.log(err));
  };


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }


  const columns = [

      {
        title: 'ID',
        dataIndex: 'userId',
        align: 'left' as 'left',
        render: function (text, record) {
          return (<span style={{fontSize: '16px', color: '#7446f9', cursor: 'pointer' }}
                        onClick={() => {setSelectedUserUid(record.userUid); setVisibleAdminConfirm(true);}}> {text} </span>);
        }
      },
      {
        title: 'Name',
        dataIndex: 'userName',
        align: 'left' as 'left',
        width: '14%',
        render: function (text, record) {
          return (<div style={{fontSize: '16px'}}> {text} </div>);
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: 'left' as 'left',
        width: '20%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {text} </div>);
        }
      },
      {
        title: 'Created At',
        dataIndex: 'createDate',
        align: 'left' as 'left',
        width: '20%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getDate(text)} </div>);
        }
      },
      {
        title: 'Update At',
        dataIndex: 'updateDate',
        align: 'left' as 'left',
        width: '20%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getDate(text)} </div>);
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left' as 'left',
        width: '10%',
        render: function (text) {
          return text ? (<span style={{color: '#50c900', fontSize: '16px' }}> 활성화 </span>) :  (<span style={{color: '#f9b446', fontSize: '16px' }}> 비활성화 </span>);
        }
      }
  ];

  const rowSelection = {
    selectedRowKeys: selectedUids,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('selectedRowKeys : ' + selectedRowKeys + ", selectedRows : " + selectedRows);
      setSelectedUids(selectedRowKeys);
    },

  }

  const { Option } = Select;
  const { Search } = Input;


  return (
    <>
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Row type='flex' justify='space-between' align='middle' style={{width: '100%'}} >

          <Col>
            <Button id='statusAllButton' style={{fontSize: '15px', width: '60px', height: '45px', backgroundColor: '#bda7fb', color: '#ffffff', fontWeight: 600}}
                    onClick={() => {setSearchStatus(-1);
                                    getAdminCount(-1);
                                    $('#statusAllButton').css('backgroundColor', '#bda7fb');
                                    $('#statusAllButton').css('color', '#ffffff');
                                    $('#statusActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusActiveButton').css('color', '#000000');
                                    $('#statusInActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusInActiveButton').css('color', '#000000');
                                  }} > ALL </Button>
            <Button id='statusActiveButton' style={{fontSize: '15px', width: '90px', height: '45px', fontWeight: 600}}
                    onClick={() => {setSearchStatus(1);
                                    getAdminCount(1);
                                    $('#statusAllButton').css('backgroundColor', '#ffffff');
                                    $('#statusAllButton').css('color', '#000000');
                                    $('#statusActiveButton').css('backgroundColor', '#bda7fb');
                                    $('#statusActiveButton').css('color', '#ffffff');
                                    $('#statusInActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusInActiveButton').css('color', '#000000');
                                  }}> Active </Button>
            <Button id='statusInActiveButton' style={{fontSize: '15px', width: '90px', height: '45px', fontWeight: 600}}
                    onClick={() => {setSearchStatus(0);
                                    getAdminCount(0);
                                    $('#statusAllButton').css('backgroundColor', '#ffffff');
                                    $('#statusAllButton').css('color', '#000000');
                                    $('#statusActiveButton').css('backgroundColor', '#ffffff');
                                    $('#statusActiveButton').css('color', '#000000');
                                    $('#statusInActiveButton').css('backgroundColor', '#bda7fb');
                                    $('#statusInActiveButton').css('color', '#ffffff');
                                  }}> InActive </Button>
            <Input placeholder='Search Filter' style={{display: 'none', width: '260px', height: '45px', margin: '0 10px'}} />
            <Input placeholder='Search Text' style={{display: 'none', width: '315px', height: '45px'}} />
          </Col>
          <Col>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', borderColor: '#f32357', backgroundColor: '#ffffff', color: '#f32357'}}
                    onClick={() => updateAdminList(1)}> To Active </Button>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', borderColor: '#f32357', backgroundColor: '#ffffff', color: '#f32357', marginLeft: '10px'}}
                    onClick={() => updateAdminList(0)}> To Inactive </Button>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', borderColor: '#f32357', backgroundColor: '#ffffff', color: '#f32357', marginLeft: '10px'}}
                    onClick={() => deleteAdminList()}> Delete </Button>
            <Button style={{fontSize: '15px', width: '120px', height: '45px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                    onClick={() => {setSelectedUserUid(0); setVisibleAdminConfirm(true);} }> Add Admin </Button>
          </Col>
        </Row>

        <Row type='flex' justify='center' style={{width: '100%', marginTop: '10px', border: 'solid 0.5px rgba(112, 112, 112, 0.2)'}}>
          <Col style={{width: '100%'}}>
            <Table
              rowKey='userUid'
              rowSelection={rowSelection}
              style={{ backgroundColor: 'rgba(255, 255, 255, 1)', color: '#FFFFFF' }}
              columns={columns}
              dataSource={adminList}
              pagination = {false}
            />
          </Col>
          <Col style={{width: '100%', height: '53px', backgroundColor: '#efeef0', padding: '16px'}}>
            total {totalCount}
          </Col>
        </Row>

        <Row type='flex' justify='center' align='middle' style={{width: '1272px'}}>
          <Pagination current={currentPage} total={totalCount} onChange={(page,size)=>getAdminListData(searchStatus, page,size)} style={{margin: '20px 0 20px 0'}}/>
          <Col>
            <span style={{margin: '0 10px'}}> 페이지 이동</span>
            <Input style={{width: '60px', height: '30px'}}
                   value={goCurrentPage}
                   onChange={e => setGoCurrentPage(Number(e.target.value))}
                   onKeyDown={e => {if(e.key == 'Enter') {setCurrentPage(goCurrentPage); getAdminListData(searchStatus, goCurrentPage, size);}}}/>
          </Col>
        </Row>

      </Row>

      <ManagerAdminEditorDialog
          userUid={selectedUserUid}
          visibleAdminConfirm={visibleAdminConfirm}
          onAdminConfirm={(value)=>{ if(value)
                                          getAdminListData(searchStatus, currentPage, size);
                                        setVisibleAdminConfirm(false);}}
        />

    </>
  );

};
