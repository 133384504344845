import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        storageService
      } from 'common';

import { ManagerMemberEditorDialog } from '.';

export const ManagerMemberView = (props) => {

  const history = useHistory();

  const [memberList, setMemberList] = useState<any[]>([]);
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [goCurrentPage, setGoCurrentPage] = useState<number>(1);

  const [selectedUserUid, setSelectedUserUid] = useState<number>(0);
  const [visibleMemberConfirm, setVisibleMemberConfirm] = useState<boolean>(false);


  useEffect(() => {

    $('#headerTitle').text('회원');

    getMemberCount();

  }, []);



  const getMemberListData = (page, pageSize) => {

    console.log('page : ' + page + ' , pageSize : ' + pageSize);

    setCurrentPage(page);
    setGoCurrentPage(page);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/members.do?' ;
    searchUrl += 'page=' + (page - 1) + '&size=' + size;


    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setMemberList(Object.assign(data));
                  })
     .catch(err => console.log(err));

  };


  const getMemberCount = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/memberCount.do' ;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setTotalCount(data);

                      if(data > 0)
                        getMemberListData(1, size);

                     })
     .catch(err => console.log(err));

  };

  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }


  const columns = [

      {
        title: 'NickName',
        dataIndex: 'userNickname',
        align: 'center' as 'center',
        width: '15%',
        render: function (text, record) {
          return (<div> {text} </div>);
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: 'left' as 'left',
        width: '25%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {text} </div>);
        }
      },
      {
        title: 'Created At',
        dataIndex: 'createDate',
        align: 'left' as 'left',
        width: '25%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getDate(text)} </div>);
        }
      },
      {
        title: 'Update At',
        dataIndex: 'updateDate',
        align: 'left' as 'left',
        width: '25%',
        render: function (text) {
          return (<div style={{fontSize: '16px' }}> {getDate(text)} </div>);
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left' as 'left',
        width: '10%',
        render: function (text) {
          return text ? (<span style={{color: '#50c900', fontSize: '16px' }}> 활성화 </span>) :  (<span style={{color: '#f9b446', fontSize: '16px' }}> 비활성화 </span>);
        }
      }
  ];



  const { Option } = Select;
  const { Search } = Input;


  return (
    <>
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Row type='flex' justify='space-between' align='middle' style={{width: '100%'}} >

          <Col>
            <Button style={{fontSize: '15px', width: '60px', height: '45px', backgroundColor: '#bda7fb', color: '#ffffff', fontWeight: 600}}> ALL </Button>
            <Button style={{fontSize: '15px', width: '90px', height: '45px', fontWeight: 600}}> Active </Button>
            <Button style={{fontSize: '15px', width: '90px', height: '45px', fontWeight: 600}}> InActive </Button>
            <Input placeholder='Search Filter' style={{display: 'none', width: '260px', height: '45px', margin: '0 10px'}} />
            <Input placeholder='Search Text' style={{display: 'none', width: '315px', height: '45px'}} />
          </Col>
          <Col>
          </Col>
        </Row>

        <Row type='flex' justify='center' style={{width: '100%', marginTop: '10px', border: 'solid 0.5px rgba(112, 112, 112, 0.2)'}}>
          <Col style={{width: '100%'}}>
            <Table
              rowKey='userId'
              style={{ backgroundColor: 'rgba(255, 255, 255, 1)', color: '#FFFFFF' }}
              columns={columns}
              dataSource={memberList}
              pagination = {false}
            />
          </Col>
          <Col style={{width: '100%', height: '53px', backgroundColor: '#efeef0', padding: '16px'}}>
            total {totalCount}
          </Col>
        </Row>

        <Row type='flex' justify='center' align='middle' style={{width: '1272px'}}>
          <Pagination current={currentPage} total={totalCount} onChange={getMemberListData} style={{margin: '20px 0 20px 0'}}/>
          <Col>
            <span style={{margin: '0 10px'}}> 페이지 이동</span>
            <Input style={{width: '60px', height: '30px'}}
                   value={goCurrentPage}
                   onChange={e => setGoCurrentPage(Number(e.target.value))}
                   onKeyDown={e => {if(e.key == 'Enter') {setCurrentPage(goCurrentPage); getMemberListData(goCurrentPage, size);}}}/>
          </Col>
        </Row>

      </Row>

      <ManagerMemberEditorDialog
          userUid={selectedUserUid}
          visibleMemberConfirm={visibleMemberConfirm}
          onMemberConfirm={(value)=>{ if(value)
                                          getMemberListData(currentPage, size);
                                        setVisibleMemberConfirm(false);}}
        />

    </>
  );

};
