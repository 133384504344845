import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
//import { GoogleLogin } from 'react-google-login';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MOBILE_HOME_PATH,
        MOBILE_MAP_EVENT_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';


export const MobileLoginView = (props) => {

  const history = useHistory();

  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  //const [voices, setVoices] = useState<any[]>([]);

  var voices : any[] = [];


  useEffect(() => {

    $('#MainHeader').hide();
    $('#Sidebar').hide();
    //$('#LoginPanel').css('background-image', 'url("images/anyeventmime/background/anyeventmime_login_background.png")')

    //$('#MainLayout').css('max-width', '430px');
    //$('#MainLayout').css('max-height', '960px');


/*
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }



    setVoiceList();
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = setVoiceList;
    }

    document.addEventListener("click", function(e) { speech('');});

*/

  }, []);

  const setVoiceList = () => {
    //setVoices(window.speechSynthesis.getVoices());
    voices = window.speechSynthesis.getVoices();
    //console.log('voices : ' + voices);
  }

  const speech = (txt) => {
    if(!window.speechSynthesis) {
      alert("음성 재생을 지원하지 않는 브라우저입니다. 크롬, 파이어폭스 등의 최신 브라우저를 이용하세요");
      return;
    }

    var utterThis = new SpeechSynthesisUtterance('Hello, How are you?');

    utterThis.onend = function (event) {
      console.log('end');
    };
    utterThis.onerror = function(event) {
      console.log('error', event);
    };

    var lang = 'en-US';
    var lang2 = 'en_US';

    var voiceFound = false;


    var voicelist = '';

    voices.forEach(voice => {
      console.log(voice.lang + '\n');
      voicelist += voice.lang + ',';

      if(voice.lang == lang || voice.lang == lang2) {
        utterThis.voice = voice;
        voiceFound = true;
      }
    });

    if(!voiceFound) {
      alert('voice not found : voicelist = ' + voicelist);
      return;
    }

    utterThis.lang = lang;
    utterThis.pitch = 1;
    utterThis.rate = 1;  //속도
    window.speechSynthesis.speak(utterThis);

  }



  const success = (position)  => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    var textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
    alert(textContent);
  }

  const error = () => {
    alert("Unable to retrieve your location");
  }

  const checkLogin = () => {

    if(userId == 'engtech' && password == 'engtech')
      history.push(MOBILE_HOME_PATH);

    let loginParam = {userId: userId, password: password}
    let url = SOPOONG_URL + ANYEVENTMIME_URL + '/login.do';

    fetch( url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(loginParam)
    } )
      .then(response => response.json())
      .then(data => {
                      console.log(data);
                      if(data.userId == null) {
                        console.log('로그인 실패');
                        InfoModal('warning', '알림', '아이디와 비밀번호가 일치하지 않습니다."')
                        storageService.removeItem('anyeventmime-user');
                      }
                      else {

                        data.loginTime = (new Date()).getTime();
                        storageService.setItem('anyeventmime-user', JSON.stringify(data));

                        $('#MainHeader').show();
                        $('#Sidebar').show();

                        history.push(MOBILE_MAP_EVENT_PATH);
                      }

                    })
      .catch(err => console.log(err));
  }

  const checkGoogleLogin = (accessToken) => {


    let loginParam = {accessToken: accessToken}
    let url = SOPOONG_URL + ANYEVENTMIME_URL + '/googlelogin.do';

    fetch( url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(loginParam)
    } )
      .then(response => response.json())
      .then(data => {
                      console.log(data);
                      if(data.userUid == null) {
                        console.log('로그인 실패');
                        InfoModal('warning', '알림', '아이디와 비밀번호가 일치하지 않습니다."')
                        storageService.removeItem('anyeventmime-user');
                      }
                      else {

                        data.loginTime = (new Date()).getTime();
                        storageService.setItem('anyeventmime-user', JSON.stringify(data));
                        
                        var menu = {menu: 'festival'}
                        storageService.setItem('anyeventmime-menu', JSON.stringify(menu));

                        $('#MainHeader').show();
                        $('#Sidebar').show();

                        history.push(MOBILE_MAP_EVENT_PATH);
                      }

                    })
      .catch(err => console.log(err));
  }


  //<img src='images/anyeventmime/background/button_google_login.png' style={{width: '100%'}} onClick={() => history.push(MOBILE_HOME_PATH)}/>
  //<img src='images/anyeventmime/background/button_google_login.png' onClick={renderProps.onClick} style={{width: '100%'}} />

/*
  const googleLogin = () => {

    var url = 'https://accounts.google.com/o/oauth2/v2/auth';
    url += '?client_id=972871285383-brmtqtp390e04ve0ssoargk6383sih7t.apps.googleusercontent.com&redirect_uri=http://localhost/anyeventmime/glogin.do';
    url += '&response_type=code&scope=email';

    location.href = url;




  }

*/
  const testLogin = () => {

    var data = {userUid: 2}
    storageService.setItem('anyeventmime-user', JSON.stringify(data));

    $('#MainHeader').show();
    $('#Sidebar').show();

    history.push(MOBILE_MAP_EVENT_PATH);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse);
      checkGoogleLogin(tokenResponse.access_token);

    },
  });

  return (
    <>

      <div id='LoginPanel' className='login-container' style={{backgroundColor:'#FFCC01'}}>
        <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100vh'}}>
          <Col span={24}>
            <Row type='flex' justify='center' align='middle' style={{width: '100%'}}>
              <img src='images/anyeventmime/background/anyeventmime_login_background.png' style={{width: '100%', maxWidth: 480 }} />
            </Row>
            <Row type='flex' justify='center' align='middle' style={{position: 'fixed', width: '100%', left: 0, bottom: 0, marginBottom: 78}}>
              <Col span={12}>
                <Row type='flex' justify='center' align='middle' style={{width: '100%'}}>
                  <img src='images/anyeventmime/background/button_google_login.png' style={{width: '100%', maxWidth: 240}} onClick={() => {console.log('click googlelogin'); googleLogin();}}/>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>

    </>
  );


/*
  return (
    <>

      <div id='LoginPanel' className='login-container' style={{backgroundColor:'#FFCC01'}}>
        <Row type='flex' justify='center' align='middle' style={{height: '100vh'}}>
          <Col span={24}>
            <Row type='flex' justify='center' align='middle' style={{width: '100%'}}>
              <img src='images/anyeventmime/background/anyeventmime_login_background.png' style={{width: '100%'}} />
            </Row>
            <Row type='flex' justify='center' align='middle' style={{width: '100%', marginTop: -78, marginBottom: 78}}>
              <Col span={12}>
                <img src='images/anyeventmime/background/button_google_login.png' style={{width: '100%'}} onClick={() => googleLogin()}/>
              </Col>

            </Row>
          </Col>
        </Row>

      </div>

    </>
  );
*/

};
