import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MOBILE_LOGIN_PATH,
        storageService
      } from 'common';

import { MobileMenuDrawer, MobileFestivalDialog, MobileSpotDetailDialog, MobileSentenceDetailDialog } from '.';

export const MobileMypageView = (props) => {

  const history = useHistory();

  const [contentType, setContentType] = useState<string>('member');

  const [userUid, setUserUid] = useState<number>(0);
  const [userId, setUserId] = useState<string>('');
  const [createDate, setCreateDate] = useState<string>('');

  const [sentenceList, setSentenceList] = useState<any[]>([]);
  const [spotList, setSpotList] = useState<any[]>([]);
  const [size, setSize] = useState<number>(4);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [goCurrentPage, setGoCurrentPage] = useState<number>(1);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedSpot, setSelectedSpot] = useState<any>({});
  const [visibleSpotConfirm, setVisibleSpotConfirm] = useState<boolean>(false);

  const [selectedSentence, setSelectedSentence] = useState<any>({});
  const [visibleSentenceConfirm, setVisibleSentenceConfirm] = useState<boolean>(false);

  const [visibleMenuConfirm, setVisibleMenuConfirm] = useState<boolean>(false);
  const [visibleFestivalConfirm, setVisibleFestivalConfirm] = useState<boolean>(false);

  const [voices, setVoices] = useState<any[]>([]);


  useEffect(() => {


    $('#MainHeader').hide();
    $('#Sidebar').hide();

    if (storageService.getItem('anyeventmime-user')) {

        var user = JSON.parse( storageService.getItem('anyeventmime-user') as string );

        if(user != undefined) {
          setUserUid(user.userUid);

          getUserData(user.userUid);
        }
    }

    if(window.speechSynthesis) {
      setVoiceList();
      if (window.speechSynthesis.onvoiceschanged !== undefined) {
        window.speechSynthesis.onvoiceschanged = setVoiceList;
      }
    }
    else {
      alert("음성 재생을 지원하지 않는 브라우저입니다. 크롬, 파이어폭스 등의 최신 브라우저를 이용하세요");
    }

  }, []);

  const setVoiceList = () => {
    //setVoices(window.speechSynthesis.getVoices());
    var widownVoices = window.speechSynthesis.getVoices();
    setVoices(widownVoices);
    console.log('widownVoices : ' + widownVoices);
  }

  const getUserData = (userUid) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/user.do?' ;
    searchUrl += 'userUid=' + userUid;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setUserId(data.userId);
                    setCreateDate(data.createDate);
                  })
     .catch(err => console.log(err));

  };


  const getSentenceListData = (page, pageSize, userUid) => {

    console.log('page : ' + page + ' , pageSize : ' + pageSize);

    setCurrentPage(page);
    setGoCurrentPage(page);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentencesByBookmark.do?' ;
    searchUrl += 'page=' + (page - 1) + '&size=' + size;
    searchUrl += '&userUid=' + userUid;
    searchUrl += '&bookmarkType=3';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setSentenceList(Object.assign(data));
                  })
     .catch(err => console.log(err));

  };

  const getSentenceDataListContinue = (page, size) => {

    console.log('page : ' + page + ' , pageSize : ' + size);
    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentencesByBookmark.do?';
    searchUrl += 'page=' + (page- 1) + '&size=' + size;
    searchUrl += '&userUid=' + userUid;
    searchUrl += '&bookmarkType=3';


    fetch(
      searchUrl
    ).then(res => res.json())
     .then(data => {
                      console.log(data)
                      setSentenceList(sentenceList.concat(Object.assign(data)));
                      setCurrentPage(currentPage + 1);
                   })
     .catch(function(t) {
        return console.log(t)
    })
  }


  const getSentenceCount = (userUid) => {

    setCurrentPage(1);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentenceCountByBookmark.do?' ;
    searchUrl += 'userUid=' + userUid;
    searchUrl += '&bookmarkType=3';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setTotalCount(data);

                      if(data > 0)
                        getSentenceListData(1, size, userUid);

                     })
     .catch(err => console.log(err));

  };



  const getSpotListData = (page, pageSize, userUid) => {

    console.log('page : ' + page + ' , pageSize : ' + pageSize);

    setCurrentPage(page);
    setGoCurrentPage(page);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/spotsByBookmark.do?' ;
    searchUrl += 'page=' + (page - 1) + '&size=' + size;
    searchUrl += '&userUid=' + userUid;
    searchUrl += '&bookmarkType=1';


    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);
                    setSpotList(Object.assign(data));
                  })
     .catch(err => console.log(err));

  };

  const getSpotDataListContinue = (page, size) => {

    console.log('page : ' + page + ' , pageSize : ' + size);
    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/spotsByBookmark.do?';
    searchUrl += 'page=' + (page- 1) + '&size=' + size;
    searchUrl += '&userUid=' + userUid;
    searchUrl += '&bookmarkType=1';

    fetch(
      searchUrl
    ).then(res => res.json())
     .then(data => {
                      console.log(data)
                      setSpotList(spotList.concat(Object.assign(data)));
                      setCurrentPage(currentPage + 1);
                   })
     .catch(function(t) {
        return console.log(t)
    })
  }


  const getSpotCount = (userUid) => {

    setCurrentPage(1);

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/spotCountByBookmark.do?' ;
    searchUrl += 'userUid=' + userUid;
    searchUrl += '&bookmarkType=1';

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                      console.log(data);
                      setTotalCount(data);

                      if(data > 0)
                        getSpotListData(1, size, userUid);

                     })
     .catch(err => console.log(err));

  };


  const deleteSentenceBookmark = (index, sentence) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/bookmarkDelete.do' ;

    var params = {
                  bookmarkId: sentence.bookmarkId
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      console.log(data);

                      var tempList = [...sentenceList];
                      tempList.splice(index, 1);
                      setSentenceList(tempList);

                      setSelectedSentence({});

                    })
      .catch(err => console.log(err));

  };


  const deleteSpotBookmark = (index, spot) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/bookmarkDelete.do' ;

    var params = {
                  bookmarkId: spot.bookmarkId
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      console.log(data);

                      var tempList = [...spotList];
                      tempList.splice(index, 1);
                      setSpotList(tempList);

                      setSelectedSpot({});

                    })
      .catch(err => console.log(err));

  };


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }


  const selectContentMenu = (item) => {

    setContentType(item);

    setSentenceList([]);
    setSpotList([]);

    $('#span-member').css('color', '#FFFFFF');
    $('#span-bookmark').css('color', '#FFFFFF');
    $('#span-spot').css('color', '#FFFFFF');

    switch(item) {
      case 'member' : $('#span-member').css('color', '#FFCC01'); getUserData(userUid); break;
      case 'bookmark-sentence' : $('#span-bookmark').css('color', '#FFCC01'); getSentenceCount(userUid); break;
      case 'bookmark-spot' : $('#span-spot').css('color', '#FFCC01'); getSpotCount(userUid); break;
    }

  }

  const getCategoryName = (category) => {

    var categoryName = ''
    switch(category) {
      case 'type02' : categoryName = 'Activity'; break;
      case 'type03' : categoryName = 'Sightseeing'; break;
      case 'type04' : categoryName = 'Shopping'; break;
      case 'type05' : categoryName = 'Food'; break;
      case 'type06' : categoryName = 'Accomodation'; break;
      case 'type07' : categoryName = 'Emergency'; break;
      case 'type08' : categoryName = 'Transit'; break;

    }

    return categoryName;
  }



  const { Option } = Select;
  const { Search } = Input;


  const getContentLayout = (item) => {

    let layout;

    switch(item) {
      case 'member' : layout = getMemberInfoLayout(); break;
      case 'bookmark-sentence' : layout = getBookmarkSentenceLayout(); break;
      case 'bookmark-spot' : layout = getBookmarkSpotLayout(); break;
    }

    return layout;
  }

  const getMemberInfoLayout = () => {

    return (
      <Row type='flex' justify='center' align='middle' style={{margin: '80px 30px 0 30px', backgroundColor: '#FFFFFF', borderRadius: 10}} >

        <Row type='flex' justify='center' align='middle' style={{width: '100%', margin: '60px 0 40px 0' }}>
          <Col span={24} >

            <Row type='flex' justify='start' style={{width: '100%', color: '#002581'}}>
              <Col offset={5}>
                <span> ID : </span>
                <span> {userId} </span>
              </Col>
            </Row>
            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, color: '#002581'}}>
              <Col offset={5}>
                <span> 가입일자 :</span>
                <span> {createDate.substring(0, 10)} </span>
              </Col>
            </Row>

          </Col>
        </Row>

        <Row style={{width: '90%'}}>
          <Divider/>
        </Row>

        <Row type='flex' justify='space-around' align='middle' style={{width: '100%', margin: '0 30px 30px 30px'}}>
          <Row type='flex' justify='center' align='middle' style={{width: '40%', height:30, background: '#002581' , color: '#ffffff', fontWeight: 'bold'}} onClick={() => history.push(MOBILE_LOGIN_PATH)}>
            로그아웃
          </Row>
          <Row type='flex' justify='center' align='middle' style={{width: '40%', height:30, background: '#002581' , color: '#ffffff', fontWeight: 'bold'}} onClick={() => history.push(MOBILE_LOGIN_PATH)}>
            탈퇴
          </Row>
        </Row>


      </Row>
    );
  }


  const getBookmarkSentenceLayout = () => {

    return (
      <Row  type='flex' justify='center' style={{marginTop: 20}}>

        <Row type='flex' justify='center' style={{width: '100%'}}>
          {sentenceList ? (
            sentenceList.map( (sentence, index) => (
              <Row key={sentence.sentenceId} type='flex' justify='start' align='middle' style={{width: '100%', margin: '5px 30px', padding: 10, backgroundColor: '#FFFFFF', borderRadius: 10}} >

                <Col span={22} onClick={() => {setSelectedIndex(index); setSelectedSentence(sentence); setVisibleSentenceConfirm(true);}} >

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    {sentence.englishSentence}
                  </Row>
                  <Row type='flex' justify='start' style={{width: '100%'}}>
                    {sentence.description}
                  </Row>
                  <Row type='flex' justify='start' style={{width: '100%'}}>
                    {sentence.koreanSentence}
                  </Row>
                </Col>
                <Col span={2}>
                  <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%'}}>

                    <Row type='flex' justify='center' align='middle'
                          style={{width: 24, height: 24, background: '#002581', borderRadius: 15}}
                          onClick={() => {setSelectedIndex(index); deleteSentenceBookmark(index, sentence);}}>
                      <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
                    </Row>

                  </Row>
                </Col>
              </Row>
            ))
          ) : (
            <Row />
          )}
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='center' align='middle'
                 style={{margin: '20px 30px 80px 30px', paddingLeft: 30, paddingRight: 30, height: 40, border: 'solid 0.5px #002581'}}
                 onClick={() => {if(sentenceList.length < totalCount) getSentenceDataListContinue(currentPage, size); }}>
                <span style={{color: '#002581', fontWeight: 'bold'}}> + 더보기 </span>
            </Row>
          </Row>
        </Row>
      </Row>
    );
  }

  const getBookmarkSpotLayout = () => {

    return (
      <Row  type='flex' justify='center' style={{marginTop: 20}}>

        <Row type='flex' justify='center' style={{width: '100%'}}>
          {spotList ? (
            spotList.map( (spot, index) => (
              <Row key={spot.spotId} type='flex' justify='start' align='middle' style={{width: '100%', margin: '5px 30px', padding: 10, backgroundColor: '#FFFFFF', borderRadius: 10}}>

                <Col span={24} >

                  <Row type='flex' justify='space-between' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    <Col>
                      유형 : {getCategoryName(spot.category)}
                    </Col>
                    <Col>
                      전화번호 : {spot.contact}
                    </Col>
                  </Row>
                  <hr />
                  <Row type='flex' justify='space-between' style={{width: '100%'}}>
                    <Col span={22} >
                      <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                        이름 : {spot.name}
                      </Row>
                      <Row type='flex' justify='start' style={{width: '100%'}}>
                        주소 : {spot.address}
                      </Row>
                    </Col>
                    <Col span={2}>
                      <Row type='flex' justify='center' align='middle' style={{width: '100%', height: '100%'}}>
                        <Row type='flex' justify='center' align='middle'
                              style={{width: 24, height: 24, background: '#002581', borderRadius: 15}}
                              onClick={() => {setSelectedIndex(index); deleteSpotBookmark(index, spot);}}>
                          <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                  {
                    (spot.linkUrl != undefined && spot.linkUrl != '') ? (
                      <Row type='flex' justify='center' align='middle' style={{width: '100%', padding: '10px'}} >
                        <Row type='flex' justify='center' align='middle'
                              style={{width: 100, height: 30, fontSize: 12, fontWeight: 600, color: '#002581', borderRadius: 15, border: '1px solid #002581'}}
                              onClick={() => {window.open(spot.linkUrl)}}> 자세히보기 </Row>
                      </Row>
                    ) : (<Row />)
                  }
                </Col>

              </Row>
            ))
          ) : (
            <Row />
          )}
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='center' align='middle'
                 style={{margin: '20px 30px 80px 30px', paddingLeft: 30, paddingRight: 30, height: 40, border: 'solid 0.5px #002581'}}
                 onClick={() => {if(sentenceList.length < totalCount) getSentenceDataListContinue(currentPage, size); }}>
                <span style={{color: '#002581', fontWeight: 'bold'}}> + 더보기 </span>
            </Row>
          </Row>
        </Row>
      </Row>
    );
  }


  return (
    <>
      <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 60,  paddingLeft: 10, paddingRight: 10, backgroundColor: '#FFCC01', borderBottom: 'solid 0.5px rgba(112, 112, 112, 0.2)'}}>
        <img src='images/anyeventmime/background/icon_mobile_menu.png'  style={{height:44}} onClick={() => setVisibleMenuConfirm(true)}/>
        <img src='images/anyeventmime/background/button_minifestival_content.png'  style={{height:32}} onClick={() => setVisibleFestivalConfirm(true)}/>
      </Row>

      <Row type='flex' justify='space-around' align='middle' style={{width: '100%', height: 40, paddingLeft: 10, paddingRight: 20, backgroundColor: '#00247F', borderRadius: 0}}>
        <Col><span id='span-member' style={{color: '#FFCC01', fontWeight: 600}} onClick={() => selectContentMenu('member')}> 회원정보 </span></Col>
        <Col><span id='span-bookmark' style={{color: '#ffffff', fontWeight: 600}} onClick={() => selectContentMenu('bookmark-sentence')}> Sentence </span></Col>
        <Col><span id='span-spot' style={{color: '#ffffff', fontWeight: 600}} onClick={() => selectContentMenu('bookmark-spot')}> Spot </span></Col>
      </Row>


      <Row className='mypage-content' style={{width: '100%', height: '85vh', paddingTop: 10, overflowY: 'scroll', backgroundColor: '#FFCC01'}}>
        {getContentLayout(contentType)}
      </Row>



      <MobileSpotDetailDialog
          userUid={userUid}
          spot={selectedSpot}
          visibleSpotConfirm={visibleSpotConfirm}
          onVisibleSpotConfirm={(value)=>{ setVisibleSpotConfirm(value);}}
          onCreateBookmark={(value) => {}}
          onDeleteBookmark={(value) => {setVisibleSpotConfirm(false); deleteSpotBookmark(selectedIndex, value);}}
        />

      <MobileSentenceDetailDialog
          sentence={selectedSentence}
          voices={voices}
          visibleSentenceConfirm={visibleSentenceConfirm}
          onVisibleSentenceConfirm={(value) => setVisibleSentenceConfirm(value)}
          onCreateBookmark={(value) => {}}
          onDeleteBookmark={(value) => {setVisibleSentenceConfirm(false);deleteSentenceBookmark(selectedIndex, value);}}
        />


      <MobileMenuDrawer
        visibleMenuConfirm = {visibleMenuConfirm}
        onVisibleMenuConfirm={(value)=>setVisibleMenuConfirm(false)}
      />

      <MobileFestivalDialog
        visibleFestivalConfirm = {visibleFestivalConfirm}
        onVisibleFestivalConfirm={(value)=>setVisibleFestivalConfirm(false)}
      />

    </>
  );

};
