import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_WORD_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const MobileSpotDetailDialog = (props) => {

  const { userUid, spot, visibleSpotConfirm, onVisibleSpotConfirm, onCreateBookmark, onDeleteBookmark  } = props;

  const history = useHistory();


  useEffect(() => {

  }, []);


  const getCategoryName = (category) => {

    var categoryName = ''
    switch(category) {
      case 'type02' : categoryName = 'Activity'; break;
      case 'type03' : categoryName = 'Sightseeing'; break;
      case 'type04' : categoryName = 'Shopping'; break;
      case 'type05' : categoryName = 'Food'; break;
      case 'type06' : categoryName = 'Accomodation'; break;
      case 'type07' : categoryName = 'Emergency'; break;
      case 'type08' : categoryName = 'Transit'; break;
    }

    return categoryName;
  }


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  return (
    <Modal
        className='popup-content'
         visible={visibleSpotConfirm}
         closable={false}
         title=""
         width={'100vw'}
         style={{ top: 0, height: '100vh' }}
         footer={null}
         >
         <Row className='word-content' style={{height: '100vh', overflowY: 'scroll', backgroundColor: '#FFCC01'}}>

           <Row type='flex' justify='center' style={{marginTop: 50}}>
             <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 40, margin: '10px 30px', paddingLeft: 20, paddingRight: 20, backgroundColor: '#FFFFFF', borderRadius: 10}}>
               <Col>
                <span > <Icon type="check"  style={{color: '#FDB813', fontSize: 18, fontWeight: 800}}/> </span>
                <span style={{marginLeft: 10, color: '#002480', fontWeight: 600}}> {getCategoryName(spot.category)} </span>
               </Col>
               <Col>
                 {
                   (spot.bookmarkId > 0) ? (
                     <Row type='flex' justify='center' align='middle' style={{width: 20, height: 20, background: '#002581', borderRadius: 15}} onClick={() => onDeleteBookmark(spot)}>
                       <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
                     </Row>
                   ) : (
                     <Row type='flex' justify='center' align='middle' style={{width: 20, height: 20, background: '#B8B8B8', borderRadius: 15}} onClick={() => onCreateBookmark(spot)}>
                       <Icon type="book" theme="filled" style={{fontSize: 12, color: '#ffffff'}} />
                     </Row>
                   )
                 }
               </Col>
             </Row>
           </Row>

           <Row type='flex' justify='center' style={{marginTop: 0}}>

             <Row type='flex' justify='start' align='middle' style={{width: '100%', margin: '5px 30px', padding: 20, backgroundColor: '#FFFFFF', borderRadius: 10}} >

               <Col span={24} >
                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    유형
                  </Row>
                  <Row type='flex' justify='start'>
                    {getCategoryName(spot.category)}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    이름
                  </Row>
                  <Row type='flex' justify='start'>
                    <span>{spot.name} </span>
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    주소
                  </Row>
                  <Row type='flex' justify='start'>
                    {spot.address}
                  </Row>

                  <Divider />

                  <Row type='flex' justify='start' style={{width: '100%', color: '#002581', fontWeight: 'bold'}}>
                    전화번호
                  </Row>
                  <Row type='flex' justify='start'>
                    {spot.contact}
                  </Row>

                  {(spot.linkUrl != undefined && spot.linkUrl != '') ? (
                    <Row type='flex'  >
                      <Divider />

                      <Row type='flex' justify='center' align='middle' style={{width: '100%', padding: '10px'}} >
                        <Row type='flex' justify='center' align='middle'
                              style={{width: 100, height: 30, fontSize: 12, fontWeight: 600, color: '#002581', borderRadius: 15, border: '1px solid #002581'}}
                              onClick={() => {window.open(spot.linkUrl)}}> 자세히보기 </Row>
                      </Row>
                    </Row>
                  ) : <Row /> }

               </Col>
             </Row>

             <Row style={{width: '100%'}}>
               <Row type='flex' justify='center' align='middle'
                    style={{margin: '20px 30px 40px 30px', paddingLeft: 30, paddingRight: 30, height: 40, border: 'solid 0.5px #002581'}}
                    onClick={() => onVisibleSpotConfirm(false)}>
                   <span style={{color: '#002581', fontWeight: 'bold'}}> 목록 </span>
               </Row>
             </Row>
           </Row>
         </Row>

    </Modal>
  );

};
