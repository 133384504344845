import React, { useState, useEffect, CSSProperties } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Typography, Button, Divider, Icon, Menu, Dropdown, Anchor, Switch} from 'antd';
import $ from 'jquery';

import { MANAGER_ADMIN_PATH,
  MANAGER_MEMBER_PATH,
  MANAGER_EVENT_PATH,
  MANAGER_SPOT_PATH,
  MANAGER_SENTENCE_PATH,
  MANAGER_WORD_PATH,
  MANAGER_VIDEO_PATH,
  storageService} from 'common';

import queryString from 'query-string';

const { SubMenu } = Menu;
/**
 * @description NomalLayout에서 사용하는 메인 헤더 컴포넌트
 */
export const MainSidebar = (props) => {

  const history = useHistory();

  const [state, setState] = useState<any>({collapsed: false});
  const [menuKeys, setMenuKeys] = useState<string[]>(['']);
  const [height, setHeight] = useState<number>(900);


  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {

    console.log('currentTime : ' + (new Date()).getTime() );

    if (storageService.getItem('anyeventmime-user') != undefined) {

      var user = JSON.parse( storageService.getItem('anyeventmime-user') as string );

      if(user != undefined) {

        var currentTime = (new Date()).getTime();
/*
        if(currentTime - user.loginTime > 3600000) {
          storageService.removeItem('groad-user');
          return;
        }
*/
      }
    }

    onWindowResize();
    window.addEventListener( 'resize',onWindowResize, false );


  }, [props]);



  const onWindowResize = () => {

    //console.log("document.body.clientWidth : " + document.body.clientWidth + ", document.body.clientHeight : " + document.body.clientHeight );

    var height = document.body.clientHeight - 80;

    setHeight(height);
  }

  const toggleCollapsed = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  const onCollapse = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  const handleClick = (e) => {
    console.log('click ', e);
  };


  const getManagerSidebar = () => {

    return (
      <Menu
        onClick={handleClick}
        defaultSelectedKeys={menuKeys}
        theme='dark'
        style={{backgroundColor: '#7446f9'}}
        mode={'inline'}
      >
        <Menu.Item key="manager" onClick={()=>{ return history.push(MANAGER_ADMIN_PATH); }}>
          <Row type='flex' justify='space-between' align='middle' style={{margin: '0px 8px 0px 0px', padding: '0 20px'}}>
            <span className="sidebar-menu-item">관리자 관리</span>
          </Row>
        </Menu.Item>
        <Menu.Item key="member" onClick={()=>{ return history.push(MANAGER_MEMBER_PATH); }}>
          <Row type='flex' justify='space-between' align='middle' style={{margin: '0px 8px 0px 0px', padding: '0 20px'}}>
            <span className="sidebar-menu-item">회원관리</span>
          </Row>
        </Menu.Item>
        <Menu.Item key="event" onClick={()=>{ return history.push(MANAGER_EVENT_PATH); }}>
          <Row type='flex' justify='space-between' align='middle' style={{margin: '0px 8px 0px 0px', padding: '0 20px'}}>
            <span className="sidebar-menu-item">이벤트관리</span>
          </Row>
        </Menu.Item>
        <Menu.Item key="spot" onClick={()=>{ return history.push(MANAGER_SPOT_PATH); }}>
          <Row type='flex' justify='space-between' align='middle' style={{margin: '0px 8px 0px 0px', padding: '0 20px'}}>
            <span className="sidebar-menu-item">관광지관리</span>
          </Row>
        </Menu.Item>
        <Menu.Item key="sentence" onClick={()=>{ return history.push(MANAGER_SENTENCE_PATH); }}>
          <Row type='flex' justify='space-between' align='middle' style={{margin: '0px 8px 0px 0px', padding: '0 20px'}}>
            <span className="sidebar-menu-item">문장관리</span>
          </Row>
        </Menu.Item>
        <Menu.Item key="word" onClick={()=>{ return history.push(MANAGER_WORD_PATH); }}>
          <Row type='flex' justify='space-between' align='middle' style={{margin: '0px 8px 0px 0px', padding: '0 20px'}}>
            <span className="sidebar-menu-item">단어관리</span>
          </Row>
        </Menu.Item>
      </Menu>
    );

  }

  const { Header, Footer, Sider, Content } = Layout;

  return (

    <Sider id='Sidebar' trigger={null} collapsible collapsed={props.collapsed} width={280} style={{backgroundColor: '#7446f9', minHeight: '100vh', borderRadius: '0px 50px 0px 0px'}}>
      <Row style={{height: '100%'}}>

        <Row type='flex' justify='center' align='middle' style={{marginTop: '55px'}}>
          <Col>
            <img src='images/anyeventmime/background/anyeventmime_logo_icon.png' style={{width: '170px', height: '67px'}} />
          </Col>
        </Row>
        <Row style={{marginTop: '70px'}}>

          { getManagerSidebar() }

        </Row>
      </Row>

    </Sider>


  );
};
