import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import {SOPOONG_URL, ANYEVENTMIME_URL,
        MOBILE_LOGIN_PATH,
        MOBILE_HOME_PATH,
        MOBILE_FESTIVAL_PATH,
        MOBILE_SCHEDULE_PATH,
        MOBILE_MAP_EVENT_PATH,
        MOBILE_MAP_SPOT_PATH,
        MOBILE_EVENT_PATH,
        MOBILE_WORD_PATH,
        MOBILE_SENTENCE_PATH,
        MOBILE_MYPAGE_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';
import { MobileCanvasDialog } from '.';

export const MobileMenuDrawer = (props) => {

  const history = useHistory();

  const {visibleMenuConfirm, onVisibleMenuConfirm} = props;

  const [menuFestivalIcon, setMenuFestivalIcon] = useState<string>('images/anyeventmime/background/icon_menu_festival_off.png');
  const [menuSpotIcon, setMenuSpotIcon] = useState<string>('images/anyeventmime/background/icon_menu_spot_off.png');
  const [menuWordIcon, setMenuWordIcon] = useState<string>('images/anyeventmime/background/icon_menu_word_off.png');
  const [menuSentenceIcon, setMenuSentenceIcon] = useState<string>('images/anyeventmime/background/icon_menu_sentence_off.png');
  const [menuMypageIcon, setMenuMypageIcon] = useState<string>('images/anyeventmime/background/icon_menu_mypage_off.png');

  const [eventDisplay, setEventDisplay] = useState<string>('none');
  const [spotDisplay, setSpotDisplay] = useState<string>('none');
  const [mypageDisplay, setMypageDisplay] = useState<string>('none');
  const [visibleCanvasConfirm, setVisibleCanvasConfirm] = useState<boolean>(false);



  useEffect(() => {

    if (storageService.getItem('anyeventmime-menu')) {

        var menu = JSON.parse( storageService.getItem('anyeventmime-menu') as string );
        if(menu != undefined)
          changeMenu(menu.menu);
    }
    else {
      changeMenu('festival');
    }

  }, []);


  const saveMenu = (menu) => {

    var data = {menu: menu}
    storageService.setItem('anyeventmime-menu', JSON.stringify(data));

    changeMenu(menu);
  }


  const changeMenu = (menu) => {

      setMenuFestivalIcon('images/anyeventmime/background/icon_menu_festival_off.png');
      setMenuSpotIcon('images/anyeventmime/background/icon_menu_spot_off.png');
      setMenuSentenceIcon('images/anyeventmime/background/icon_menu_sentence_off.png');
      setMenuMypageIcon('images/anyeventmime/background/icon_menu_mypage_off.png');

      setEventDisplay('none');
      setSpotDisplay('none');
      setMypageDisplay('none');

      switch (menu) {
          case 'festival':
              setMenuFestivalIcon('images/anyeventmime/background/icon_menu_festival_on.png');
              setEventDisplay('flex');
              break;
          case 'spot':
              setMenuSpotIcon('images/anyeventmime/background/icon_menu_spot_on.png');
              setSpotDisplay('flex');
              break;
          case 'sentence':
              setMenuSentenceIcon('images/anyeventmime/background/icon_menu_sentence_on.png');
              break;
          case 'mypage':
              setMenuMypageIcon('images/anyeventmime/background/icon_menu_mypage_on.png');
              break;
      }
  };


  return (
    <Drawer
      title=""
      width={320}
      closable={false}
      placement='left'
      onClose={() => onVisibleMenuConfirm(false)}
      visible={visibleMenuConfirm}
      style={{padding: 0}}
    >
      <Row style={{height:'100vh', width:'100%'}}>
        <Col span={8} style={{height:'100%', backgroundColor: '#002581'}}>
          <Row type='flex' justify='start' style={{marginTop: 50, paddingLeft: 15}}>
             <img src={menuFestivalIcon}  style={{height:70}} onClick={() => {saveMenu('festival')}}/>
          </Row>
          <Row type='flex' justify='center' ><Col span={16}> <Divider /> </Col></Row>
          <Row type='flex' justify='start' style={{marginTop: 0, paddingLeft: 15}}>
             <img src={menuSpotIcon} style={{height:70}} onClick={() => {saveMenu('spot')}}/>
          </Row>
          <Row type='flex' justify='center' ><Col span={16}> <Divider /> </Col></Row>
          <Row type='flex' justify='start' style={{marginTop: 0, paddingLeft: 15}}>
             <img src={menuSentenceIcon}  style={{height:70}} onClick={() => {saveMenu('sentence'); onVisibleMenuConfirm(false); history.push(MOBILE_SENTENCE_PATH);}}/>
          </Row>
          <Row type='flex' justify='center' ><Col span={16}> <Divider /> </Col></Row>
          <Row type='flex' justify='start' style={{marginTop: 0, paddingLeft: 15}}>
             <img src={menuMypageIcon} style={{height:70}} onClick={() => {saveMenu('mypage'); onVisibleMenuConfirm(false); history.push(MOBILE_MYPAGE_PATH);}}/>
          </Row>
        </Col>
        <Col span={16} style={{height:'100%', color: '#002581', backgroundColor: '#FFCC01'}}>
          <Row type='flex' justify='end' style={{marginTop: 10, paddingRight: 50}}>
            <Icon type="arrow-left" style={{color: '#002581'}} onClick={() => onVisibleMenuConfirm(false)} />
          </Row>

          <Row type='flex' style={{display: eventDisplay}}>
            <Row type='flex' justify='start' style={{width: '100%', marginTop: 10, padding: '5px 20px', fontSize: 12, fontWeight: 600}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_FESTIVAL_PATH)}}>
              소개
            </Row>
            <Row type='flex' justify='start' style={{width: '100%', padding: '5px 20px', fontSize: 12, fontWeight: 600}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_SCHEDULE_PATH)}}>
              일정
            </Row>
            <Row type='flex' justify='start' style={{width: '100%', padding: '5px 20px', fontSize: 12, fontWeight: 600, color: '#ffffff', background: '#002581'}}>
              행사소개
            </Row>
            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/1')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> City of Water; Ah! Su Nanjang </span>
            </Row>

            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/3')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Theater Performance 1 </span>
            </Row>
            <Row type='flex' justify='start' style={{marginTop: 5, paddingLeft: 25, fontSize: 10}}>
              <span style={{marginLeft: 15}}> -"Manhwagyeong (Comic Mirror)" </span>
            </Row>

            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/4')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Theater Performance 2 </span>
            </Row>
            <Row type='flex' justify='start' style={{marginTop: 5, paddingLeft: 25, fontSize: 10}}>
              <span style={{marginLeft: 15}}> -"Marine Boy: Voyage" </span>
            </Row>

            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/2')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> City of Spring </span>
            </Row>

            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/5')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Outdoor Performance at </span>
            </Row>
            <Row type='flex' justify='start' style={{marginTop: 5, paddingLeft: 25, fontSize: 10}}>
              <span style={{marginLeft: 15}}>  Festival Theater Gesture </span>
            </Row>

            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/6')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> City of Fire; Dokkaebi Nanjang </span>
            </Row>

            <Row type='flex' justify='start' style={{marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_EVENT_PATH + '/7')}}>
              <span><Icon type="caret-right" /></span><span style={{marginLeft: 5}}>  Daytime Dokkaebi Nanjang Returns </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, padding: '5px 20px', fontSize: 12, fontWeight: 600}} onClick={() => {setVisibleCanvasConfirm(true)}}>
              티켓구매
            </Row>
            <Row type='flex' justify='start' style={{width: '100%', padding: '5px 20px', fontSize: 12, fontWeight: 600}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_EVENT_PATH)}}>
              축제지도
            </Row>
          </Row>

          <Row type='flex' justify='start' style={{display: spotDisplay, width: '100%'}}>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 110,  padding: '5px 20px', fontSize: 12, fontWeight: 600, color: '#ffffff', background: '#002581'}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type00')}}>
              All
            </Row>
            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type02')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Activity </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type03')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Sightseeing </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type04')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Shopping </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type05')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Food </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type06')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Accomodation </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type07')}}>
              <span><Icon type="caret-right" /></span> <span style={{marginLeft: 5}}> Emergency </span>
            </Row>

            <Row type='flex' justify='start' style={{width: '100%', marginTop: 20, paddingLeft: 25, fontSize: 10}} onClick={() => {onVisibleMenuConfirm(false); history.push(MOBILE_MAP_SPOT_PATH + '/type08')}}>
              <span><Icon type="caret-right" /></span><span style={{marginLeft: 5}}>  Transit </span>
            </Row>
          </Row>

          <Row type='flex' style={{display: mypageDisplay, width: '100%'}}>
          </Row>

          <Row justify='end' style={{position: 'absolute', right: 10, bottom: 10 }}>
            <Row type='flex' justify='center' align='middle'
                  style={{width: 80, height: 30, marginLeft: 10, borderRadius: '15px 15px 0 15px', color: '#ffffff', background: '#002581'}}
                  onClick={() => history.push(MOBILE_LOGIN_PATH)}>
                  <span>Logout</span><span style={{marginLeft: 5}}> <Icon type="logout" /></span></Row>
          </Row>

        </Col>
      </Row>

      <MobileCanvasDialog
        visibleCanvasConfirm={visibleCanvasConfirm}
        onVisibleCanvasConfirm={(value) => setVisibleCanvasConfirm(value)}
        />


    </Drawer>

  );

};
