import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_ADMIN_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const ManagerAdminEditorView = (props) => {

  const history = useHistory();

  const [userUid, setUserUid] = useState<number>(0);
  const [userId, setUserId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userNickname, setUserNickname] = useState<string>('');
  const [role, setRole] = useState<number>(1);
  const [status, setStatus] = useState<number>(0);


  useEffect(() => {

    if(props.match.params.userId > 0) {
      $('#headerTitle').text('관리자 수정');

      setUserUid(props.match.params.userUid);
      getAdminData(props.match.params.userUid);
    }
    else {
      $('#headerTitle').text('관리자 등록');
    }

  }, [props.match.params.userUid]);



  const getAdminData = (userId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/user.do?userUid=' + userUid;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null) {
                      setUserId(data.userId);
                      setEmail(data.email);
                      setUserName(data.userName);
                      setUserNickname(data.userNickname);
                      setPassword('password');
                      setPassword2('password');
                      setRole(data.role);
                      setStatus(data.status);
                    }

                  })
     .catch(err => console.log(err));

  };


  const createAdmin = () => {

    if( !checkEmail() || !checkPassword() || !checkUserName())
      return;

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/user.do';

    var params = {
                  userId: userId,
                  email: email,
                  password: password,
                  userName: userName,
                  userNickname: userNickname,
                  role: role,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.userUid == null){
                        InfoModal("info", "알림", "관리자정보 등록이 실패하였습니다. 이메일과 비밀번호를 확인해 주세요.");
                      }
                      else {
                        InfoModal("info", "알림", "관리자정보 등록이 성공하였습니다.");
                      }

                    })
      .catch(err => console.log(err));

  }

  const updateAdmin = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/userUpdate.do';

    var params = {
              userUid: userUid,
              userId: userId,
              email: email,
              password: password,
              userName: userName,
              userNickname: userNickname,
              role: role,
              status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.userUid == null){
                        InfoModal("info", "알림", "관리자정보 수정이 실패하였습니다. 이메일과 비밀번호를 확인해 주세요.");
                      }
                      else {
                        InfoModal("info", "알림", "관리자정보 수정이 성공하였습니다.");
                      }

                    })
      .catch(err => console.log(err));

  }

  const checkEmail = () => {
    var emailChecker = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if(!emailChecker.test(email)){
      InfoModal("info", "알림", "이메일을 확인해 주세요.");
      return false;
    }

    return true;
  }


  const checkPassword = () => {
    var num = password.search(/[0-9]/g);
    var eng = password.search(/[a-z]/ig);
    var spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

    if(password.length < 8 || password.length > 20){
      InfoModal("info", "알림", "8자리 ~ 20자리 이내로 입력해주세요.");
      return false;
    }

    if(password.search(/₩s/) != -1){
      InfoModal("info", "알림", "비밀번호는 공백없이 입력해주세요.");
      return false;
    }
    if(num < 0 || eng < 0 || spe < 0 ){
      InfoModal("info", "알림", "영문,숫자, 특수문자를 혼합하여 입력해주세요.");
      return false;
    }
    if(password !== password2) {
      InfoModal("info", "알림", '비밀번호를 확인해 주세요.');
      return false;
    }

    return true;
  }


  const checkUserName = () => {

    if(userName.length < 1 ) {
      InfoModal("info", "알림", '이름을 확인해 주세요.');
      return false;
    }

    return true;
  }


  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const { Option } = Select;
  const { Search } = Input;

  return (
    <>
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Col style={{width: '100%', backgroundColor: '#ffffff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', paddingLeft: '80px'}}>
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '65px'}}>
              <Col span={4}> ID </Col>
              <Col span={14}>
                <Input placeholder='ID'
                       style={{height: '58px'}}
                       value={userId}
                       onChange={e => setUserId(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> Email </Col>
              <Col span={14}>
                <Input placeholder='Email'
                       style={{height: '58px'}}
                       value={email}
                       onChange={e => setEmail(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> Name </Col>
              <Col span={14}>
                <Input placeholder='Name'
                       style={{height: '58px'}}
                       value={userName}
                       onChange={e => setUserName(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> Password </Col>
              <Col span={14}>
                <Input.Password placeholder='Password'
                       style={{height: '58px'}}
                       value={password}
                       onChange={e => setPassword(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> Password Confirm </Col>
              <Col span={14}>
                <Input.Password placeholder='Password'
                       style={{height: '58px'}}
                       value={password2}
                       onChange={e => setPassword2(e.target.value)} />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '30px', marginBottom: '50px'}}>
              <Col span={4}> Admin Usage </Col>
              <Col span={14}>
                <Button style={{fontSize: '17px', height: '58px', width: '140px', borderColor: '#7446f9', backgroundColor: '#ffffff', color: '#7445f9'}}
                        onClick={e => setStatus(1)} > 활성화 </Button>
                <Button style={{fontSize: '17px', height: '58px', width: '140px', borderColor: '#dddddd', backgroundColor: '#f7f6fb', color: '#dddddd'}}
                        onClick={e => setStatus(0)} > 비활성화 </Button>
              </Col>
            </Row>
            <Row type='flex' justify='start'>
              <Col span={18}>
                <Divider />
              </Col>
            </Row>
            <Row type='flex' justify='end' style={{margin: '40px 0 88px 0'}}>
              <Col>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#928ca2', color: '#ffffff'}}
                        onClick={() => history.push(MANAGER_ADMIN_PATH)}> Back </Button>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                        onClick={() => { userUid > 0 ? createAdmin() : updateAdmin() }}> Save </Button>
              </Col>
              <Col span={6} />
            </Row>
          </Row>
        </Col>
      </Row>

    </>
  );

};
