import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Radio, Checkbox, Table, Pagination, Modal, Divider, Empty } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

import { SOPOONG_URL, ANYEVENTMIME_URL,
        MANAGER_SENTENCE_PATH,
        storageService
      } from 'common';

import { InfoModal } from 'components';

export const ManagerSentenceEditorView = (props) => {

  const history = useHistory();

  const [sentenceId, setSentenceId] = useState<number>(0);
  const [sentence, setSentence] = useState<string>('');
  const [define, setDefine] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [englishSentence, setEnglishSentence] = useState<string>('');
  const [koreanSentence, setKoreanSentence] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [status, setStatus] = useState<number>(0);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [indeterminateCategory, setIndeterminateCategory] = useState<boolean>(false);
  const [checkAllCategory, setCheckAllCategory] = useState<boolean>(false);

  const [keywordList, setKeywordList] = useState<any[]>([]);
  const [tempKeyword, setTempKeyword] = useState<string>('');


  useEffect(() => {

    if(props.match.params.sentenceId > 0) {
      $('#headerTitle').text('문장 수정');

      setSentenceId(props.match.params.sentenceId);
      getSentenceData(props.match.params.sentenceId);
    }
    else {
      $('#headerTitle').text('문장 등록');
    }

  }, [props.match.params.sentenceId]);



  const getSentenceData = (sentenceId) => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentence.do?sentenceId=' + sentenceId;

    fetch(
      searchUrl
    ).then(response => response.json())
     .then(data => {
                    console.log(data);

                    if(data != null) {
                      setSentence(data.sentence);
                      setDefine(data.define);

                      setCategory(data.category);
                      if(data.category != null)
                        setCategoryList(data.category.split(','));

                      setKeyword(data.keyword);
                      if(data.keyword != null)
                        setKeywordList(data.keyword.split(','));

                      setEnglishSentence(data.englishSentence);
                      setKoreanSentence(data.koreanSentence);
                      setDescription(data.description);
                      setStatus(data.status);
                    }

                  })
     .catch(err => console.log(err));

  };


  const createSentence = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentence.do';

    var categoryData = '';
    categoryList.forEach(category => { categoryData += category + ',' });
    if(categoryData.length > 0)
      categoryData = categoryData.substring(0, categoryData.length - 1);

    var keywordData = '';
    keywordList.forEach(keyword => { keywordData += keyword + ',' });
    if(keywordData.length > 0)
      keywordData = keywordData.substring(0, keywordData.length - 1);

    var params = {
            sentence: sentence,
            define: define,
            category: categoryData,
            keyword: keywordData,
            englishSentence: englishSentence,
            koreanSentence: koreanSentence,
            description: description,
            status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.sentenceId == null){
                        InfoModal("info", "알림", "문장 등록이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "문장 등록이 성공하였습니다.");
                      }

                    })
      .catch(err => console.log(err));

  }

  const updateSentence = () => {

    var searchUrl = SOPOONG_URL + ANYEVENTMIME_URL + '/sentenceUpdate.do';

    var categoryData = '';
    categoryList.forEach(category => { categoryData += category + ',' });
    if(categoryData.length > 0)
      categoryData = categoryData.substring(0, categoryData.length - 1);

      var keywordData = '';
      keywordList.forEach(keyword => { keywordData += keyword + ',' });
      if(keywordData.length > 0)
        keywordData = keywordData.substring(0, keywordData.length - 1);

    var params = {
                  sentenceId: sentenceId,
                  sentence: sentence,
                  define: define,
                  category: categoryData,
                  keyword: keywordData,
                  englishSentence: englishSentence,
                  koreanSentence: koreanSentence,
                  description: description,
                  status: status
          };

    fetch( searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
                      if(data.sentenceId == null){
                        InfoModal("info", "알림", "문장 수정이 실패하였습니다.");
                      }
                      else {
                        InfoModal("info", "알림", "문장 수정이 성공하였습니다.");
                      }

                    })
      .catch(err => console.log(err));

  }

  const onCheckAllChangeCategory = (e) => {

    if(e.target.checked)
      setCategoryList(['type01', 'type02', 'type03', 'type04', 'type05', 'type06', 'type07', 'type08', 'type09', 'type10', 'type11', 'type12']);
    else
      setCategoryList([]);

    setIndeterminateCategory(false);
    setCheckAllCategory(e.target.checked);

  };

  const checkCategoryList = (checkedList) => {

    if(checkedList.length == 0) {
      setIndeterminateCategory(false);
      setCheckAllCategory(false);
    }
    else if(checkedList.length < 12) {
      setIndeterminateCategory(true);
      setCheckAllCategory(false);
    }
    else {
      setIndeterminateCategory(false);
      setCheckAllCategory(true);
    }
    setCategoryList(checkedList);
  }

  const removeKeyword = (word) => {
    let words = keywordList.filter((keyword) => keyword != word);
    setKeywordList(words);
  }

  const getDate = (date) => {

    return date != null ? date.replace('T', ' ') : date;
  }

  const { Option } = Select;
  const { Search } = Input;


  var editorConfiguration = {
      ckfinder: {
          // Upload the images to the server using the CKFinder QuickUpload command.
          uploadUrl: SOPOONG_URL + '/fileupload/insertAnyEventMimeImage.do'
      },
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'alignment', 'fontBackgroundColor', 'fontColor', 'fontSize', '|', 'imageUpload'],
      image: {
          // Configure the available styles.
          styles: [
              'alignLeft', 'alignCenter', 'alignRight'
          ],
          // Configure the available image resize options.
          resizeOptions: [
              {
                  name: 'imageResize:original',
                  label: 'Original',
                  value: null
              },
              {
                  name: 'imageResize:50',
                  label: '50%',
                  value: '50'
              },
              {
                  name: 'imageResize:75',
                  label: '75%',
                  value: '75'
              }
          ],
          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
              'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
              '|',
              'imageResize',
              '|',
              'imageTextAlternative'
          ]
      }
  };


  return (
    <>
      <Row type='flex' justify='center' style={{width: '100%', fontSize: '15px', backgroundColor: '#f6f6fa', padding: '40px 30px'}}>
        <Col style={{width: '100%', backgroundColor: '#ffffff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '0px 80px'}}>
          <Row style={{width: '100%'}}>
            <Row type='flex' justify='start' align='top' style={{marginTop: '65px'}}>
              <Col span={4}> 대분류 </Col>
              <Col span={3}>
                <Checkbox checked={checkAllCategory}
                          indeterminate={indeterminateCategory}
                          onChange={onCheckAllChangeCategory}>전체</Checkbox>
              </Col>
              <Col span={17}>
                <Checkbox.Group style={{width: '100%'}}
                                value={categoryList}
                                onChange={values => checkCategoryList(values)} >
                  <Row type='flex' justify='space-between'>
                    <Col span={3}>
                      <Checkbox value='type01'>식당</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type02'>카페</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type03'>쇼핑</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type04'>레저,운동</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type05'>문화체험</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type06'>대중교통</Checkbox>
                    </Col>
                  </Row>
                  <Row type='flex' justify='space-between' style={{marginTop: '20px'}}>
                    <Col span={3}>
                      <Checkbox value='type07'>드라이빙</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type08'>숙박시설</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type09'>병원약국</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type10'>공공기관</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type11'>해외여행</Checkbox>
                    </Col>
                    <Col span={3}>
                      <Checkbox value='type12'>K-컨텐츠</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>키워드 </Col>
              <Col span={20} style={{backgroundColor: '#f7f6fb', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '24px'}}>
                <Row>
                  <Input style={{height: '50px', width: '420px'}}
                         value={tempKeyword}
                         onChange={e => setTempKeyword(e.target.value)}
                         onKeyDown={e => {
                                          if(e.key == 'Enter'){
                                            setKeywordList([...keywordList, tempKeyword]);
                                            setTempKeyword('');
                                          }
                                        }}/>
                  <span style={{marginLeft:'10px'}}>  *Enter로 태그를 추가할 수 있습니다. </span>
                </Row>
                <Row style={{marginTop: '20px'}} />
                <Row>
                  {keywordList ? (
                    keywordList.map(word => {
                      return (
                        <Col key={word} span={2} style={{backgroundColor: '#f3efff', border: 'solid 0.5px rgba(112, 112, 112, 0.2)', padding: '5px 10px', marginRight: '10px'}}>
                          <span>{word}</span>
                          <span style={{color: '#774af9', marginLeft: '10px', cursor: 'pointer'}}
                                onClick={() => removeKeyword(word)}> X </span>
                        </Col>);
                      })) :

                    <Empty />
                  }
                </Row>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}> 활성 여부 </Col>
              <Col span={20}>
                <Button style={{fontSize: '17px', height: '58px', width: '140px', borderColor: '#7446f9', backgroundColor: '#ffffff', color: '#7445f9'}}
                        onClick={e => setStatus(1)} > 활성화 </Button>
                <Button style={{fontSize: '17px', height: '58px', width: '140px', borderColor: '#dddddd', backgroundColor: '#f7f6fb', color: '#dddddd'}}
                        onClick={e => setStatus(0)} > 비활성화 </Button>
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>영문 문장</Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={englishSentence}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEnglishSentence(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px'}}>
              <Col span={4}>한글 문장</Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={koreanSentence}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setKoreanSentence(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start' align='middle' style={{marginTop: '20px', marginBottom: '50px'}}>
              <Col span={4}>설명</Col>
              <Col span={20}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={description}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setDescription(data);

                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                    config={editorConfiguration}
                />
              </Col>
            </Row>
            <Row type='flex' justify='start'>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row type='flex' justify='end' style={{margin: '40px 0 88px 0'}}>
              <Col>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#928ca2', color: '#ffffff'}}
                        onClick={() => history.push(MANAGER_SENTENCE_PATH)}> Back </Button>
                <Button style={{fontSize: '18px', height: '58px', width: '160px', backgroundColor: '#7446f9', color: '#ffffff', marginLeft: '10px'}}
                        onClick={() => { sentenceId > 0 ? createSentence() : updateSentence() }}> Save </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

    </>
  );

};
