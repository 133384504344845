import React, { Component, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Icon, Select, Row, Col, Menu, Carousel, Button, Input, Modal, Drawer, Divider } from 'antd';
import $ from 'jquery';
import queryString from 'query-string';

import { SOPOONG_URL,
         ANYEVENTMIME_URL,
         MOBILE_EVENT_PATH
          } from 'common';


export const MobileEventDialog = (props) => {

  const { userUid, event, visibleEventConfirm, onVisibleEventConfirm } = props;

  const history = useHistory();

  const [title, setTitle] = useState<string>('공연일정');
  const [scheduleList, setScheduleList] = useState<any[]>([]);

  useEffect(() => {

    if(event.eventDate != undefined)
      setScheduleList(event.eventDate.split(','));

  }, [event]);


  const getHtml = (data) => {

    if(data != null){

      let content = {__html: data };

      return (<div className='ck-content' dangerouslySetInnerHTML={content} />);
    }

    return(<div />);
  }


  return (
    <Modal
         className='festival-modal'
         visible={visibleEventConfirm}
         closable={false}
         title=""
         width='65vw'
         style={{ top: 0 }}
         centered
         footer={null}
         >
      <Row type='flex' justify='space-between' align='middle' style={{width: '100%', height: 30,  paddingLeft: 10, paddingRight: 10, backgroundColor: '#FFCC01'}}>
        <Col span={18}>
          <span style={{color: '#002581', fontWeight: 600}}>{title}</span>
        </Col>
        <Col>
          <img src='images/anyeventmime/background/button_minifestival_close.png'  style={{height:20}} onClick={() => onVisibleEventConfirm(false)}/>
        </Col>
      </Row>
      <Row type='flex' justify='center' style={{width: '100%', backgroundColor: '#002581'}}>
        <Col span={22} style={{marginBottom: 0}}>
          <Row type='flex' justify='center' style={{width: '100%', marginTop: 5, padding: '10px'}}>

            {getHtml(event.description)}

          </Row>
          <Row><hr style={{margin: '15px 0'}}/></Row>


          <Row type='flex' justify='center' align='middle' style={{width: '100%', padding: '0px', marginBottom: 20}} >
            <Row type='flex' justify='center' align='middle'
                  style={{width: 100, height: 30, fontSize: 12, fontWeight: 600, color: '#002581', background: '#FFFFFF', borderRadius: '15px 15px 0 15px', border: '1px solid #002581'}}
                  onClick={() => history.push(MOBILE_EVENT_PATH + '/' + event.eventId)}> 자세히보기 </Row>
          </Row>

        </Col>
      </Row>



    </Modal>


  );

};
